/**
 * Default Portal Branding.
 *
 * These are applied right from the get-go and are overridden by team/user themes.
 */
export const cssVariables = {
    /********************/
    /* Portal-X General */
    /********************/

    "font-family": "",
    "font-size": "1em",
    "line-height": "1.5",

    "primary-background-color": "rgba(36, 45, 52, 1)",
    "primary-color": "rgba(255, 255, 255, 1)",
    "primary-border-color": "rgba(45, 55, 62, 1)",

    "secondary-background-color": "rgba(45, 55, 62, 1)",
    "secondary-color": "rgba(204, 204, 204, 1)",
    "secondary-border-color": "rgba(75, 92, 104, 1)",

    "standard-box-shadow": "0px 20px 30px rgba(0, 0, 0, 0.35)",
    "left-drawer-box-shadow": "10px 0px 30px rgba(0, 0, 0, 0.35)",
    "right-drawer-box-shadow": "-10px 0px 30px rgba(0, 0, 0, 0.35)",

    /************************/
    /* Portal-X Breadcrumbs */
    /************************/

    "primary-breadcrumbs-background-color": "rgba(21, 60, 108, 1)",
    "primary-breadcrumbs-color": "rgba(255, 255, 255, 1)",
    "primary-breadcrumbs-border-color": "rgba(21, 60, 108, 1)",

    "secondary-breadcrumbs-background-color": "rgba(60, 72, 81, 1)",
    "secondary-breadcrumbs-color": "rgba(204, 204, 204, 1)",
    "secondary-breadcrumbs-border-color": "rgba(60, 72, 81, 1)",

    /*********************************/
    /* Portal-X Progress Indicators */
    /*********************************/

    "progress-indicator-color": "rgba(0, 159, 255, 1)",
    "progress-indicator-text-color": "rgba(155, 155, 155, 1)",

    /***********************/
    /* Portal-X Scrollbars */
    /***********************/

    "scrollbar-size": "0.4em",
    "scrollbar-track-color": "rgba(45, 55, 62, 1)",
    "scrollbar-thumb-color": "rgba(60, 72, 81, 1)",
    "scrollbar-thumb-radius": "0.2em",

    /******************/
    /* Portal-X Links */
    /******************/

    "link-color": "rgba(0, 159, 255, 1)",

    /*******************/
    /* Portal-X Header */
    /*******************/

    "header-background-color": "rgba(12, 41, 76, 1)",
    "header-color": "rgba(255, 255, 255, 1)",
    "header-border-color": "rgba(12, 41, 76, 1)",

    "header-help-icon-color": "rgba(255, 220, 0, 1)",
    "header-menu-launcher-color": "rgba(255, 255, 255, 1)",
    "header-intercom-launcher-color": "rgba(255, 220, 0, 1)",

    /***********************/
    /* Portal-X Navigation */
    /***********************/

    "navigation-background-color": "rgba(17, 27, 39, 1)",
    "navigation-color": "rgba(255, 255, 255, 1)",
    "navigation-border-color": "rgba(55, 57, 65, 1)",

    "navigation-disabled-background-color": "rgba(17, 27, 39, 1)",
    "navigation-disabled-color": "rgba(55, 57, 65, 1)",

    "navigation-app-selector-background-color": "rgba(17, 27, 39, 1)",
    "navigation-app-selector-color": "rgba(255, 255, 255, 1)",
    "navigation-app-selector-border-color": "rgba(55, 57, 65, 1)",

    "navigation-app-selector-hover-background-color": "rgba(0, 109, 186, 1)",
    "navigation-app-selector-hover-color": "rgba(255, 220, 0, 1)",

    "navigation-app-selector-active-background-color": "rgba(255, 220, 0, 1)",
    "navigation-app-selector-active-color": "rgba(0, 109, 186, 1)",

    "navigation-team-selector-background-color": "rgba(17, 27, 39, 1)",
    "navigation-team-selector-color": "rgba(255, 255, 255, 1)",
    "navigation-team-selector-border-color": "rgba(55, 57, 65, 1)",

    "navigation-team-selector-hover-background-color": "rgba(0, 109, 186, 1)",
    "navigation-team-selector-hover-color": "rgba(255, 220, 0, 1)",

    "navigation-team-selector-active-background-color": "rgba(255, 220, 0, 1)",
    "navigation-team-selector-active-color": "rgba(0, 109, 186, 1)",

    "navigation-super-context-background-color": "rgba(251, 21, 8, 0.1)",
    "navigation-super-context-color": "rgba(251, 21, 8, 1)",

    "navigation-team-context-background-color": "rgba(43, 112, 252, 0.1)",
    "navigation-team-context-color": "rgba(43, 112, 252, 1)",

    "navigation-app-context-background-color": "rgba(26, 194, 98, 0.1)",
    "navigation-app-context-color": "rgba(26, 194, 98, 1)",

    /*****************/
    /* Portal-X Logs */
    /*****************/

    "logs-sys-info-color": "inherit",
    "logs-sys-warning-color": "inherit",
    "logs-sys-error-color": "inherit",

    "logs-app-info-color": "inherit",
    "logs-app-warning-color": "inherit",
    "logs-app-error-color": "inherit",

    "logs-sys-info-font-weight": "inherit",
    "logs-sys-warning-font-weight": "inherit",
    "logs-sys-error-font-weight": "inherit",

    "logs-app-info-font-weight": "inherit",
    "logs-app-warning-font-weight": "inherit",
    "logs-app-error-font-weight": "inherit",

    "logs-sys-info-font-style": "inherit",
    "logs-sys-warning-font-style": "inherit",
    "logs-sys-error-font-style": "inherit",

    "logs-app-info-font-style": "inherit",
    "logs-app-warning-font-style": "inherit",
    "logs-app-error-font-style": "inherit",

    /*****************/
    /* Portal-X Job Queues */
    /*****************/
    "job-queues-scheduled-color": "rgba(21, 104, 219, 1)",
    "job-queues-queued-color": "rgba(123, 59, 251, 1)",
    "job-queues-running-color": "rgba(216, 78, 254, 1)",
    "job-queues-success-color": "rgba(83, 196, 26, 1)",
    "job-queues-fail-color": "rgba(245, 34, 52, 1)",
    "job-queues-cancelled-color": "rgba(250, 173, 20, 1)",

    /**********************/
    /* Portal-X Gradients */
    /**********************/

    /* Gradient start and end colors (for coloring gradients). */
    /* Gradients are not really used (currently). */
    "gradient-start-color": "rgba(255, 255, 255, 1)",
    "gradient-end-color": "rgba(255, 255, 255, 1)",

    /***********************/
    /* Portal-X Tabs */
    /***********************/

    "tabs-background-color": "initial",
    "tabs-color": "initial",

    "tabs-hover-background-color": "rgba(255, 255, 255, 0.03)",
    "tabs-hover-color": "initial",

    "tabs-active-background-color": "rgba(60, 72, 81, 1)",
    "tabs-active-color": "rgba(0, 159, 255, 1)",
    "tabs-active-border-color": "rgba(0, 159, 255, 1)",
    "tabs-active-indicator-size": "0.25em",

    "tabs-disabled-background-color": "initial",
    "tabs-disabled-color": "initial",

    /***************************/
    /* Portal-X Input Controls */
    /***************************/

    "field-height": "2em",
    "field-line-height": "1.5",

    "field-background-color": "rgba(60, 72, 81, 1)",
    "field-color": "rgba(149, 171, 188, 1)",
    "field-border-color": "rgba(0, 159, 255, 1)",

    "field-focused-background-color": "rgba(45, 55, 62, 1)",
    "field-focused-color": "rgba(255, 255, 255, 1)",
    "field-focused-border-color": "rgba(122, 122, 122, 1)",

    "field-disabled-background-color": "rgba(193, 193, 193, 1)",
    "field-disabled-color": "rgba(0, 0, 0, 1)",
    "field-disabled-border-color": "rgba(193, 193, 193, 1)",

    "field-error-background-color": "rgba(237, 190, 192, 1)",
    "field-error-color": "rgba(174, 13, 10, 1)",
    "field-error-border-color": "rgba(174, 13, 10, 1)",

    /*********************/
    /* Portal-X Popovers */
    /*********************/

    "popup-menu-background-color": "rgba(45, 55, 62, 1)",
    "popup-menu-color": "rgba(204, 204, 204, 1)",
    "popup-menu-border-color": "rgba(75, 92, 104, 1)",

    "popup-menu-box-shadow": "0px 0px 3px 0px rgba(255, 255, 255, 1)",

    "popup-menu-hover-background-color": "rgba(255, 255, 255, 0.1)",
    "popup-menu-hover-color": "inherit",

    "popup-menu-active-background-color": "rgba(60, 72, 81, 1)",
    "popup-menu-active-color": "rgba(255, 255, 255, 1)",

    /********************/
    /* Portal-X Buttons */
    /********************/

    /* Neutral */
    "button-neutral-background-color": "rgba(0, 0, 0, 0)",
    "button-neutral-color": "rgba(0, 159, 255, 1)",
    "button-neutral-border-color": "rgba(0, 0, 0, 0)",

    "button-neutral-hover-background-color": "rgba(45, 55, 62, 1)",
    "button-neutral-hover-color": "rgba(149, 171, 188, 1)",
    "button-neutral-hover-border-color": "rgba(45, 55, 62, 1)",

    "button-neutral-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-neutral-disabled-color": "rgba(0, 0, 0, 1)",
    "button-neutral-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Primary */
    "button-primary-background-color": "rgba(0, 159, 255, 1)",
    "button-primary-color": "rgba(255, 255, 255, 1)",
    "button-primary-border-color": "rgba(0, 159, 255, 1)",

    "button-primary-hover-background-color": "rgba(48, 107, 141, 1)",
    "button-primary-hover-color": "rgba(220, 220, 220, 1)",
    "button-primary-hover-border-color": "rgba(48, 107, 141, 1)",

    "button-primary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-primary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-primary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Secondary */
    "button-secondary-background-color": "rgba(36, 45, 52, 1)",
    "button-secondary-color": "rgba(0, 159, 255, 1)",
    "button-secondary-border-color": "rgba(0, 159, 255, 1)",

    "button-secondary-hover-background-color": "rgba(26, 33, 40, 1)",
    "button-secondary-hover-color": "rgba(204, 204, 204, 1)",
    "button-secondary-hover-border-color": "rgba(204, 204, 204, 1)",

    "button-secondary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-secondary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-secondary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Samantic - Positive (Primary) */
    "button-semantic-positive-primary-background-color": "rgba(70, 209, 77, 1)",
    "button-semantic-positive-primary-color": "rgba(255, 255, 255, 1)",
    "button-semantic-positive-primary-border-color": "rgba(70, 209, 77, 1)",

    "button-semantic-positive-primary-hover-background-color": "rgba(31, 135, 36, 1)",
    "button-semantic-positive-primary-hover-color": "rgba(220, 220, 220, 1)",
    "button-semantic-positive-primary-hover-border-color": "rgba(31, 135, 36, 1)",

    "button-semantic-positive-primary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-positive-primary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-positive-primary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Samantic - Positive (Secondary) */
    "button-semantic-positive-secondary-background-color": "rgba(36, 45, 52, 1)",
    "button-semantic-positive-secondary-color": "rgba(26, 194, 98, 1)",
    "button-semantic-positive-secondary-border-color": "rgba(26, 194, 98, 1)",

    "button-semantic-positive-secondary-hover-background-color": "rgba(23, 29, 33, 1)",
    "button-semantic-positive-secondary-hover-color": "rgba(22, 161, 81, 1)",
    "button-semantic-positive-secondary-hover-border-color": "rgba(22, 161, 81, 1)",

    "button-semantic-positive-secondary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-positive-secondary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-positive-secondary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Semantic - Negative (Primary) */
    "button-semantic-negative-primary-background-color": "rgba(235, 64, 55, 1)",
    "button-semantic-negative-primary-color": "rgba(255, 255, 255, 1)",
    "button-semantic-negative-primary-border-color": "rgba(235, 64, 55, 1)",

    "button-semantic-negative-primary-hover-background-color": "rgba(141, 37, 31, 1)",
    "button-semantic-negative-primary-hover-color": "rgba(220, 220, 220, 1)",
    "button-semantic-negative-primary-hover-border-color": "rgba(141, 37, 31, 1)",

    "button-semantic-negative-primary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-negative-primary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-negative-primary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Semantic - Negative (Secondary) */
    "button-semantic-negative-secondary-background-color": "rgba(36, 45, 52, 1)",
    "button-semantic-negative-secondary-color": "rgba(235, 64, 55, 1)",
    "button-semantic-negative-secondary-border-color": "rgba(235, 64, 55, 1)",

    "button-semantic-negative-secondary-hover-background-color": "rgba(23, 29, 33, 1)",
    "button-semantic-negative-secondary-hover-color": "rgba(141, 37, 31, 1)",
    "button-semantic-negative-secondary-hover-border-color": "rgba(141, 37, 31, 1)",

    "button-semantic-negative-secondary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-negative-secondary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-negative-secondary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /***************************/
    /* Portal-X Icon Buttons   */
    /***************************/

    "icon-button-default-color": "rgba(255, 255, 255, 1)",
    "icon-button-semantic-positive-color": "rgba(70, 209, 77, 1)",
    "icon-button-semantic-negative-color": "rgba(235, 64, 55, 1)",

    /********************/
    /* Portal-X Chips   */
    /********************/

    "chip-default-background-color": "rgba(0, 159, 255, 1)",
    "chip-default-color": "rgba(255, 255, 255, 1)",

    "chip-positive-background-color": "rgba(70, 209, 77, 1)",
    "chip-positive-color": "rgba(255, 255, 255, 1)",

    "chip-negative-background-color": "rgba(251, 21, 8, 1)",
    "chip-negative-color": "rgba(255, 255, 255, 1)",

    "chip-warning-background-color": "rgba(253, 173, 11, 1)",
    "chip-warning-color": "rgba(255, 255, 255, 1)",

    /********************/
    /* Portal-X Labels  */
    /********************/

    "label-default-color": "rgba(0, 159, 255, 1)",

    "label-positive-color": "rgba(70, 209, 77, 1)",

    "label-negative-color": "rgba(251, 21, 8, 1)",

    "label-warning-color": "rgba(253, 173, 11, 1)",

    /********************/
    /* Portal-X Tooltip */
    /********************/
    "tooltip-background-color": "rgba(252, 251, 251, 1)",

    "tooltip-color": "rgba(21, 20, 20, 1)",

    "tooltip-border-color": "rgba(21, 20, 20, 1)",

    /******************/
    /* Portal-X Table */
    /******************/

    "table-row-odd-background-color": "initial",
    "table-row-odd-color": "initial",

    "table-row-even-background-color": "initial",
    "table-row-even-color": "initial",

    "table-row-hover-background-color": "rgba(102, 89, 128, 1)",
    "table-row-hover-color": "initial",

    "table-row-selected-background-color": "rgba(102, 89, 128, 1)",
    "table-row-selected-color": "initial",

    "table-cell-modified-background-color": "rgba(250, 201, 101, 1)",
    "table-cell-modified-color": "initial",

    /*********************/
    /* Portal-X Info Box */
    /*********************/

    "infobox-info-background-color": "rgba(60, 72, 81, 1)",
    "infobox-info-color": "rgba(0, 159, 255, 1)",
    "infobox-info-border-color": "rgba(60, 72, 81, 1)",

    "infobox-warning-background-color": "rgba(60, 72, 81, 1)",
    "infobox-warning-color": "rgba(254, 134, 0, 1)",
    "infobox-warning-border-color": "rgba(60, 72, 81, 1)",

    "infobox-error-background-color": "rgba(60, 72, 81, 1)",
    "infobox-error-color": "rgba(251, 21, 8, 1)",
    "infobox-error-border-color": "rgba(60, 72, 81, 1)",

    /***************************/
    /* Portal-X Message Banner */
    /***************************/

    "system-message-banner-info-background-color": "rgba(225, 244, 255, 1)",
    "system-message-banner-info-color": "rgba(0, 159, 255, 1)",
    "system-message-banner-info-border-color": "rgba(60, 72, 81, 1)",

    "system-message-banner-warning-background-color": "rgba(255, 231, 213, 1)",
    "system-message-banner-warning-color": "rgba(254, 134, 0, 1)",
    "system-message-banner-warning-border-color": "rgba(60, 72, 81, 1)",

    "system-message-banner-error-background-color": "rgba(255, 241, 241, 1)",
    "system-message-banner-error-color": "rgba(251, 21, 8, 1)",
    "system-message-banner-error-border-color": "rgba(60, 72, 81, 1)",

    /***************************/
    /* Portal-X Live Lock Banner */
    /***************************/

    "live-lock-banner-locked-background-color": "rgba(0, 255, 170, 0.2)",
    "live-lock-banner-locked-color": "rgba(255, 255, 255, 1)",
    "live-lock-banner-locked-border-color": "rgba(0, 255, 170, 1)",
    "live-lock-banner-icon-color": "rgba(0, 0, 0, 1)",

    "live-lock-banner-unlocked-background-color": "rgba(255, 128, 0, 1)",
    "live-lock-banner-unlocked-color": "rgba(0, 0, 0, 1)",
    "live-lock-banner-unlocked-border-color": "rgba(255, 128, 0, 1)",

    /*******************/
    /* Portal-X Monaco */
    /*******************/
    "monaco-font-family": 'Menlo, Monaco, "Courier New", monospace',
    "monaco-font-size": "0.875em",
    "monaco-line-height": "1.125",

    /******************/
    /* Portal-X Jodit */
    /******************/
    "jodit-editor-toolbar-background-color": "initial",
    "jodit-editor-toolbar-border-color": "initial",
    "jodit-editor-toolbar-color": "initial",

    "jodit-editor-workplace-background-color": "initial",
    "jodit-editor-workplace-border-color": "initial",
    "jodit-editor-workplace-color": "initial",

    "jodit-editor-statusbar-background-color": "initial",
    "jodit-editor-statusbar-border-color": "initial",
    "jodit-editor-statusbar-color": "initial",

    /******************************/
    /* Portal-X Cloud Code Editor */
    /******************************/

    "cloud-code-editor-general-header-background-color": "rgba(18, 19, 20, 1)",
    "cloud-code-editor-general-header-color": "rgba(157, 157, 157, 1)",

    "cloud-code-editor-general-content-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-general-content-color": "rgba(157, 157, 157, 1)",

    "cloud-code-editor-file-tab-default-background-color": "rgba(168, 168, 168, 1)",
    "cloud-code-editor-file-tab-default-color": "rgba(0, 0, 0, 1)",

    "cloud-code-editor-file-tab-active-background-color": "rgba(47, 50, 52, 1)",
    "cloud-code-editor-file-tab-active-color": "rgba(157, 157, 157, 1)",

    "cloud-code-editor-banners-viewing-old-version-background-color": "rgba(225, 244, 23, 1)",
    "cloud-code-editor-banners-viewing-old-version-border-color": "rgba(225, 244, 23, 1)",
    "cloud-code-editor-banners-viewing-old-version-color": "rgba(83, 83, 83, 1)",

    "cloud-code-editor-banners-editing-old-version-background-color": "rgba(252, 79, 9, 1)",
    "cloud-code-editor-banners-editing-old-version-border-color": "rgba(252, 79, 9, 1)",
    "cloud-code-editor-banners-editing-old-version-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-no-longer-exists-background-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-no-longer-exists-border-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-no-longer-exists-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-save-in-progress-background-color": "rgba(18, 123, 246, 1)",
    "cloud-code-editor-banners-save-in-progress-border-color": "rgba(18, 123, 246, 1)",
    "cloud-code-editor-banners-save-in-progress-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-save-failed-background-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-save-failed-border-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-save-failed-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-save-successful-background-color": "rgba(26, 194, 98, 1)",
    "cloud-code-editor-banners-save-successful-border-color": "rgba(26, 194, 98, 1)",
    "cloud-code-editor-banners-save-successful-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-request-log-header-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-request-log-header-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-request-log-header-color": "rgba(127, 73, 230, 1)",

    "cloud-code-editor-request-log-content-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-request-log-content-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-request-log-content-color": "rgba(127, 73, 230, 1)",

    "cloud-code-editor-response-log-header-success-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-header-success-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-header-success-color": "rgba(26, 194, 98, 1)",

    "cloud-code-editor-response-log-content-success-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-content-success-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-content-success-color": "rgba(26, 194, 98, 1)",

    "cloud-code-editor-response-log-header-failure-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-header-failure-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-header-failure-color": "rgba(251, 21, 8, 1)",

    "cloud-code-editor-response-log-content-failure-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-content-failure-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-content-failure-color": "rgba(251, 21, 8, 1)",

    /*************************/
    /* Portal-X Chart Colors */
    /*************************/

    "chart-dataset-color-0": "rgba( 43, 174, 51, 1)",
    "chart-dataset-color-1": "rgba( 237, 177, 16, 1)",
    "chart-dataset-color-2": "rgba(88, 203, 188, 1)",
    "chart-dataset-color-3": "rgba( 255, 143, 151, 1)",
    "chart-dataset-color-4": "rgba( 43, 51, 255, 1)",
    "chart-dataset-color-5": "rgba(190, 173, 161, 1)",
    "chart-dataset-color-6": "rgba( 59, 89, 152, 1)",
    "chart-dataset-color-7": "rgba( 164, 198, 57, 1)",
    "chart-dataset-color-8": "rgba( 182, 48, 114, 1)",
    "chart-dataset-color-9": "rgba( 150, 173, 239, 1)",
    "chart-dataset-color-10": "rgba( 221, 72, 20, 1)",
    "chart-dataset-color-11": "rgba( 0, 173, 239, 1)",
    "chart-dataset-color-12": "rgba( 55, 65, 20, 1)",
    "chart-dataset-color-13": "rgba( 255, 165, 0, 1)",
    "chart-dataset-color-14": "rgba( 155, 165, 200, 1)",
    "chart-dataset-color-15": "rgba( 141, 104, 133, 1)",
    "chart-dataset-color-16": "rgba(220, 248, 56, 1)",
    "chart-dataset-color-17": "rgba(154, 141, 154, 1)",
    "chart-dataset-color-18": "rgba(117, 94, 92, 1)",
    "chart-dataset-color-19": "rgba(51, 157, 187, 1)",
    "chart-dataset-color-20": "rgba(1, 57, 87, 1)",

    "annotation-color": "rgba(255, 255, 255, 1)",
    "annotation-label-color": "rgba(1, 1, 1, 1)",
    "annotation-label-background-color": "rgba(255, 255, 255, 1)",

    "chart-dataset-font-color-0": "rgba( 3, 74, 1, 1)",
    "chart-dataset-font-color-1": "rgba( 37, 77, 6, 1)",
    "chart-dataset-font-color-2": "rgba(8, 03, 88, 1)",
    "chart-dataset-font-color-3": "rgba( 55, 43, 51, 1)",
    "chart-dataset-font-color-4": "rgba( 43, 251, 55, 1)",
    "chart-dataset-font-color-5": "rgba(90, 73, 61, 1)",
    "chart-dataset-font-color-6": "rgba( 159, 89, 52, 1)",
    "chart-dataset-font-color-7": "rgba( 64, 98, 57, 1)",
    "chart-dataset-font-color-8": "rgba( 82, 148, 14, 1)",
    "chart-dataset-font-color-9": "rgba( 150, 73, 39, 1)",
    "chart-dataset-font-color-10": "rgba( 21, 72, 20, 1)",
    "chart-dataset-font-color-11": "rgba( 0, 73, 39, 1)",
    "chart-dataset-font-color-12": "rgba( 55, 165, 120, 1)",
    "chart-dataset-font-color-13": "rgba( 225, 65, 0, 1)",
    "chart-dataset-font-color-14": "rgba( 55, 65, 100, 1)",
    "chart-dataset-font-color-15": "rgba( 241, 04, 33, 1)",
    "chart-dataset-font-color-16": "rgba(20, 48, 156, 1)",
    "chart-dataset-font-color-17": "rgba(54, 41, 54, 1)",
    "chart-dataset-font-color-18": "rgba(17, 24, 192, 1)",
    "chart-dataset-font-color-19": "rgba(11, 27, 87, 1)",
    "chart-dataset-font-color-20": "rgba(120, 157, 87, 1)",

    "chart-title-color": "rgba(254, 254, 254, 1)",
    "chart-xaxis-label-color": "rgba(254, 254, 254, 1)",
    "chart-yaxis-label-color": "rgba(254, 254, 254, 1)",
    "chart-legend-label-color": "rgba(254, 254, 254, 1)",

    "gauge-chart-level-low-color": "rgb(144, 237, 144, 1)",
    "gauge-chart-level-medium-color": "rgb(254, 205, 85, 1)",
    "gauge-chart-level-high-color": "rgb(254, 1, 1, 1)",
    "gauge-chart-pointer-color": "rgb(255, 255, 255, 1)",

    /**********************************/
    /* Portal-X Portal Special Colors */
    /**********************************/

    "portal-color-green": "rgba(0, 204, 0, 1)",
    "portal-color-red": "rgba(255, 0, 0, 1)",
    "portal-color-yellow": "rgba(250, 173, 20, 1)",
    "portal-color-orange": "rgba(255, 131, 43, 1)",
    "portal-color-blue": "rgba(0, 117, 255, 1)",
    "portal-color-lightBlue": "rgba(0, 147, 193, 1)",
    "portal-color-aqua": "rgba(80, 234, 209, 1)",
    "portal-color-olive": "rgba(128, 128, 0, 1)",
    "portal-color-pink": "rgba(255, 106, 151, 1)",
    "portal-color-magenta": "rgba(199, 21, 133, 1)",
    "portal-color-purple": "rgba(186, 85, 255, 1)",
    "portal-color-brown": "rgba(161, 107, 71, 1)",
    "portal-color-grey": "rgba(133, 133, 133, 1)",
};

export const other = {
    monaco: '{\n  "base": "vs",\n  "inherit": true,\n  "rules": [],\n  "colors": {}\n}',
    fontUrl: null,
};
