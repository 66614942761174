import { createContext, useContext, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";

import PortalState from "types/store";
import { DirectRTTLog, DirectRTTSession } from "types/common/DirectRTT";

import { APPEND_DIRECT_RTT_LOG, CLEAR_DIRECT_RTT_LOGS, CLEAR_DIRECT_RTT_SESSION, SET_DIRECT_RTT_AUTO_CONNECT, SET_DIRECT_RTT_BUSY, SET_DIRECT_RTT_SESSION, UPDATE_DIRECT_RTT_LOG } from "store/actions/directRTT";

import CloneUtils from "utils/Clone";
import PollerUtils from "utils/Poller";
import { RTT_HEARTBEAT_POLLER_ID } from "utils/PollerIds";

interface RTTHeartbeat {
    data: any;
    status: "good" | "degraded" | "bad" | null;
    pending: boolean;
}

export type DirectRTTContextType = {
    directRTTSession: DirectRTTSession | null;
    setDirectRTTSession: (session: DirectRTTSession) => void;
    clearDirectRTTSession: (session: DirectRTTSession) => void;
    directRTTLogs: DirectRTTLog[];
    clearDirectRTTLogs: () => void;
    directRTTBusy: boolean;
    directRTTHeartbeat: RTTHeartbeat | null;
    directRTTAutoConnect: boolean;
    setDirectRTTAutoConnect: (autoConnect: boolean) => void;
};

const DEFAULT_VALUE: DirectRTTContextType = {
    directRTTSession: null,
    setDirectRTTSession: (_session: DirectRTTSession) => {
        return;
    },
    clearDirectRTTSession: (_session: DirectRTTSession) => {
        return;
    },
    directRTTLogs: [],
    clearDirectRTTLogs: () => {
        return;
    },
    directRTTBusy: false,
    directRTTHeartbeat: null,
    directRTTAutoConnect: false,
    setDirectRTTAutoConnect: (_autoConnect: boolean) => {
        return;
    },
};

enum Service {
    RTT = "rtt",
}

enum Operation {
    CONNECT = "CONNECT",
    HEARTBEAT = "HEARTBEAT",
}

// Interval for the RTT heartbeat poller (in seconds).
const HEARTBEAT_INTERVAL = 3;

// Threshold when a heartbeat is considered "degraded" (in milliseconds).
const HEARTBEAT_DEGRADED_THRESHOLD = 250;

// Threshold when a heartbeat is considered "bad" (in milliseconds).
const HEARTBEAT_BAD_THRESHOLD = 500;

export const DirectRTTContext = createContext<DirectRTTContextType>(DEFAULT_VALUE);

export const DirectRTTContextProvider = (props: any) => {
    const dispatch = useDispatch();

    const appId = useSelector((state: PortalState) => state.session.appId || "");
    const directRTT = useSelector((state: PortalState) => state.directRTT);

    const rttSession = directRTT.sessions.find((item) => item.appId === appId) || null;

    const targetUrl = rttSession?.host ? (rttSession.ssl ? "wss://" : "ws://") + rttSession.host + ":" + (rttSession.port != null ? rttSession.port : rttSession.ssl ? 443 : 80) : null;

    const [heartbeat, setHeartbeat] = useState<RTTHeartbeat | null>(null);

    const [currentConnectLog, setCurrentConnectLog] = useState<DirectRTTLog | null>(null);
    const [currentConnectLogStart, setCurrentConnectLogStart] = useState<Date | null>(null);

    const [currentHeartbeatLog, setCurrentHeartbeatLog] = useState<DirectRTTLog | null>(null);
    const [currentHeartbeatLogStart, setCurrentHeartbeatLogStart] = useState<Date | null>(null);

    const setDirectRTTSession = useCallback(
        (session: DirectRTTSession) => {
            dispatch(SET_DIRECT_RTT_SESSION(session));
        },
        [dispatch]
    );

    const clearDirectRTTSession = useCallback(
        (session: DirectRTTSession) => {
            dispatch(CLEAR_DIRECT_RTT_SESSION(session));
        },
        [dispatch]
    );

    const setDirectRTTBusy = useCallback(
        (isBusy: boolean) => {
            dispatch(SET_DIRECT_RTT_BUSY(isBusy));
        },
        [dispatch]
    );

    const clearDirectRTTLogs = useCallback(() => {
        dispatch(CLEAR_DIRECT_RTT_LOGS());
    }, [dispatch]);

    const setDirectRTTAutoConnect = useCallback(
        (autoConnect: boolean) => {
            dispatch(SET_DIRECT_RTT_AUTO_CONNECT(autoConnect));
        },
        [dispatch]
    );

    const websocket = useWebSocket(targetUrl, {
        queryParams: {
            "X-APPID": appId,
            "X-RTT-SECRET": rttSession?.rttSecret || "",
        },
        onOpen: () => {
            setDirectRTTBusy(true);

            completeRTTConnection();

            setDirectRTTBusy(false);
        },
        onClose: (_event: CloseEvent) => {
            setDirectRTTBusy(true);

            console.debug("WEB-SOCKET CLOSED");

            const currentPoller = PollerUtils.getPoller(RTT_HEARTBEAT_POLLER_ID);
            if (currentPoller) {
                PollerUtils.stopPoller(RTT_HEARTBEAT_POLLER_ID);
            }

            if (rttSession) {
                clearDirectRTTSession(rttSession);
            }

            setDirectRTTBusy(false);
        },
        onError: (event: WebSocketEventMap["error"]) => {
            setDirectRTTBusy(true);

            console.error("WEB-SOCKET ERROR", event);

            const currentPoller = PollerUtils.getPoller(RTT_HEARTBEAT_POLLER_ID);
            if (currentPoller) {
                PollerUtils.stopPoller(RTT_HEARTBEAT_POLLER_ID);
            }

            if (rttSession) {
                clearDirectRTTSession(rttSession);
            }

            setDirectRTTBusy(false);
        },
        shouldReconnect: (_closeEvent: any) => {
            return false;
        },
        onMessage: (event: WebSocketEventMap["message"]) => {
            event.data.text().then((data: string) => {
                if (!rttSession) return;

                let dataAsJson: { [index: string]: any } | null = null;

                try {
                    dataAsJson = JSON.parse(data);
                } catch (error: any) {
                    console.warn("WEB-SOCKET MESSAGE RECEIVED - Could not parse message", data);
                }

                if (dataAsJson == null) return;

                if (dataAsJson.service === Service.RTT && dataAsJson.operation === Operation.CONNECT) {
                    if (currentConnectLog) {
                        currentConnectLog.response = dataAsJson;
                        currentConnectLog.duration = currentConnectLogStart != null ? new Date().getTime() - currentConnectLogStart.getTime() : -1;

                        dispatch(UPDATE_DIRECT_RTT_LOG(currentConnectLog));

                        setCurrentConnectLogStart(null);
                        setCurrentConnectLog(null);
                    }

                    const updatedDirectRTTSession = CloneUtils.clone(rttSession) as DirectRTTSession;

                    updatedDirectRTTSession.connectInfo = dataAsJson.data || null;

                    setDirectRTTSession(updatedDirectRTTSession);

                    if (dataAsJson.data) {
                        const heartbeatSeconds = dataAsJson.data.heartbeatSeconds || HEARTBEAT_INTERVAL;

                        const currentPoller = PollerUtils.getPoller(RTT_HEARTBEAT_POLLER_ID);
                        if (currentPoller) {
                            PollerUtils.stopPoller(RTT_HEARTBEAT_POLLER_ID);
                        }

                        PollerUtils.startPoller(
                            RTT_HEARTBEAT_POLLER_ID, // Poller ID.
                            0, // Start delay in ms (0 = immediately).
                            0, // Maximum number of iterations (0 = infinite).
                            heartbeatSeconds * 1000, // Interval between iterations in ms.
                            async (currentHeartbeat: RTTHeartbeat | null, setHeartbeat: (hearbeat: RTTHeartbeat | null) => void, sendJsonMessage: (data: any) => void) => {
                                if (currentHeartbeatLog) {
                                    // If there is a currentHeartbeatLog, that means we have yet to receive a response for the previous heartbeat.
                                    // In this situation we mark the heartbeat status as "bad".
                                    setHeartbeat({
                                        data: currentHeartbeat?.data || null,
                                        status: "bad",
                                        pending: true,
                                    });
                                } else {
                                    // Otherwise we are good to go to initiate the heartbeat normally.
                                    setHeartbeat({
                                        data: currentHeartbeat?.data || null,
                                        status: currentHeartbeat?.status || null,
                                        pending: true,
                                    });
                                }

                                const directRTTLog = {
                                    appId: appId,
                                    request: {
                                        service: Service.RTT,
                                        operation: Operation.HEARTBEAT,
                                        data: null,
                                    },
                                    response: null,
                                    duration: -1,
                                } as DirectRTTLog;

                                setCurrentHeartbeatLog(directRTTLog);
                                setCurrentHeartbeatLogStart(new Date());

                                sendJsonMessage(directRTTLog.request);
                            }, // The actual poller implementation function.
                            [heartbeat, setHeartbeat, sendJsonMessage] // Arguments passed to the poller implementation function.
                        );
                    }
                }

                if (dataAsJson.service === Service.RTT && dataAsJson.operation === Operation.HEARTBEAT) {
                    if (currentHeartbeatLog) {
                        currentHeartbeatLog.response = dataAsJson;
                        currentHeartbeatLog.duration = currentHeartbeatLogStart != null ? new Date().getTime() - currentHeartbeatLogStart.getTime() : -1;

                        setCurrentHeartbeatLogStart(null);
                        setCurrentHeartbeatLog(null);

                        const updatedHeartbeat: RTTHeartbeat = {
                            data: dataAsJson,
                            status: currentHeartbeatLog.duration >= HEARTBEAT_BAD_THRESHOLD ? "bad" : currentHeartbeatLog.duration >= HEARTBEAT_DEGRADED_THRESHOLD ? "degraded" : "good",
                            pending: false,
                        };

                        setHeartbeat(updatedHeartbeat);

                        const currentPoller = PollerUtils.getPoller(RTT_HEARTBEAT_POLLER_ID);
                        if (currentPoller) {
                            const heartbeatSeconds = rttSession.connectInfo?.heartbeatSeconds || HEARTBEAT_INTERVAL;

                            PollerUtils.updatePoller(RTT_HEARTBEAT_POLLER_ID, 0, heartbeatSeconds * 1000, [updatedHeartbeat, setHeartbeat, sendJsonMessage]);
                        }
                    } else {
                        console.warn("Mismatched heartbeat...");
                    }
                }

                if (dataAsJson.service && dataAsJson.service !== Service.RTT) {
                    const directRTTLog = {
                        appId: appId,
                        request: null,
                        response: dataAsJson,
                        duration: -1,
                    } as DirectRTTLog;

                    dispatch(APPEND_DIRECT_RTT_LOG(directRTTLog));
                }

                if (dataAsJson.errorCode || dataAsJson.errorMsg) {
                    console.error("WEB-SOCKET ERROR [" + dataAsJson.errorCode + "] - " + dataAsJson.errorMsg);

                    if (currentConnectLog) {
                        currentConnectLog.response = dataAsJson;
                        currentConnectLog.duration = currentConnectLogStart != null ? new Date().getTime() - currentConnectLogStart.getTime() : -1;

                        dispatch(UPDATE_DIRECT_RTT_LOG(currentConnectLog));

                        setCurrentConnectLogStart(null);
                        setCurrentConnectLog(null);
                    } else {
                        const directRTTLog = {
                            appId: appId,
                            request: null,
                            response: dataAsJson,
                            duration: -1,
                        } as DirectRTTLog;

                        dispatch(APPEND_DIRECT_RTT_LOG(directRTTLog));
                    }

                    if (dataAsJson.errorCode === "INVALID_SESSION") {
                        clearDirectRTTSession(rttSession);
                    } else {
                        if (rttSession.connectInfo?.status === "pending") {
                            clearDirectRTTSession(rttSession);
                        }
                    }
                }
            });
        },
    });

    const sendJsonMessage = useCallback(
        (data: any) => {
            if (!rttSession) {
                throw new Error("Missing Direct RTT Session");
            }

            websocket.sendJsonMessage(data);
        },
        [websocket, rttSession]
    );

    const completeRTTConnection = useCallback(() => {
        if (!rttSession) return;

        const directRTTLog = {
            timestamp: new Date(),
            appId: appId,
            request: {
                service: Service.RTT,
                operation: Operation.CONNECT,
                data: {
                    auth: {
                        "X-APPID": appId,
                        "X-RTT-SECRET": rttSession.rttSecret || "",
                    },
                    system: {
                        platform: rttSession.platform || "",
                        protocol: rttSession.protocol || "",
                    },
                    appId: appId || "",
                    profileId: rttSession.profileId || "",
                    sessionId: rttSession.sessionId || "",
                },
            },
            response: null,
            duration: -1,
        } as DirectRTTLog;

        dispatch(APPEND_DIRECT_RTT_LOG(directRTTLog));

        setCurrentConnectLog(directRTTLog);
        setCurrentConnectLogStart(new Date());

        sendJsonMessage(directRTTLog.request);
    }, [rttSession, appId, dispatch, sendJsonMessage]);

    const [value, setValue] = useState<DirectRTTContextType>({
        directRTTSession: rttSession,
        setDirectRTTSession: setDirectRTTSession,
        clearDirectRTTSession: clearDirectRTTSession,
        directRTTLogs: directRTT.logs,
        clearDirectRTTLogs: clearDirectRTTLogs,
        directRTTBusy: directRTT.isBusy || rttSession?.connectInfo?.status === "pending",
        directRTTHeartbeat: heartbeat,
        directRTTAutoConnect: directRTT.autoConnect,
        setDirectRTTAutoConnect: setDirectRTTAutoConnect,
    });

    useEffect(() => {
        setValue({
            directRTTSession: rttSession,
            setDirectRTTSession: setDirectRTTSession,
            clearDirectRTTSession: clearDirectRTTSession,
            directRTTLogs: directRTT.logs,
            clearDirectRTTLogs: clearDirectRTTLogs,
            directRTTBusy: directRTT.isBusy || rttSession?.connectInfo?.status === "pending",
            directRTTHeartbeat: heartbeat,
            directRTTAutoConnect: directRTT.autoConnect,
            setDirectRTTAutoConnect: setDirectRTTAutoConnect,
        });
    }, [directRTT, heartbeat, rttSession, setDirectRTTSession, clearDirectRTTSession, clearDirectRTTLogs, setDirectRTTAutoConnect]);

    return <DirectRTTContext.Provider value={value}>{props.children}</DirectRTTContext.Provider>;
};

export const useDirectRTT = () => useContext(DirectRTTContext);
