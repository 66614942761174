// Import libraries.
import React, { CSSProperties, Suspense } from "react";
import { Theme, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import classnames from "classnames";
import lazyRetry from "utils/LazyRetry";

// Import types.
import { FormValidator } from "framework/formValidator";

// Import components.
import Tooltip from "@mui/material/Tooltip";
import LoadingProgress from "../widgets/LoadingProgress";

// Import the validator implementation.
import { validate } from "./Validator";

// Import the various option types.
import type { TextFieldOptions } from "./fields/TextField";
import type { NumberFieldOptions } from "./fields/NumberField";
import type { BigIntFieldOptions } from "./fields/BigIntField";
import type { PasswordFieldOptions } from "./fields/PasswordField";
import type { TextAreaFieldOptions } from "./fields/TextAreaField";
import type { CheckboxFieldOptions } from "./fields/CheckboxField";
import type { SwitchFieldOptions } from "./fields/SwitchField";
import type { SelectFieldOptions } from "./fields/SelectField";
import type { ComboboxFieldOptions } from "./fields/ComboboxField";
import type { ColorFieldOptions } from "./fields/ColorField";
import type { DateTimeFieldOptions } from "./fields/DateTimeField";
import type { DateFieldOptions } from "./fields/DateField";
import type { TimeFieldOptions } from "./fields/TimeField";
import type { RadioButtonFieldOptions } from "./fields/RadioButtonField";
import type { MaskedInputFieldOptions } from "./fields/MaskedInputField";
import type { AceFieldOptions } from "./fields/AceField";
import type { MonacoFieldOptions } from "./fields/MonacoField";
import type { ScriptPickerOptions } from "./fields/ScriptPicker";

// Lazy-load the actual field implementations.
const TextField = React.lazy(lazyRetry(() => import("./fields/TextField")));
const NumberField = React.lazy(lazyRetry(() => import("./fields/NumberField")));
const BigIntField = React.lazy(lazyRetry(() => import("./fields/BigIntField")));
const PasswordField = React.lazy(lazyRetry(() => import("./fields/PasswordField")));
const TextAreaField = React.lazy(lazyRetry(() => import("./fields/TextAreaField")));
const CheckboxField = React.lazy(lazyRetry(() => import("./fields/CheckboxField")));
const SwitchField = React.lazy(lazyRetry(() => import("./fields/SwitchField")));
const SelectField = React.lazy(lazyRetry(() => import("./fields/SelectField")));
const ComboboxField = React.lazy(lazyRetry(() => import("./fields/ComboboxField")));
const ColorField = React.lazy(lazyRetry(() => import("./fields/ColorField")));
const DateTimeField = React.lazy(lazyRetry(() => import("./fields/DateTimeField")));
const DateField = React.lazy(lazyRetry(() => import("./fields/DateField")));
const TimeField = React.lazy(lazyRetry(() => import("./fields/TimeField")));
const RadioButtonField = React.lazy(lazyRetry(() => import("./fields/RadioButtonField")));
const MaskedInputField = React.lazy(lazyRetry(() => import("./fields/MaskedInputField")));
// const AceField = React.lazy(lazyRetry(() => import("./fields/AceField")));
const MonacoField = React.lazy(lazyRetry(() => import("./fields/MonacoField")));
const ScriptPicker = React.lazy(lazyRetry(() => import("./fields/ScriptPicker")));

export type FieldType = "text" | "number" | "bigint" | "password" | "textarea" | "checkbox" | "switch" | "select" | "combobox" | "color" | "datetime" | "date" | "time" | "radiobutton" | "ace" | "monaco" | "masked" | "cloud-code-script";

export type FieldOptions =
    | TextFieldOptions
    | NumberFieldOptions
    | BigIntFieldOptions
    | PasswordFieldOptions
    | MaskedInputFieldOptions
    | TextAreaFieldOptions
    | CheckboxFieldOptions
    | SwitchFieldOptions
    | SelectFieldOptions
    | ComboboxFieldOptions
    | ColorFieldOptions
    | DateTimeFieldOptions
    | DateFieldOptions
    | TimeFieldOptions
    | RadioButtonFieldOptions
    | AceFieldOptions
    | MonacoFieldOptions
    | ScriptPickerOptions;

// Define the properties accepted by this component.
interface OWN_PROPS {
    className?: string;
    type?: FieldType;
    name: string;
    value?: any;
    label?: React.ReactNode;
    labelPosition?: "left" | "top" | "right" | "bottom";
    labelAlignment?: "flex-start" | "center" | "flex-end" | "stretch";
    errorTooltipPosition?: "top" | "bottom" | "left" | "right" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "left-start" | "left-end" | "right-start" | "right-end" | null;
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    onChange?: (name: string, value: any) => void;
    onKeyPress?: (name: string, key: string) => void;
    onClick?: (name: string) => void; // Only applicable when readonly OR disabled (otherwise it interfers with complex/popover/dialog based controls).
    onFocus?: (name: string) => void; // Only applicable when not readonly AND not disabled.
    onBlur?: (name: string) => void; // Only applicable when not readonly AND not disabled.
    validate?: (name: string, value: any) => React.ReactNode;
    options?: FieldOptions;
    style?: CSSProperties;
    labelStyle?: CSSProperties;
    controlStyle?: CSSProperties;
    errorStyle?: CSSProperties;
    innerStyle?: CSSProperties;
    formValidator?: FormValidator; // Optional form validator (takes complete control of field validation if provided)
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

// Define the local state managed by this component.
interface STATE {
    error: React.ReactNode;
    tooltipOpen: boolean;
    controlHeightComputed: boolean;
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            margin: "0.3125em",

            overflow: "hidden",

            maxHeight: "100%",
            maxWidth: "100%",
        },
        label: {
            flex: "0 0 auto",
            display: "flex",

            overflow: "hidden",
        },
        pointerOnHover: {
            cursor: "pointer",
        },
        control: {
            flex: "1 1 auto",
            display: "flex",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",

            minHeight: "var(--field-height)",
        },
        error: {
            flex: "1 1 auto",
            display: "flex",

            flexDirection: "column",
            alignItems: "stretch",

            overflow: "hidden",

            fontSize: "inherit",

            "& > span": {
                flex: "0 0 auto",
                display: "flex",
                overflow: "hidden",
                fontSize: "inherit",

                "& > div": {
                    flex: "1 1 auto",
                    alignSelf: "flex-start",

                    display: "flex",
                    flexDirection: "column",

                    whiteSpace: "break-spaces",

                    overflow: "hidden",
                },
            },
        },
    });

const validationOptionsChanged = (prevOptions?: FieldOptions, newOptions?: FieldOptions) => {
    if (!prevOptions && newOptions) return true;
    if (prevOptions && !newOptions) return true;

    if (prevOptions && newOptions) {
        if ((prevOptions as any).minLength !== (newOptions as any).minLength) return true;
        if ((prevOptions as any).maxLength !== (newOptions as any).maxLength) return true;

        if ((prevOptions as any).minValue !== (newOptions as any).minValue) return true;
        if ((prevOptions as any).maxValue !== (newOptions as any).maxValue) return true;

        // TODO: Probably want to update this at some point to account for changes in modifiers/flags.
        // TODO: For now this will just compare the source expressions of the two RegExp instances.
        if (((prevOptions as any).pattern as RegExp)?.source !== ((newOptions as any).pattern as RegExp)?.source) return true;
    }

    return false;
};

class FieldWrapper extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        error: null,
        tooltipOpen: false,
        controlHeightComputed: false,
    };
    private rootRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.rootRef.current) {
            this.rootRef.current.addEventListener("bc-set-value", this.bcSetValue);
        }

        if (this.props.formValidator) {
            if (this.props.type) {
                this.props.formValidator.registerField(this.props.name, this.props.type, this.props.value, {
                    disabled: this.props.disabled || this.props.readonly,
                    required: this.props.required,
                    minLength: (this.props.options as any)?.minLength,
                    maxLength: (this.props.options as any)?.maxLength,
                    minValue: (this.props.options as any)?.minValue,
                    maxValue: (this.props.options as any)?.maxValue,
                    pattern: (this.props.options as any)?.pattern,
                    patternMessage: (this.props.options as any)?.patternMessage,
                    custom: this.props.validate,
                });
            }
        } else {
            this.setState({
                error: validate(this.props.type, this.props.name, this.props.value, {
                    disabled: this.props.disabled === true || this.props.readonly === true,
                    required: this.props.required === true,
                    minLength: (this.props.options as any)?.minLength,
                    maxLength: (this.props.options as any)?.maxLength,
                    minValue: (this.props.options as any)?.minValue,
                    maxValue: (this.props.options as any)?.maxValue,
                    pattern: (this.props.options as any)?.pattern,
                    patternMessage: (this.props.options as any)?.patternMessage,
                    freeSolo: (this.props.options as any)?.freeSolo,
                    mode: (this.props.options as any)?.mode,
                    custom: this.props.validate
                        ? (value: any) => {
                              if (this.props.validate) {
                                  return this.props.validate(this.props.name, value);
                              } else {
                                  return null;
                              }
                          }
                        : undefined,
                }),
            });
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (this.props.formValidator) {
            if (this.props.type) {
                if (prevProps.disabled !== this.props.disabled || prevProps.readonly !== this.props.readonly || prevProps.required !== this.props.required || validationOptionsChanged(prevProps.options, this.props.options)) {
                    this.props.formValidator.registerField(this.props.name, this.props.type, this.props.value, {
                        disabled: this.props.disabled || this.props.readonly,
                        required: this.props.required,
                        minLength: (this.props.options as any)?.minLength,
                        maxLength: (this.props.options as any)?.maxLength,
                        minValue: (this.props.options as any)?.minValue,
                        maxValue: (this.props.options as any)?.maxValue,
                        pattern: (this.props.options as any)?.pattern,
                        patternMessage: (this.props.options as any)?.patternMessage,
                        custom: this.props.validate,
                    });
                }
            }
        } else {
            if (
                prevProps.disabled !== this.props.disabled ||
                prevProps.readonly !== this.props.readonly ||
                prevProps.required !== this.props.required ||
                validationOptionsChanged(prevProps.options, this.props.options) ||
                (prevProps.value !== this.props.value && !(Number.isNaN(prevProps.value) && Number.isNaN(this.props.value)))
            ) {
                this.setState({
                    error: validate(this.props.type, this.props.name, this.props.value, {
                        disabled: this.props.disabled === true || this.props.readonly === true,
                        required: this.props.required === true,
                        minLength: (this.props.options as any)?.minLength,
                        maxLength: (this.props.options as any)?.maxLength,
                        minValue: (this.props.options as any)?.minValue,
                        maxValue: (this.props.options as any)?.maxValue,
                        pattern: (this.props.options as any)?.pattern,
                        patternMessage: (this.props.options as any)?.patternMessage,
                        freeSolo: (this.props.options as any)?.freeSolo,
                        mode: (this.props.options as any)?.mode,
                        custom: this.props.validate
                            ? (value: any) => {
                                  if (this.props.validate) {
                                      return this.props.validate(this.props.name, value);
                                  } else {
                                      return null;
                                  }
                              }
                            : undefined,
                    }),
                });
            }
        }
    }

    componentWillUnmount(): void {
        if (this.props.formValidator) {
            if (this.props.type) {
                this.props.formValidator.unregisterField(this.props.name);
            }
        }

        if (this.rootRef.current) {
            this.rootRef.current.removeEventListener("bc-set-value", this.bcSetValue);
        }
    }

    /**
     * Function to allow automated test frameworks to be able to set the current value of the field (of any field type).
     */
    bcSetValue = (e: any) => {
        const { name, disabled, readonly } = this.props;

        if (disabled || readonly) return;

        if (this.props.formValidator) {
            this.props.formValidator.fieldUpdated(name, e.detail);
        }

        if (this.props.onChange) {
            this.props.onChange(name, e.detail);
        }
    };

    onChange = (name: string, value: any) => {
        if (this.props.formValidator) {
            this.props.formValidator.fieldUpdated(name, value);
        }

        if (this.props.onChange) {
            this.props.onChange(name, value);
        }
    };

    onKeyPress = (name: string, key: string) => {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(name, key);
        }
    };

    onClick = () => {
        if ((this.props.readonly || this.props.disabled) && this.props.onClick) {
            this.props.onClick(this.props.name);
        }
    };

    onMouseDown = () => {
        this.setState({ tooltipOpen: false });
    };

    onMouseUp = () => {};

    onTouchStart = () => {
        this.setState({ tooltipOpen: true });
    };

    onTouchEnd = () => {
        this.setState({ tooltipOpen: false });
    };

    onMouseEnter = () => {
        this.setState({ tooltipOpen: true });
    };

    onMouseLeave = () => {
        this.setState({ tooltipOpen: false });
    };

    onFocus = () => {
        const { name } = this.props;

        if (this.props.formValidator) {
            this.props.formValidator.fieldFocused(name);
        }

        if (this.props.onFocus) {
            this.props.onFocus(name);
        }
    };

    onBlur = () => {
        const { name } = this.props;

        if (this.props.formValidator) {
            this.props.formValidator.fieldBlurred(name);
        }

        if (this.props.onBlur) {
            this.props.onBlur(name);
        }
    };

    validate = (name: string, value: any) => {
        if (this.props.validate) {
            return this.props.validate(name, value);
        } else {
            return null;
        }
    };

    onInitialControlHeightComputed = () => {
        const { controlHeightComputed } = this.state;

        if (!controlHeightComputed) {
            this.setState({ controlHeightComputed: true });
        }
    };

    renderLabel = () => {
        const { classes, type, name, value, label, readonly, disabled, options, labelAlignment, labelStyle, labelPosition } = this.props;

        const appliedLabelStyle = Object.assign({} as CSSProperties, labelStyle);
        appliedLabelStyle.alignSelf = labelAlignment ? labelAlignment : "center";
        if (!labelPosition || labelPosition === "left") appliedLabelStyle.marginRight = "0.3125em";
        if (labelPosition === "right") appliedLabelStyle.marginLeft = "0.3125em";

        const clickableLabel = type != null && ["checkbox", "switch"].includes(type) && !readonly && !disabled && options && (options as CheckboxFieldOptions | SwitchFieldOptions).clickableLabel;

        return (
            <div id={"label"} className={classnames(classes.label, "FieldWrapper-label")} style={appliedLabelStyle}>
                <span
                    style={{ flex: "1 1 auto", display: "flex", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "break-spaces" }}
                    className={clickableLabel ? classes.pointerOnHover : ""}
                    onClick={clickableLabel ? () => this.onChange(name, !value) : undefined}
                >
                    {label}
                </span>
            </div>
        );
    };

    renderControl = () => {
        const { classes, type, name, value, required, readonly, disabled, autoFocus, options, labelPosition, errorTooltipPosition, controlStyle, errorStyle, innerStyle } = this.props;

        let error: React.ReactNode = null;

        if (this.props.formValidator) {
            const errors = this.props.formValidator?.getErrors(name) || [];

            error = errors.length > 0 ? errors.map((error) => error.message) : null;
        } else {
            error = this.state.error;
        }

        const innerProps = {
            name: name,
            value: value,
            onMouseDown: this.onMouseDown,
            onMouseUp: this.onMouseUp,
            onTouchStart: this.onTouchStart,
            onTouchend: this.onTouchEnd,
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            validate: this.validate,
            required: required,
            readonly: readonly,
            disabled: disabled,
            autoFocus: autoFocus,
            innerStyle: innerStyle,
            formValidatorError: error != null,
        };

        let control = (
            <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                <Typography>
                    <Trans>Control Not Implemented</Trans>
                </Typography>
            </span>
        );

        switch (type) {
            case "text":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <TextField {...innerProps} options={options as TextFieldOptions} />
                    </Suspense>
                );
                break;
            case "number":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <NumberField {...innerProps} options={options as NumberFieldOptions} />
                    </Suspense>
                );
                break;
            case "bigint":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <BigIntField {...innerProps} options={options as BigIntFieldOptions} />
                    </Suspense>
                );
                break;
            case "password":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <PasswordField {...innerProps} options={options as PasswordFieldOptions} />
                    </Suspense>
                );
                break;
            case "textarea":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <TextAreaField {...innerProps} options={options as TextAreaFieldOptions} onInitialHeightComputed={this.onInitialControlHeightComputed} />
                    </Suspense>
                );
                break;
            case "checkbox":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <CheckboxField {...innerProps} options={options as CheckboxFieldOptions} />
                    </Suspense>
                );
                break;
            case "switch":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <SwitchField {...innerProps} options={options as SwitchFieldOptions} />
                    </Suspense>
                );
                break;
            case "select":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <SelectField {...innerProps} options={options as SelectFieldOptions} />
                    </Suspense>
                );
                break;
            case "combobox":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <ComboboxField {...innerProps} options={options as ComboboxFieldOptions} />
                    </Suspense>
                );
                break;
            case "color":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <ColorField {...innerProps} options={options as ColorFieldOptions} />
                    </Suspense>
                );
                break;
            case "datetime":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <DateTimeField {...innerProps} options={options as DateTimeFieldOptions} />
                    </Suspense>
                );
                break;
            case "date":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <DateField {...innerProps} options={options as DateFieldOptions} />
                    </Suspense>
                );
                break;
            case "time":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <TimeField {...innerProps} options={options as TimeFieldOptions} />
                    </Suspense>
                );
                break;
            case "radiobutton":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <RadioButtonField {...innerProps} options={options as RadioButtonFieldOptions} />
                    </Suspense>
                );
                break;
            case "masked":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <MaskedInputField {...innerProps} options={options as MaskedInputFieldOptions} />
                    </Suspense>
                );
                break;
            case "ace":
                // control = (
                //     <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                //         <AceField {...innerProps} options={options as AceFieldOptions} />
                //     </Suspense>
                // );
                break;
            case "monaco":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <MonacoField {...innerProps} options={options as MonacoFieldOptions} onInitialHeightComputed={this.onInitialControlHeightComputed} />
                    </Suspense>
                );
                break;
            case "cloud-code-script":
                control = (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <ScriptPicker {...innerProps} options={options as ScriptPickerOptions} />
                    </Suspense>
                );
                break;
            default:
                control = (
                    <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                        <Typography>
                            <Trans>Control Not Implemented</Trans>
                        </Typography>
                    </span>
                );
        }

        const errorComponent = error ? (
            <div className={classnames(classes.error, "FieldWrapper-error")} style={errorStyle}>
                {Array.isArray(error) &&
                    error.map((error, idx) => (
                        <span key={idx}>
                            <Typography style={{ flex: "0 0 auto", alignSelf: "flex-start", fontSize: "inherit" }}>*</Typography>

                            <div>{error}</div>
                        </span>
                    ))}

                {!Array.isArray(error) && (
                    <span>
                        <Typography style={{ flex: "0 0 auto", alignSelf: "flex-start", fontSize: "inherit" }}>*</Typography>

                        <div>{error}</div>
                    </span>
                )}
            </div>
        ) : (
            ""
        );

        const tooltipPlacement =
            errorTooltipPosition != null ? errorTooltipPosition : labelPosition == null || labelPosition === "left" ? "right" : labelPosition === "right" ? "left" : labelPosition === "top" ? "bottom" : labelPosition === "bottom" ? "top" : undefined;

        const appliedControlStyle = Object.assign({}, controlStyle) as React.CSSProperties;
        if (type && ["textarea", "monaco"].includes(type)) {
            appliedControlStyle.minHeight = "var(--field-height)";

            appliedControlStyle.height = "auto";

            delete appliedControlStyle.maxHeight;
        }

        return (
            <Tooltip open={this.state.tooltipOpen} arrow placement={tooltipPlacement} title={errorComponent} slotProps={{ tooltip: { className: error ? "error" : undefined }, arrow: { className: error ? "error" : undefined } }}>
                <span
                    id={"control"}
                    className={classnames(classes.control, "FieldWrapper-control")}
                    style={appliedControlStyle}
                    onClick={this.onClick}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    {control}
                </span>
            </Tooltip>
        );
    };

    render() {
        const { className, style, classes, name, label, labelPosition } = this.props;
        const { controlHeightComputed } = this.state;

        const appliedRootStyle = Object.assign({} as CSSProperties, style);
        appliedRootStyle.flexDirection = labelPosition === "bottom" ? "column-reverse" : labelPosition === "right" ? "row-reverse" : labelPosition === "top" ? "column" : "row";
        if ((!labelPosition || labelPosition === "left" || labelPosition === "right") && style?.alignItems == null) appliedRootStyle.alignItems = "stretch";

        if (controlHeightComputed) {
            appliedRootStyle.flex = "0 0 auto";
        }

        return (
            <div ref={this.rootRef} id={"field-wrapper-" + name} className={classnames(classes.root, className)} style={appliedRootStyle}>
                {label && this.renderLabel()}
                {this.renderControl()}
            </div>
        );
    }
}

export default withStyles(styles)(FieldWrapper);
