import React, { CSSProperties, ReactElement } from "react";
import classnames from "classnames";

import { Box, ClickAwayListener, Theme, Paper, Popper, PopperPlacementType } from "@mui/material";

import { createStyles, withStyles, WithStyles } from "@mui/styles";

// Define the various properties accepted by this component.
interface OWN_PROPS {
    className?: string;
    style?: CSSProperties;
    content: ReactElement;
    children: ReactElement;
    open: boolean;
    onClose: () => void;
    placement?: PopperPlacementType;
    disableFlip?: boolean;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

// Define the local state managed by this component.
interface STATE {
    anchorEl: HTMLElement | null;
    arrowRef: HTMLElement | null;
}

class PopperWithArrow extends React.Component<PROPS, STATE> {
    state: Readonly<STATE> = { anchorEl: null, arrowRef: null };

    handleArrowRef = (node: HTMLElement | null) => {
        this.setState({ arrowRef: node });
    };

    handleAnchorEl = (event: HTMLElement | null) => {
        this.setState({ anchorEl: event });
    };

    swallowEvent = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    render() {
        const { className, style, open, placement, disableFlip, classes, content, children } = this.props;
        const { arrowRef, anchorEl } = this.state;

        return (
            <div className={classes.root}>
                {React.cloneElement(children, { ...children.props, ref: this.handleAnchorEl })}

                <Popper
                    id={"popper-with-arrow"}
                    className={classnames(classes.popper, className)}
                    style={style}
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement ? placement : "bottom"}
                    modifiers={[
                        { name: "flip", enabled: disableFlip ? false : true },
                        { name: "arrow", enabled: true, options: { element: arrowRef } },
                        { name: "preventOverflow", enabled: true, options: { boundariesElement: "window", padding: { top: 64, left: 16, right: 16 } } },
                    ]}
                    onMouseDown={this.swallowEvent}
                    onTouchStart={this.swallowEvent}
                >
                    <ClickAwayListener onClickAway={this.props.onClose}>
                        <Paper className={classes.contentRoot} onClick={this.swallowEvent}>
                            <span className={classes.arrow} ref={this.handleArrowRef} />
                            <Box className={classes.content}>{content}</Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        popper: {
            display: "flex",

            maxWidth: "95%",
            maxHeight: "95%",

            zIndex: 1,

            '&[data-popper-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "0 100%",
                },
            },
            '&[data-popper-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "100% 0",
                },
            },
            '&[data-popper-placement*="right"] $arrow': {
                left: 0,
                marginLeft: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "100% 100%",
                },
            },
            '&[data-popper-placement*="left"] $arrow': {
                right: 0,
                marginRight: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "0 0",
                },
            },
        },
        arrow: {
            position: "absolute",

            width: "1em",
            height: "0.71em",

            boxSizing: "border-box",

            overflow: "hidden",

            "&::before": {
                content: '""',

                display: "block",

                width: "100%",
                height: "100%",

                margin: "auto",

                transform: "rotate(45deg)",

                backgroundColor: "var(--popup-menu-border-color, inherit)",
                borderColor: "var(--popup-menu-border-color, inherit)",

                borderStyle: "solid",
                borderWidth: "0.0625em",

                boxShadow: "var(--standard-box-shadow)",
            },
        },
        contentRoot: {
            flex: "1 1 auto",
            display: "flex",
            overflow: "hidden",

            backgroundColor: "var(--popup-menu-background-color, inherit)",
            color: "var(--popup-menu-color, inherit)",
            borderColor: "var(--popup-menu-border-color, inherit)",

            borderStyle: "solid",
            borderWidth: "0.0625em",
            borderRadius: "0.25em",

            boxShadow: "var(--standard-box-shadow)",
        },
        content: {
            flex: "1 1 auto",

            padding: "0.625em",

            overflow: "auto",
        },
    });

export default withStyles(styles)(PopperWithArrow);
