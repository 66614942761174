// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme, Tooltip } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n, withI18nProps } from "@lingui/react";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import User from "types/common/User";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";

// Import components.
import { Breadcrumbs, Link, Typography } from "@mui/material";
import IconButton from "components/common/button/IconButton";
import CustomMenu from "components/common/menu";

// Import icons.
import LinkIcon from "@mui/icons-material/InsertLink";
import ChevronUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// Import redux actions.
import { SET_RECENTLY_VIEWED } from "store/actions/recentlyViewed";

// Import utilities
import PortalRouteUtils from "utils/PortalRoutes";
import RecentlyViewedUtils from "utils/RecentlyViewed";
import ClipboardUtils from "utils/Clipboard";

// Import screen data collection.
import { ScreenData, getScreenDataForId } from "assets/data/screens";

interface STATE_PROPS {
    currentUser: User | null;
    recentlyViewed: string[];
}
interface DISPATCH_PROPS {
    setRecentlyViewed: (recentlyViewed: string[]) => void;
}
interface OWN_PROPS {
    definitions: PortalRouteDefinition[];
    bannerOpen: boolean;
    bannerMode: "team" | "app" | "player";
    onToggleBannerOpen: (mode: "team" | "app" | "player", open?: boolean, saveToLocalStorage?: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps {}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        currentUser: state.currentUser,
        recentlyViewed: state.recentlyViewed,
    };
};

const mapDispatchToProps = (dispatch: Function): DISPATCH_PROPS => {
    return {
        setRecentlyViewed: (recentlyViewed: string[]) => dispatch(SET_RECENTLY_VIEWED(recentlyViewed)),
    };
};

interface STATE {
    anchorE1: any;
    anchorE2: any;
    screenInfo: ScreenData | null;
    collapseBreadcrumbs: boolean;
}

class BreadcrumbBar extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        anchorE1: null,
        anchorE2: null,
        screenInfo: null,
        collapseBreadcrumbs: false,
    };

    private breadcrumbSpanRef = React.createRef<HTMLDivElement>();
    private breadcrumbsRef = React.createRef<HTMLElement>();

    componentDidMount() {
        this.updateRecentlyViewed();
        this.handleBreadcrumbsResize();
        this.getScreenInfo();
    }

    componentDidUpdate(prevProps: PROPS) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.updateRecentlyViewed();
            this.handleBreadcrumbsResize();
            this.getScreenInfo();
        }
        window.addEventListener("resize", this.handleBreadcrumbsResize);
    }

    getScreenInfo = () => {
        const screenInfo = getScreenDataForId(this.props.location.pathname);

        this.setState({ screenInfo });
    };

    handleBreadcrumbsResize = () => {
        if (this.breadcrumbSpanRef.current && this.breadcrumbsRef.current) {
            const breadcrumbSpanWidth = this.breadcrumbSpanRef.current.offsetWidth;
            const breadcrumbsWidth = this.breadcrumbsRef.current.offsetWidth;

            if (breadcrumbsWidth >= breadcrumbSpanWidth) this.setState({ collapseBreadcrumbs: true });
            else this.setState({ collapseBreadcrumbs: false });
        }
    };

    openBreadcrumbsMenu = (event: any) => this.setState({ anchorE1: event.currentTarget });

    closeBreadcrumbsMenu = () => this.setState({ anchorE1: null });

    openShareInfoMenu = (event: any) => this.setState({ anchorE2: event.currentTarget });

    closeShareInfoMenu = () => this.setState({ anchorE2: null });

    toggleTeamAndAppBanner = () => {
        const { location, bannerOpen, bannerMode } = this.props;

        if (!location.pathname.startsWith("/super") && !["/team/dashboard", "/team/setup/team-info", "/app/dashboard", "/app/design/cloud-code/scripts"].includes(location.pathname)) {
            this.props.onToggleBannerOpen(bannerMode, !bannerOpen, true);
        } else {
            this.props.onToggleBannerOpen(bannerMode, !bannerOpen);
        }
    };

    generatePathToCurrentPage = (currentRoutesInPath: PortalRouteDefinition[]) => {
        return currentRoutesInPath.map((item) => item.label(this.props.i18n)).join(" > ");
    };

    updateRecentlyViewed = () => {
        const { location, definitions, currentUser, recentlyViewed } = this.props;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        if (currentUser) {
            let updatedRecentlyViewed = [...recentlyViewed];

            if (updatedRecentlyViewed.includes(currentPath)) {
                updatedRecentlyViewed.splice(updatedRecentlyViewed.indexOf(currentPath), 1);
                updatedRecentlyViewed.push(currentPath);
            } else {
                if (updatedRecentlyViewed.length === 10) {
                    updatedRecentlyViewed = updatedRecentlyViewed.slice(1);
                }

                updatedRecentlyViewed.push(currentPath);
            }

            RecentlyViewedUtils.setRecentlyViewed(currentUser.profileId, updatedRecentlyViewed);

            this.props.setRecentlyViewed(updatedRecentlyViewed);
        }
    };

    render() {
        const { classes, history, location, definitions, bannerOpen } = this.props;
        const { anchorE1, anchorE2, collapseBreadcrumbs, screenInfo } = this.state;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        const currentRoutesInPath = PortalRouteUtils.getPortalRouteDefinitionsInPath(definitions, currentPath);

        const breadcrumbs = screenInfo?.breadcrumbs || [];

        return (
            <div id={"breadcrumb-bar"} className={classes.root}>
                <span ref={this.breadcrumbSpanRef} style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <Breadcrumbs
                        ref={this.breadcrumbsRef}
                        aria-label="breadcrumb"
                        style={{ flex: "1 0 auto", maxHeight: collapseBreadcrumbs ? 0 : undefined, width: "fit-content", alignItems: "center" }}
                        className={classes.breadcrumbs}
                        maxItems={10}
                        separator={<ChevronRightIcon />}
                    >
                        {breadcrumbs.map((item, idx) => {
                            if (idx !== breadcrumbs.length - 1) {
                                if (item.route != null) {
                                    return (
                                        <Link key={idx} rel="noreferrer" onClick={() => history.push(item.route as string)}>
                                            {item.label}
                                        </Link>
                                    );
                                } else {
                                    return <Typography key={idx}>{item.label}</Typography>;
                                }
                            } else
                                return (
                                    <span key={idx} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: ".5em" }}>
                                        <Typography key={idx} style={{ fontWeight: "bold" }}>
                                            {item.label}
                                        </Typography>

                                        <IconButton id={"share-page-info"} type={"neutral"} onClick={this.openShareInfoMenu}>
                                            <LinkIcon style={{ transform: "rotate(-45deg)" }} />
                                        </IconButton>
                                    </span>
                                );
                        })}
                    </Breadcrumbs>

                    <Breadcrumbs aria-label="collapsed-breadcrumb" style={{ maxHeight: !collapseBreadcrumbs ? 0 : undefined }} className={classes.breadcrumbs} maxItems={10} separator={<ChevronRightIcon />}>
                        {breadcrumbs.length > 0 && (
                            <Link rel="noreferrer" onClick={() => history.push(breadcrumbs[0].route as string)}>
                                {breadcrumbs[0].label}
                            </Link>
                        )}

                        {breadcrumbs.length > 2 && (
                            <IconButton id={"more-actions"} onClick={this.openBreadcrumbsMenu}>
                                <MoreHorizIcon />
                            </IconButton>
                        )}

                        {breadcrumbs.length > 1 && (
                            <span style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: ".5em" }}>
                                <Typography style={{ fontWeight: "bold" }}>{breadcrumbs[breadcrumbs.length - 1].label}</Typography>

                                <IconButton id={"share-page-info"} type={"neutral"} onClick={this.openShareInfoMenu}>
                                    <LinkIcon style={{ transform: "rotate(-45deg)" }} />
                                </IconButton>
                            </span>
                        )}
                    </Breadcrumbs>
                </span>

                {collapseBreadcrumbs && breadcrumbs.length > 2 && (
                    <CustomMenu
                        anchorElement={anchorE1}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={Boolean(anchorE1)}
                        onClose={this.closeBreadcrumbsMenu}
                        menuItems={breadcrumbs.slice(1, breadcrumbs.length - 1).map((item, idx) => {
                            return {
                                id: `${idx}`,
                                label: <Typography style={{ opacity: item.route == null ? 0.5 : undefined }}>{item.label}</Typography>,
                                onClick: () => {
                                    if (item.route != null) {
                                        history.push(item.route);
                                    }

                                    this.closeBreadcrumbsMenu();
                                },
                            };
                        })}
                    />
                )}

                <CustomMenu
                    id={"share-page-info"}
                    anchorElement={anchorE2}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(anchorE2)}
                    onClose={this.closeShareInfoMenu}
                    header={<Trans>Share Page Info</Trans>}
                    menuItems={[
                        {
                            id: "copy-page-url",
                            label: <Trans>Page URL</Trans>,
                            onClick: () => {
                                ClipboardUtils.writeText(window.location.href);
                                this.closeShareInfoMenu();
                            },
                        },
                        {
                            id: "copy-path",
                            label: <Trans>Path to current page</Trans>,
                            onClick: () => {
                                ClipboardUtils.writeText(this.generatePathToCurrentPage(currentRoutesInPath));
                                this.closeShareInfoMenu();
                            },
                        },
                    ]}
                />

                {!location.pathname.startsWith("/super") && location.pathname !== "/team/setup/team-info" && (
                    <Tooltip arrow title={bannerOpen ? <Trans>Hide team/app/profile banner</Trans> : <Trans>Show team/app/profile banner</Trans>}>
                        <IconButton id={"toggle-banner"} type={"neutral"} onClick={this.toggleTeamAndAppBanner}>
                            {bannerOpen ? <ChevronUpIcon style={{ pointerEvents: "none" }} /> : <ChevronDownIcon style={{ pointerEvents: "none" }} />}
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: "2.1252em",
            width: "100%",
            padding: "0.125em 0.625em",
            overflow: "hidden",
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--primary-breadcrumbs-color, inherit)",
            borderColor: "var(--primary-breadcrumbs-border-color, inherit)",
            borderTopStyle: "solid",
            borderTopWidth: "0.0625em",
            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625em",
        },
        breadcrumbs: {
            color: "inherit",
            overflow: "hidden",
            "& .MuiBreadcrumbs-ol": {
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
                "& .MuiBreadcrumbs-separator": {
                    margin: "0 .3125em",
                },
                "& .MuiBreadcrumbs-li *": {
                    color: "inherit",
                },
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withI18n()(withStyles(styles)(BreadcrumbBar))));
