// Import libraries.
import React, { CSSProperties } from "react";
import { Theme } from "@mui/material";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import types.
import TeamInfo from "types/models/TeamInfo";

// Import components.
import { MenuItem, Tooltip, Typography } from "@mui/material";
import ImageWrapper from "components/common/ImageWrapper";

// Import icons.
import SelectedIcon from "@mui/icons-material/FiberManualRecord";
import NoTeamImage from "components/icons/NoTeamImage";

// Import utilities.
import StringUtils from "utils/String";

interface OWN_PROPS {
    style?: CSSProperties;

    teamInfo: TeamInfo;

    disabled?: boolean;
    selected?: boolean;

    onClick?: (companyId?: string) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class TeamMenuItem extends React.PureComponent<PROPS> {
    render() {
        const { classes, style, teamInfo, disabled, selected } = this.props;

        return (
            <MenuItem
                data-id={teamInfo.companyId}
                data-name={teamInfo.companyName}
                data-type={"team"}
                component={"div"}
                className={classnames({ [classes.root]: true, [classes.selected]: selected != null && selected, [classes.hover]: disabled != null && !selected })}
                style={style}
                disabled={disabled != null && disabled}
                onClick={() => !disabled && this.props.onClick && this.props.onClick(teamInfo.companyId)}
                disableRipple
            >
                <ImageWrapper
                    style={{ flex: "0 0 auto", width: "2em", height: "2em", borderRadius: "50%" }}
                    src={!StringUtils.isNullOrEmpty(teamInfo.imageUrl) ? "files/portal/c/" + teamInfo.companyId + teamInfo.imageUrl : null}
                    fallback={<NoTeamImage />}
                    disableCache={teamInfo.updatedAt || null}
                />

                <Tooltip
                    arrow
                    title={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>{teamInfo.companyName}</Typography>

                            {teamInfo.accountNumber && (
                                <Typography>
                                    <Trans>(Account Number: {teamInfo.accountNumber})</Trans>
                                </Typography>
                            )}
                        </span>
                    }
                >
                    <Typography style={{ flex: "1 1 auto", fontWeight: "bold", marginLeft: "0.3125em", marginRight: "0.3125em" }} noWrap>
                        {teamInfo.companyName}
                    </Typography>
                </Tooltip>

                {selected != null && selected && <SelectedIcon style={{ flex: "0 0 auto", marginLeft: "auto", color: "var(--navigation-team-context-color, inherit)" }} />}
            </MenuItem>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",

            padding: "0.3125em",

            overflow: "hidden",

            "&:hover": {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
            },
        },
        selected: {
            backgroundColor: "var(--navigation-app-selector-active-background-color, inherit)",
            color: "var(--navigation-app-selector-active-color, inherit)",

            "&:hover": {
                backgroundColor: "var(--navigation-app-selector-active-background-color, inherit)",
                color: "var(--navigation-app-selector-active-color, inherit)",
            },
        },
        hover: {
            "&:hover": {
                backgroundColor: "var(--navigation-app-selector-hover-background-color, inherit)",
                color: "var(--navigation-app-selector-hover-color, inherit)",
            },
        },
    });

export default withStyles(styles)(TeamMenuItem);
