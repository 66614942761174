// Import libraries.
import Http, { HttpResponse } from "utils/networking/Http";

// Import types.
import TeamInfo, { processTeamInfo } from "types/models/TeamInfo";
import AppInfo, { processAppInfo } from "types/models/AppInfo";
import AppDailyCounts, { processAppDailyCounts } from "types/models/AppDailyCounts";
import AppAggregateCounts, { processAppAggregateCounts } from "types/models/AppAggregateCounts";
import PlayerSummary, { processPlayerSummary } from "types/models/PlayerSummaryInfo";

export const getTeam = async (teamId: string): Promise<TeamInfo> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/company-read", { companyId: teamId }, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processTeamInfo(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getApps = async (): Promise<AppInfo[]> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/team-apps-read", null, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data && Array.isArray(response.data) ? response.data.map(processAppInfo) : []);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppDailyCounts = async (date?: Date): Promise<AppDailyCounts> => {
    const response: HttpResponse = await Http.GET("admin/reporting/dailyGameCounts-read", { dateInMillis: (date != null ? date : new Date()).getTime() }, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppDailyCounts(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppAggregateCounts = async (): Promise<AppAggregateCounts> => {
    const response: HttpResponse = await Http.GET("admin/reporting/aggregateGameCounts-read", null, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppAggregateCounts(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppCurrentUserCount = async (): Promise<number | null> => {
    const response: HttpResponse = await Http.GET("admin/reporting/getUpToDateUserCount", null, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data ? processAppCurrentUserCount(response.data) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getPlayerSummary = async (playerId: string): Promise<PlayerSummary | null> => {
    const response: HttpResponse = await Http.GET("admin/monitoring/playerSummaryByPlayerId", { playerId }, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data ? processPlayerSummary(response.data) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const processAppCurrentUserCount = (data: any): number => {
    return Number.parseInt(data.upToDateUserCount);
};

const Services = {
    getTeam: getTeam,
    getApps: getApps,
    getAppDailyCounts: getAppDailyCounts,
    getAppAggregateCounts: getAppAggregateCounts,
    getAppCurrentUserCount: getAppCurrentUserCount,
    getPlayerSummary: getPlayerSummary,
};

export default Services;
