export default interface AppAggregateCounts {
    account: {
        count: number;
        byPlatform: { [index: string]: number };
    };

    bulkOpsCount: number;

    ccScriptInvokeCount: number;

    companyId: string | null;

    createdAt?: Date;

    currentAccountCount: number;

    dauCount: number;

    dispV1CallCount: number;

    emailsSentCount: number;

    freePeerApiCallCount: number;

    gameId: string;

    kbDownloaded: number;

    mauCount: number;

    monthlyHistory: { [index: string]: any };

    notification: {
        count: number;
        byTemplateId: { [index: string]: number };
        byPromotionId: { [index: string]: number };
    };

    offboardEventCount: number;

    purchase: {
        count: number;
        byPlatform: { [index: string]: number };
    };

    revenue: {
        count: number;
        byPlatform: { [index: string]: number };
    };

    scheduledScriptCallCount: number;

    session: {
        count: number;
        byPlatform: { [index: string]: number };
        byLibrary: { [index: string]: number };
        byLibraryVersion: { [index: string]: number };
    };

    updatedAt?: Date;
}

export const processAppAggregateCounts = (data: any): AppAggregateCounts => {
    return {
        account: {
            count: data.accountCount,
            byPlatform: data.accountCountPlatform,
        },
        bulkOpsCount: data.bulkOpsCount,
        ccScriptInvokeCount: data.ccScriptInvokeCount,
        companyId: data.companyId,
        createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
        currentAccountCount: data.currentAccountCount,
        dauCount: data.dauCount,
        dispV1CallCount: data.dispV1CallCount,
        emailsSentCount: data.emailsSentCount,
        freePeerApiCallCount: data.freePeerApiCallCount,
        gameId: data.gameId,
        kbDownloaded: data.kbDownloaded,
        mauCount: data.mauCount,
        monthlyHistory: data.monthlyHistory,
        notification: {
            count: data.notificationCount,
            byTemplateId: data.notificationCountPromotionId,
            byPromotionId: data.notificationCountTemplateId,
        },
        offboardEventCount: data.offboardEventCount,
        purchase: {
            count: data.purchaseCount,
            byPlatform: data.purchaseCountPlatform,
        },
        revenue: {
            count: data.revenue,
            byPlatform: data.revenuePlatform,
        },
        scheduledScriptCallCount: data.scheduledScriptCallCount,
        session: {
            count: data.sessionCount,
            byPlatform: data.sessionCountPlatform,
            byLibrary: data.sessionCountLibrary,
            byLibraryVersion: data.sessionCountLibraryVersion,
        },
        updatedAt: data.updatedAt ? new Date(data.updatedAt) : undefined,
    };
};
