// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { I18n } from "@lingui/core";
import { withI18n, withI18nProps } from "@lingui/react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { Trans } from "@lingui/macro";
import QueryString from "query-string";

// Import framework.
import { withOrientation, WithOrientationProps } from "framework/withOrientation";

// Import types.
import PortalState from "types/store";
import EnvironmentInformation from "types/common/EnvironmentInformation";
import ApplicationInformation from "types/common/ApplicationInformation";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import Session from "types/common/Session";
import User from "types/common/User";
import AccessMode from "types/enums/AccessMode";
import TwoFactorType from "types/enums/TwoFactorType";
import SupportedLanguage from "types/enums/SupportedLanguage";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";

// Import components.
import { Card, Link, Tooltip, Typography } from "@mui/material";
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import Activate from "components/auth/Activate";
import Recover from "components/auth/Recover";
import Verify2FA from "components/auth/Verify2FA";
import AppVersion from "components/common/widgets/AppVersion";
import ServerStatus from "components/common/widgets/ServerStatus";
import ResetPassword from "components/auth/ResetPassword";
import SelectTeam from "components/auth/SelectTeam";
import CreateTeam from "components/auth/CreateTeam";
import Button from "components/common/button/Button";
import SystemMessageBanner from "components/common/screen/SystemMessageBanner";

// Import icons.
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";

// Import utilities.
import StringUtils from "utils/String";
import CloneUtils from "utils/Clone";
import LocalizationUtils from "utils/Localization";

enum Mode {
    LOGIN = "LOGIN",
    VERIFY_2FA = "VERIFY_2FA",
    SELECT_TEAM = "SELECT_TEAM",
    CREATE_TEAM = "CREATE_TEAM",
    REGISTER_ACCOUNT = "REGISTER_ACCOUNT",
    ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT",
    RECOVER_ACCOUNT = "RECOVER_ACCOUNT",
    RESET_PASSWORD = "RESET_PASSWORD",
    READY = "READY",
}

interface STATE_PROPS {
    environmentInformation: EnvironmentInformation;
    applicationInformation: ApplicationInformation;
    themeConfiguration: ThemeConfiguration;
    session: Session;
    currentUser: User | null;
}
interface DISPATCH_PROPS {
    setSession: (session: Session) => void;
    saveSession: () => void;
    completeLogin: (i18n: I18n, mode: "login" | "full") => void;
    populateAvailableCompanies: () => void;
    setCompanyId: (i18n: I18n, companyId: string | null, path?: string, history?: History) => void;
    logout: () => void;
}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps, WithOrientationProps {}

interface STATE {
    mode: Mode;
    twoFactorType: TwoFactorType | null;
    initialCompanyId: string | null;
    isRestricted: boolean;
    showCarousel: boolean;
}

const mapStateToProps = (state: PortalState) => {
    return {
        environmentInformation: state.environmentInformation,
        applicationInformation: state.applicationInformation,
        themeConfiguration: state.themeConfiguration,
        session: state.session,
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setSession: (session: Session) => dispatch(SET_SESSION(session)),
        saveSession: () => dispatch({ type: "session.saveSession" }),
        completeLogin: (i18n: I18n, mode: "login" | "full") => dispatch({ type: "authentication.completeLogin", payload: { i18n, mode } }),
        populateAvailableCompanies: () => dispatch({ type: "company.populateAvailableCompanies" }),
        setCompanyId: (i18n: I18n, companyId: string | null, path?: string, history?: History) => dispatch({ type: "company.setCompanyId", payload: { i18n, companyId, path, history } }),
        logout: () => dispatch({ type: "authentication.logout" }),
    };
};

class PortalUnauthenticated extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        mode: Mode.LOGIN,
        twoFactorType: null,
        initialCompanyId: null,
        isRestricted: false,
        showCarousel: false,
    };
    private popoverContainerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const { session } = this.props;

        // Extract any query string parameters that may have been supplied.
        let queryString = window.location.href.indexOf("?") >= 0 ? window.location.href.substring(window.location.href.indexOf("?") + 1) : null;
        if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));
        const queryParams = queryString ? QueryString.parse(queryString) : {};

        // Extract any hash/doc-frag that may have been supplied.
        const hashString = window.location.href.indexOf("#") >= 0 ? window.location.href.substring(window.location.href.indexOf("#") + 1) : null;

        /*** BACKWARDS-COMPATABILITY (for supporting certain links intended for the Legacy Portal): ***/
        //
        // Processing account activation link from email.
        //     - The current link is actually more or less ok, we have Apache rewrite the requested URL.
        //     - See the "httpd.conf" file for relavent RewriteRule's.
        //
        // Processing reset password link from email (based on docfrag/hash, so we need some "extra" processing here).
        //     - The current link is pretty bad (hash/docfrag based), we have Apache rewrite the requested URL.
        //     - See the "httpd.conf" file for relavent RewriteRule's.
        //     - We also process the hash/docfrag here and replace the state of the history (Apache doesn't have access to the hash/docfrag).
        //

        // Support the legacy reset password link.
        const hashMatches = hashString ? hashString.match("\\/changePassword\\/email\\/([^\\/]*)\\/token\\/([^\\/]*)") : null;
        if (hashMatches && hashMatches.length > 2 && hashMatches[1].trim().length > 0 && hashMatches[2].trim().length > 0) {
            // If the docfrag/hash matches what the :legacy Portal accepted as a "reset password" with email/token, then force a redirect with the proper query string paramaeters.
            queryParams["email"] = decodeURIComponent(hashMatches[1]);
            queryParams["recoveryToken"] = decodeURIComponent(hashMatches[2]);

            window.history.replaceState(null, "", "?email=" + encodeURIComponent(decodeURIComponent(hashMatches[1])) + "&recoveryToken=" + encodeURIComponent(decodeURIComponent(hashMatches[2])));
        }
        /*** DONE ***/

        if (!Array.isArray(queryParams.automatedTest) && StringUtils.isTruthy(queryParams.automatedTest)) {
            this.setState({ showCarousel: false });
        } else {
            this.setState({ showCarousel: true });
        }

        if (!Array.isArray(queryParams.email) && !StringUtils.isNullOrEmpty(queryParams.email) && !Array.isArray(queryParams.activationCode) && !StringUtils.isNullOrEmpty(queryParams.activationCode)) {
            // Automatically switch to ACTIVATE_ACCOUNT mode.
            this.setState({ mode: Mode.ACTIVATE_ACCOUNT });
        } else if (!Array.isArray(queryParams.email) && !StringUtils.isNullOrEmpty(queryParams.email) && !Array.isArray(queryParams.recoveryToken) && !StringUtils.isNullOrEmpty(queryParams.recoveryToken)) {
            // Automatically switch to RESET_PASSWORD mode.
            this.setState({ mode: Mode.RESET_PASSWORD });
        } else if (!Array.isArray(queryParams.signup) && StringUtils.isTruthy(queryParams.signup)) {
            // Automatically switch to REGISTER_ACCOUNT mode.
            this.setState({ mode: Mode.REGISTER_ACCOUNT });
        } else {
            // Check the session to place the user in the correct mode.
            if (session.isAuthenticated) {
                // If the user is authenticated but has no selected companyId then we automatically switch to SELECT_TEAM mode.
                if (!session.companyId) {
                    this.setState({ mode: Mode.SELECT_TEAM });
                }
            } else {
                // Otherwise we automatically switch to LOGIN mode.
                // The LOGIN mode is the default, so this should be a no-op.
                this.setState({ mode: Mode.LOGIN });
            }
        }

        if (!this.props.isMobile || this.props.isLandscape) {
            // Find the frame representing the carousel.
            let carouselFrame: Window | null = null;
            for (let x = 0; x < window.length; x++) {
                if (window[x].name === "carousel") {
                    carouselFrame = window[x];
                    break;
                }
            }

            if (carouselFrame) {
                // Add a focus listener to the carousel frame so we can "click" the main document body and refocus the carousel frame.
                // This results in triggering any ClickAwayListener's that may be present in Portal-X.
                // Since the carousel is an iFrame, click events on elements within it do not cross the iframe border.
                carouselFrame.addEventListener("focus", () => {
                    document.body.click();
                    carouselFrame?.parent.focus();
                });
            }
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.isMobile !== this.props.isMobile || prevProps.isLandscape !== this.props.isLandscape) {
            if (!this.props.isMobile || this.props.isLandscape) {
                // Find the frame representing the carousel.
                let carouselFrame: Window | null = null;
                for (let x = 0; x < window.length; x++) {
                    if (window[x].name === "carousel") {
                        carouselFrame = window[x];
                        break;
                    }
                }

                if (carouselFrame) {
                    // Add a focus listener to the carousel frame so we can "click" the main document body and refocus the carousel frame.
                    // This results in triggering any ClickAwayListener's that may be present in Portal-X.
                    // Since the carousel is an iFrame, click events on elements within it do not cross the iframe border.
                    carouselFrame.addEventListener("focus", () => {
                        document.body.click();
                        carouselFrame?.parent.focus();
                    });
                }
            }
        }

        if (
            (prevProps.applicationInformation.loadingLoginState || prevProps.applicationInformation.loadingBasicState) &&
            !this.props.applicationInformation.loadingLoginState &&
            !this.props.applicationInformation.loadingBasicState &&
            this.props.currentUser
        ) {
            let isRestricted = true;

            // First, determine whether or not Portal-X is in RESTRICTED mode.
            // Possible modes:
            //     - 'disabled' - No one has access (default).
            //     - 'open' - Everyone has access.
            //     - 'restricted' - Access is restricted to flagged user's.
            //     - 'super' - Access is restricted to super user's.
            switch (this.props.applicationInformation.accessMode) {
                case AccessMode.OPEN:
                    isRestricted = false;

                    break;
                case AccessMode.RESTRICTED:
                    if (this.props.currentUser.customData?.portalxAccessPermitted) {
                        isRestricted = false;
                    }

                    break;
                case AccessMode.SUPER:
                    if (this.props.currentUser.isSuper || this.props.currentUser.customData?.portalxAccessPermitted) {
                        isRestricted = false;
                    }

                    break;
                default:
                // Do nothing.
            }

            this.setState({ isRestricted: isRestricted }, () => {
                if (this.state.isRestricted) {
                    this.onSwitchToMode(Mode.LOGIN);
                }
            });
        }
    }

    onSwitchToMode = (newMode: Mode, params?: any) => {
        const { i18n, session } = this.props;
        const { mode } = this.state;

        // If the new mode is the same as the current mode, just return (nothing to do).
        if (newMode === mode) return;

        const updatedSession = CloneUtils.clone(session) as Session;

        // Handle post-processing for the previous mode (if applicable) before we actually complete the switch to the new mode.
        switch (mode) {
            case Mode.LOGIN:
                if (newMode === Mode.SELECT_TEAM) {
                    updatedSession.isAuthenticated = true;

                    // If there is an external authentication provider attached to the session, then remove it.
                    // It will be present if the user logged in via an external provider (i.e. SSO).
                    if (updatedSession.externalAuthenticationProvider) {
                        delete updatedSession.externalAuthenticationProvider;
                    }

                    window.history.replaceState({}, document.title, "/");

                    // Update the session in the redux store.
                    this.props.setSession(updatedSession);
                    this.props.saveSession();

                    this.props.completeLogin(i18n, "login");
                }

                if (newMode === Mode.VERIFY_2FA) {
                    this.setState({ twoFactorType: (params?.type as TwoFactorType | null) || null }, () => {
                        // If there is an external authentication provider attached to the session, then remove it.
                        // It will be present if the user logged in via an external provider (i.e. SSO).
                        if (updatedSession.externalAuthenticationProvider) {
                            delete updatedSession.externalAuthenticationProvider;
                        }

                        window.history.replaceState({}, document.title, "/");

                        // Update the session in the redux store.
                        this.props.setSession(updatedSession);
                        this.props.saveSession();
                    });
                }

                break;
            case Mode.VERIFY_2FA:
                this.setState({ twoFactorType: null }, () => {
                    if (newMode === Mode.SELECT_TEAM) {
                        updatedSession.isAuthenticated = true;

                        // Update the session in the redux store.
                        this.props.setSession(updatedSession);
                        this.props.saveSession();

                        this.props.completeLogin(i18n, "login");
                    }

                    if (newMode === Mode.LOGIN) {
                        this.props.logout();
                    }
                });

                break;
            case Mode.SELECT_TEAM:
                if (newMode === Mode.CREATE_TEAM) {
                    if (params?.companyId) {
                        this.setState({ initialCompanyId: params.companyId as string });
                    }
                }

                if (newMode === Mode.READY) {
                    this.props.setCompanyId(i18n, (params?.companyId as string) || null);
                }

                if (newMode === Mode.LOGIN) {
                    this.props.logout();
                }

                break;
            case Mode.CREATE_TEAM:
                if (newMode === Mode.SELECT_TEAM) {
                    if (params?.companyId) {
                        this.props.populateAvailableCompanies();

                        this.setState({ initialCompanyId: params.companyId as string });
                    }
                }

                if (newMode === Mode.LOGIN) {
                    this.props.logout();
                }

                break;
            case Mode.REGISTER_ACCOUNT:
                if (newMode === Mode.LOGIN) {
                    window.history.replaceState({}, document.title, "/");

                    updatedSession.email = (params?.email as string) || "";

                    // Update the session in the redux store.
                    this.props.setSession(updatedSession);
                    this.props.saveSession();
                }

                break;
            case Mode.ACTIVATE_ACCOUNT:
                if (newMode === Mode.LOGIN) {
                    window.history.replaceState({}, document.title, "/");

                    updatedSession.email = (params?.email as string) || "";

                    // Update the session in the redux store.
                    this.props.setSession(updatedSession);
                    this.props.saveSession();
                }

                break;
            case Mode.RECOVER_ACCOUNT:
                if (newMode === Mode.LOGIN) {
                    window.history.replaceState({}, document.title, "/");

                    updatedSession.email = (params?.email as string) || "";

                    // Update the session in the redux store.
                    this.props.setSession(updatedSession);
                    this.props.saveSession();
                }

                break;
            case Mode.RESET_PASSWORD:
                if (newMode === Mode.LOGIN) {
                    window.history.replaceState({}, document.title, "/");

                    updatedSession.email = (params?.email as string) || "";

                    // Update the session in the redux store.
                    this.props.setSession(updatedSession);
                    this.props.saveSession();
                }

                break;
            default:
            // Do nothing.
        }

        // Update the current mode to be the new mode.
        this.setState({ mode: newMode });
    };

    onToggleLanguage = async () => {
        const { i18n } = this.props;

        const activeLanguage = LocalizationUtils.getActiveLanguage(i18n);
        const toggleLanguage = activeLanguage === SupportedLanguage.ENGLISH ? SupportedLanguage.FRENCH : SupportedLanguage.ENGLISH;

        await LocalizationUtils.setActiveLanguage(i18n, toggleLanguage);

        this.forceUpdate();
    };

    render() {
        const { classes, isMobile, isPortrait, i18n, environmentInformation, themeConfiguration, session } = this.props;
        const { mode, twoFactorType, initialCompanyId, isRestricted, showCarousel } = this.state;

        // Get the active language.
        const activeLanguage = LocalizationUtils.getActiveLanguage(i18n);
        const toggleLanguage = activeLanguage === SupportedLanguage.ENGLISH ? SupportedLanguage.FRENCH : SupportedLanguage.ENGLISH;

        // Get the active theme mode.
        const activeTheme = themeConfiguration.activeTheme;
        const activeThemeMode = activeTheme ? activeTheme[session.themeMode] : null;

        // Get the logo src, width and height from the active theme mode.
        const logoUrl =
            activeThemeMode && activeThemeMode.loginLogoUrl && activeThemeMode.loginLogoUrl.startsWith("../")
                ? "/api/" + activeThemeMode.loginLogoUrl.substring(3)
                : activeThemeMode && activeThemeMode.loginLogoUrl
                ? activeThemeMode.loginLogoUrl
                : null;
        const logoWidth = activeThemeMode?.cssVariables["login-logo-width"];

        // Get the URL reference for the custom carousel from the environment information (overrides the default carousel).
        const carouselUrl = environmentInformation.carouselUrl;

        return (
            <div id="unauthenticated" className={classes.root} ref={isMobile && isPortrait ? this.popoverContainerRef : undefined}>
                <Card className={classes.card}>
                    <div id="component-container" className={classes.componentContainer} ref={!isMobile || !isPortrait ? this.popoverContainerRef : undefined}>
                        <div id="buttons" className={classes.buttons}>
                            {!session.isAuthenticated && (
                                <Tooltip title={<Trans>Switch to {LocalizationUtils.formatLanguage(i18n, toggleLanguage)}</Trans>}>
                                    <Button id={"toggle-language"} type={"neutral"} style={{ width: "2em", height: "2em" }} onClick={this.onToggleLanguage}>
                                        {toggleLanguage}
                                    </Button>
                                </Tooltip>
                            )}
                        </div>

                        <div id="header" className={classes.header}>
                            <img
                                style={{
                                    cursor: "pointer",
                                    width: logoWidth || undefined,
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    margin: "auto",
                                }}
                                src={logoUrl || "/images/brainCloud_logo.png"}
                                alt={"brainCloud-logo"}
                                onClick={() => this.onSwitchToMode(Mode.LOGIN)}
                            />
                        </div>

                        <div id="component" className={classes.component}>
                            <SystemMessageBanner style={{ margin: "0.625em 0.3125em" }} />

                            {mode === Mode.LOGIN && (
                                <Login
                                    isRestricted={isRestricted}
                                    onComplete={(requires2FA: boolean, type: TwoFactorType | null) => {
                                        this.onSwitchToMode(requires2FA ? Mode.VERIFY_2FA : Mode.SELECT_TEAM, requires2FA ? { type } : undefined);
                                    }}
                                    onRegister={() => this.onSwitchToMode(Mode.REGISTER_ACCOUNT)}
                                    onRecover={() => this.onSwitchToMode(Mode.RECOVER_ACCOUNT)}
                                />
                            )}
                            {mode === Mode.VERIFY_2FA && <Verify2FA type={twoFactorType} onCancel={() => this.onSwitchToMode(Mode.LOGIN)} onComplete={() => this.onSwitchToMode(Mode.SELECT_TEAM)} />}
                            {mode === Mode.SELECT_TEAM && (
                                <SelectTeam
                                    onCreate={(companyId: string | null) => this.onSwitchToMode(Mode.CREATE_TEAM, { companyId })}
                                    onCancel={() => this.onSwitchToMode(Mode.LOGIN)}
                                    onComplete={(companyId: string | null) => this.onSwitchToMode(Mode.READY, { companyId })}
                                    companyId={initialCompanyId}
                                    selectTeamContainerRef={this.popoverContainerRef}
                                />
                            )}
                            {mode === Mode.CREATE_TEAM && <CreateTeam onCancel={() => this.onSwitchToMode(Mode.SELECT_TEAM)} onComplete={(companyId: string | null) => this.onSwitchToMode(Mode.SELECT_TEAM, { companyId })} />}
                            {mode === Mode.REGISTER_ACCOUNT && <Register onCancel={() => this.onSwitchToMode(Mode.LOGIN)} onComplete={(email: string | null) => this.onSwitchToMode(Mode.LOGIN, { email })} />}
                            {mode === Mode.ACTIVATE_ACCOUNT && <Activate onCancel={() => this.onSwitchToMode(Mode.LOGIN)} onComplete={(email: string | null) => this.onSwitchToMode(Mode.LOGIN, { email })} />}
                            {mode === Mode.RECOVER_ACCOUNT && <Recover onCancel={() => this.onSwitchToMode(Mode.LOGIN)} onComplete={(email: string | null) => this.onSwitchToMode(Mode.LOGIN, { email })} />}
                            {mode === Mode.RESET_PASSWORD && <ResetPassword onCancel={() => this.onSwitchToMode(Mode.LOGIN)} onComplete={(email: string | null) => this.onSwitchToMode(Mode.LOGIN, { email })} />}

                            {mode === Mode.READY && (
                                <div className={classes.loginComplete}>
                                    <SuccessIcon />

                                    <Typography>
                                        <Trans>Login Complete</Trans>
                                    </Typography>
                                </div>
                            )}
                        </div>

                        <div id="footer" className={classes.footer}>
                            <ServerStatus />
                            <AppVersion />

                            <Link data-id={"cookie-preferences"} className={classes.cookiePreferences} onClick={() => window.document.getElementById("open_preferences_center")?.click()} noWrap>
                                <Trans>Change Your Cookie Preferences</Trans>
                            </Link>
                        </div>
                    </div>

                    <div id="carousel-container" className={classes.carouselContainer}>
                        {showCarousel && <iframe id={"carousel"} name={"carousel"} title={"carousel"} className={classes.carousel} src={process.env.NODE_ENV === "production" ? carouselUrl || "/carousel/index.html" : "/carousel/index.html"} />}
                        {!showCarousel && (
                            <Typography style={{ margin: "auto" }}>
                                <Trans>Carousel Disabled</Trans>
                            </Typography>
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },

        card: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",

            overflow: "hidden",

            "& > div": {
                "&:first-child": {
                    flex: "0 0 auto",
                    transition: "width 0.5s linear, padding 0.5s linear",
                },
                "&:last-child": {
                    flex: "1 1 auto",
                },
            },

            transition: "margin 0.5s linear, border-radius 0.5s linear",

            "@media (max-width: 600px)": {
                "& > div:first-child": {
                    width: "100%",
                },
                "& > div:last-child": {
                    display: "none",
                },

                borderRadius: 0,
                margin: "0em",
            },
            "@media (min-width: 600px)": {
                "& > div:first-child": {
                    width: "20em",
                    padding: "1em",
                },

                borderRadius: 0,
                margin: "0em",
            },
            "@media (min-width: 1000px)": {
                "& > div:first-child": {
                    width: "30em",
                    padding: "2em",
                },

                borderRadius: "0.25em",
                margin: "2em 4em 2em 4em",
            },
        },

        componentContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
            position: "relative",
        },
        header: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            minHeight: "4em",
            height: "20%",

            padding: "0.5em",
        },
        component: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
        footer: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            padding: "0.5em",
        },

        carouselContainer: {
            display: "flex",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        carousel: {
            flex: "1 1 auto",
            border: "none",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },

        cookiePreferences: {
            flex: "0 0 auto",

            marginTop: "0.3125em",

            fontSize: "0.75em",
        },

        loginComplete: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",

            overflow: "hidden",

            "& > *": {
                marginLeft: "auto",
                marginRight: "auto",

                "&.MuiSvgIcon-root": {
                    width: "6em",
                    height: "6em",

                    color: "var(--label-positive-color, inherit)",
                },

                "&.MuiTypography-root": {
                    marginTop: "2em",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                },
            },
        },
        buttons: {
            display: "flex",
            alignItems: "center",
            padding: "0.5em",
            position: "absolute",
            right: 0,
            top: 0,
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withOrientation()(withI18n()(withStyles(styles)(PortalUnauthenticated))));
