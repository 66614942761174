// Import libraries.
import React, { useCallback } from "react";
import { Theme, Tooltip } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";
import { Trans } from "@lingui/macro";

// Import types.
import { ActionDefinition, ColumnDefinition, RowData } from "../../types";

// Import components.
import { TableRow, TableCell } from "@mui/material";
import IconButton from "components/common/button/IconButton";
import FieldWrapper from "components/common/form/FieldWrapper";
import Cell from "../Cell";

// Import icons.
import MoreActionsIcon from "@mui/icons-material/MoreVert";

interface OWN_PROPS {
    id: string;

    rowIdx: number;

    className?: string;

    height?: string | number;

    definitions: ColumnDefinition[];

    row: RowData;

    primaryKey: string;

    selectMode?: "single" | "multi"; // The selection mode behaviour ("single" or "multi" mode, applies to the current page. Changing the page clears the selections).
    selected?: boolean;

    actions?: ActionDefinition[] | ((row: RowData) => React.ReactNode);

    swapSelectAndActionColumns?: boolean; // Determines whether the "select" and "actions" columns are swapped (such that actions is on the left and the select is on the right).

    onClick?: (row: RowData, rowIdx: number, colIdx: number) => void;
    onChange?: (columnId: string, newValue: any, row: RowData) => void;
    onToggleSelection?: (row: RowData) => void;
    onOpenActionMenu?: (event: React.SyntheticEvent<HTMLElement>, row: RowData) => void;
    getColumnClassName?: (definition: ColumnDefinition, row: RowData) => string | undefined;

    onCellHeightCalculated: ((rowIdx: number, colIdx: number, height: number) => void) | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const Row = (props: PROPS) => {
    const { classes, id, rowIdx, className, height, definitions, row, primaryKey, selectMode, selected, actions, swapSelectAndActionColumns, onClick, onChange, onToggleSelection, onOpenActionMenu, getColumnClassName, onCellHeightCalculated } = props;

    const handleToggleSelection = useCallback(() => {
        if (onToggleSelection) {
            onToggleSelection(row);
        }
    }, [row, onToggleSelection]);

    const handleOpenActionMenu = useCallback(
        (event: React.SyntheticEvent<HTMLElement>) => {
            if (onOpenActionMenu) {
                onOpenActionMenu(event, row);
            }
        },
        [row, onOpenActionMenu]
    );

    return (
        <TableRow id={id} data-name={row[primaryKey]} data-index={rowIdx} className={classnames(classes.root, className)} hover tabIndex={-1}>
            {!swapSelectAndActionColumns && (
                <>
                    {selectMode && (
                        <TableCell id={id + "-select"} className={classnames(classes.stickyColumnLeft, "MuiTableCell-stickyHeader")} padding="checkbox" style={{ padding: 0 }}>
                            <div className={classes.wrapper} style={{ minHeight: "1em", height: height, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",

                                        overflow: "hidden",

                                        zIndex: 7,
                                    }}
                                >
                                    <FieldWrapper
                                        style={{ margin: 0 }}
                                        controlStyle={{ backgroundColor: "transparent" }}
                                        innerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                                        name={"select"}
                                        type={"checkbox"}
                                        value={selected}
                                        onChange={handleToggleSelection}
                                    />
                                </div>

                                <div className="stripeOverlay" style={{ zIndex: 5 }}></div>
                                <div className="hoverOverlay" style={{ zIndex: 6 }}></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}

            {swapSelectAndActionColumns && (
                <>
                    {actions && (typeof actions === "function" || actions.length > 0) && (
                        <TableCell id={id + "-actions"} className={classnames(classes.stickyColumnRight, "MuiTableCell-stickyHeader")}>
                            <div className={classes.wrapper} style={{ minHeight: "1em", height: height, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",

                                        padding: actions && typeof actions !== "function" ? 0 : "0.3125em",

                                        overflow: "hidden",

                                        zIndex: 7,
                                    }}
                                >
                                    {actions && typeof actions === "function" && <span style={{ zIndex: 7 }}>{actions(row)}</span>}

                                    {actions && typeof actions !== "function" && actions.length > 0 && (
                                        <Tooltip arrow placement={"top"} title={<Trans>Actions</Trans>}>
                                            <IconButton id={"more-actions"} style={{ marginLeft: "auto", marginRight: "auto" }} aria-label="more actions" onClick={handleOpenActionMenu}>
                                                <MoreActionsIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>

                                <div className="stripeOverlay" style={{ zIndex: 5 }}></div>
                                <div className="hoverOverlay" style={{ zIndex: 6 }}></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}

            {definitions.map((definition: ColumnDefinition, colIdx: number) => {
                const columnClassName = getColumnClassName ? getColumnClassName(definition, row) : undefined;

                return (
                    <Cell
                        key={colIdx}
                        id={id + "-" + colIdx}
                        rowIdx={rowIdx}
                        colIdx={colIdx}
                        className={columnClassName}
                        height={height}
                        definition={definition}
                        row={row}
                        onClick={onClick}
                        onChange={onChange}
                        onHeightCalculated={onCellHeightCalculated}
                    ></Cell>
                );
            })}

            {!swapSelectAndActionColumns && (
                <>
                    {actions && (typeof actions === "function" || actions.length > 0) && (
                        <TableCell id={id + "-actions"} className={classnames(classes.stickyColumnRight, "MuiTableCell-stickyHeader")}>
                            <div className={classes.wrapper} style={{ minHeight: "1em", height: height, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",

                                        padding: actions && typeof actions !== "function" ? 0 : "0.3125em",

                                        overflow: "hidden",

                                        zIndex: 7,
                                    }}
                                >
                                    {actions && typeof actions === "function" && <span style={{ zIndex: 7 }}>{actions(row)}</span>}

                                    {actions && typeof actions !== "function" && actions.length > 0 && (
                                        <Tooltip arrow placement={"top"} title={<Trans>Actions</Trans>}>
                                            <IconButton id={"more-actions"} style={{ marginLeft: "auto", marginRight: "auto" }} aria-label="more actions" onClick={handleOpenActionMenu}>
                                                <MoreActionsIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>

                                <div className="stripeOverlay" style={{ zIndex: 5 }}></div>
                                <div className="hoverOverlay" style={{ zIndex: 6 }}></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}

            {swapSelectAndActionColumns && (
                <>
                    {selectMode && (
                        <TableCell id={id + "-select"} className={classnames(classes.stickyColumnRight, "MuiTableCell-stickyHeader")} padding="checkbox" style={{ padding: 0 }}>
                            <div className={classes.wrapper} style={{ minHeight: "1em", height: height, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",

                                        overflow: "hidden",

                                        zIndex: 7,
                                    }}
                                >
                                    <FieldWrapper
                                        style={{ margin: 0 }}
                                        controlStyle={{ backgroundColor: "transparent" }}
                                        innerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                                        name={"select"}
                                        type={"checkbox"}
                                        value={selected}
                                        onChange={handleToggleSelection}
                                    />
                                </div>

                                <div className="stripeOverlay" style={{ zIndex: 5 }}></div>
                                <div className="hoverOverlay" style={{ zIndex: 6 }}></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}
        </TableRow>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontSize: "inherit",
        },
        wrapper: {
            position: "relative",

            width: "100%",

            display: "flex",
            alignItems: "flex-start",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",

            "& *": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },

            "& .MuiTypography-root": {
                fontSize: "inherit",
            },
        },
        stickyColumnLeft: {
            position: "sticky",
            left: 0,

            padding: 0,

            cursor: "default",

            zIndex: 4,
        },
        stickyColumnRight: {
            position: "sticky",
            right: 0,

            padding: 0,

            cursor: "default",

            zIndex: 4,
        },
    });

export default withStyles(styles)(Row);
