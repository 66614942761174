// Import libraries.
import React, { CSSProperties } from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";

// Import components.
import { MenuItem, Tooltip, Typography } from "@mui/material";
import ImageWrapper from "components/common/ImageWrapper";
import TeamInfo from "types/models/TeamInfo";

// Import icons.
import SelectedIcon from "@mui/icons-material/FiberManualRecord";
import NoTeamImage from "components/icons/NoTeamImage";

// Import utilities.
import StringUtils from "utils/String";

interface OWN_PROPS {
    style?: CSSProperties;

    teamInfo: TeamInfo | null;

    disabled?: boolean;
    selected?: boolean;

    height?: number;

    onClick?: (companyId?: string) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class TeamMenu extends React.PureComponent<PROPS> {
    render() {
        const { classes, style, height, teamInfo, disabled, selected } = this.props;
        return (
            <MenuItem
                data-id={teamInfo?.companyId}
                data-name={teamInfo?.companyName}
                data-type={"app"}
                component={"div"}
                className={classnames({ [classes.root]: true, [classes.selected]: selected != null && selected, [classes.hover]: disabled != null && !selected })}
                style={style}
                onClick={() => this.props.onClick && this.props.onClick(teamInfo?.companyId)}
            >
                <ImageWrapper
                    style={{ width: "calc(" + height + "px - 0.625em)", height: "calc(" + height + "px - 0.625em)", marginRight: "0.3125em" }}
                    src={!StringUtils.isNullOrEmpty(teamInfo?.imageUrl) ? "files/portal/c/" + teamInfo?.companyId + teamInfo?.imageUrl : null}
                    fallback={<NoTeamImage />}
                    disableCache={teamInfo?.updatedAt || null}
                />

                <Tooltip arrow title={<Typography>{teamInfo?.companyName}</Typography>}>
                    <Typography style={{ flex: "1 1 auto", fontWeight: "bold" }} noWrap>
                        {teamInfo?.companyName}
                    </Typography>
                </Tooltip>

                {selected != null && selected && <SelectedIcon style={{ marginLeft: "auto", color: "var(--navigation-app-context-color, inherit)" }} />}
            </MenuItem>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            padding: "0.3125em",
            overflow: "visible",
            "&:hover": {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
            },
        },
        selected: {
            backgroundColor: "var(--navigation-team-selector-active-background-color, inherit)",
            color: "var(--navigation-team-selector-active-color, inherit)",

            "&:hover": {
                backgroundColor: "var(--navigation-team-selector-active-background-color, inherit)",
                color: "var(--navigation-team-selector-active-color, inherit)",
            },
        },
        hover: {
            "&:hover": {
                backgroundColor: "var(--navigation-team-selector-hover-background-color, inherit)",
                color: "var(--navigation-team-selector-hover-color, inherit)",
            },
        },
    });

export default withStyles(styles)(TeamMenu);
