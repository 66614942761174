// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Import types.
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";

// Import components.
import { Tooltip, Typography } from "@mui/material";
import Button from "components/common/button/Button";

// Import formatters.
import NumberFormatter from "utils/formatters/Number";

// Define the properties accepted by this component.
interface OWN_PROPS {
    style?: CSSProperties; // Optional styles that are applied to the field (overriding any defaults).
    name: string; // The name associated with the field.
    label?: React.ReactNode; // An optional custom label for the field.
    acceptedFiles?: string[]; // The types of files that may be selected (example: ['image/jpeg', 'image/png', 'image/bmp', '*.mp4']).
    filesLimit?: number; // The maximum number of files that are allowed to be selected (default is 1).
    maxFileSize?: number; // The maximum size of a any single file in bytes (must be less than the global max as defined in the "springMultiUploadMaxSizeBytes" BC property).
    onChange: (name: string, value: any) => void; // Callback function invoked when the field value changes.
    disabled?: boolean; // Disables the field (i.e. prevents choosing any files).
    tooltipText?: React.ReactNode; // Optional tooltip to be displayed when hovering the field.
}
interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
}
interface DISPATCH_PROPS {}
interface PROPS extends OWN_PROPS, STATE_PROPS, DISPATCH_PROPS, WithStyles<typeof styles>, withI18nProps {}

interface STATE {}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        applicationInformation: state.applicationInformation,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

class FileUpload extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {};
    private inputRef = React.createRef<HTMLInputElement>();

    openFileChooser = () => {
        if (this.inputRef.current) {
            this.inputRef.current.click();
        }
    };

    handleFilesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { applicationInformation, name, filesLimit, maxFileSize } = this.props;

        let maxPermittedSize = applicationInformation.maxFileUploadSizeInBytes;

        if (maxFileSize != null && maxFileSize > -1 && (maxPermittedSize === -1 || maxFileSize < maxPermittedSize)) {
            maxPermittedSize = maxFileSize;
        }

        // console.log("handleFilesChanged (Max Count = " + filesLimit + ", Max File Size: " + (maxPermittedSize > -1 ? maxPermittedSize / 1024 / 1024 + " MB" : "UNLIMITED"), e.target.files);

        if (e.target.files && e.target.files.length > 0) {
            if (filesLimit != null && e.target.files.length > filesLimit) {
                toast.error(
                    <span style={{ display: "flex", flexDirection: "column" }}>
                        <Typography>
                            <Trans>Exceeds Max File Count ({NumberFormatter.formatInteger(filesLimit)}):</Trans>
                        </Typography>

                        <Typography>
                            <Trans>- {NumberFormatter.formatInteger(e.target.files.length)} files selected</Trans>
                        </Typography>
                    </span>
                );

                this.clearInputValue();

                return;
            }

            const exceedsMaxSize: File[] = [];

            for (let x = 0; x < e.target.files.length; x++) {
                if (maxPermittedSize > -1 && e.target.files[x].size > maxPermittedSize) {
                    exceedsMaxSize.push(e.target.files[x]);
                }
            }

            if (exceedsMaxSize.length > 0) {
                toast.error(
                    <span style={{ display: "flex", flexDirection: "column" }}>
                        <Typography>
                            <Trans>Exceeds Max File Size ({NumberFormatter.formatInteger(maxPermittedSize / 1024 / 1024)} MB):</Trans>
                        </Typography>

                        {exceedsMaxSize.map((file, idx) => {
                            return (
                                <Typography key={idx}>
                                    <Trans>
                                        - {file.name}: {NumberFormatter.formatInteger(file.size / 1024 / 1024)} MB
                                    </Trans>
                                </Typography>
                            );
                        })}
                    </span>
                );

                this.clearInputValue();

                return;
            }
        }

        if (this.props.onChange) {
            const files: File[] = [];

            if (e.target.files) {
                for (let x = 0; x < e.target.files.length; x++) {
                    files.push(e.target.files[x]);
                }
            }

            this.props.onChange(name, files);
        }

        this.clearInputValue();
    };

    clearInputValue = () => {
        if (this.inputRef.current) {
            this.inputRef.current.value = "";
        }
    };

    render() {
        const { classes, style, name, label, acceptedFiles, filesLimit, disabled, tooltipText } = this.props;

        return (
            <div id={"file-upload-" + name} className={classes.root} style={style}>
                <input
                    ref={this.inputRef}
                    style={{ display: "none" }}
                    type={"file"}
                    accept={acceptedFiles ? acceptedFiles.join(",") : ["", "application/*", "audio/*", "image/*", "video/*", "text/*"].join(",")}
                    multiple={filesLimit != null && filesLimit > 1 ? true : undefined}
                    onChange={this.handleFilesChanged}
                />

                {tooltipText ? (
                    <Tooltip arrow title={<Typography>{tooltipText}</Typography>}>
                        <Button id={"add-file-or-files"} type={"primary"} style={{ width: "100%", margin: 0 }} onClick={this.openFileChooser} disabled={disabled}>
                            {filesLimit != null && filesLimit > 1 && (label ? label : <Trans>Add Files...</Trans>)}
                            {(filesLimit == null || filesLimit === 1) && (label ? label : <Trans>Add File...</Trans>)}
                        </Button>
                    </Tooltip>
                ) : (
                    <Button id={"add-file-or-files"} type={"primary"} style={{ width: "100%", margin: 0 }} onClick={this.openFileChooser} disabled={disabled}>
                        {filesLimit != null && filesLimit > 1 && (label ? label : <Trans>Add Files...</Trans>)}
                        {(filesLimit == null || filesLimit === 1) && (label ? label : <Trans>Add File...</Trans>)}
                    </Button>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            margin: "0.125em",
            overflow: "hidden",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(FileUpload)));
