// Import libraries.
import { I18n } from "@lingui/core";
import { select, put, call, all, takeLatest } from "redux-saga/effects";

// Import types.
import { parseAccessMode } from "types/enums/AccessMode";
import AuthType from "types/enums/AuthType";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import Session, { resetSession } from "types/common/Session";

// Import redux actions.
import { SET_APPLICATION_INFORMATION } from "store/actions/app";
import { SET_SESSION } from "store/actions/session";
import { DELETE_THEME } from "store/actions/theme";
import { SET_SCREEN_SETTINGS } from "store/actions/screenSettings";
import { SET_AVAILABLE_PRIVILEGES } from "store/actions/availablePrivileges";

// Import redux sagas.
import { saveSession } from "./sessionSagas";
import { setTheme } from "./themeSagas";
import { clearBasicState, populateBasicState } from "./initializationSagas";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import StringUtils from "utils/String";

// Import services.
import BrainCloudPropertiesService from "services/BrainCloudProperties";

interface CompleteLogin {
    type: "authentication.completeLogin";
    payload: {
        i18n: I18n;
        mode: "login" | "full";
    };
}

interface Logout {
    type: "authentication.logout";
    payload?: boolean;
}

export function* completeLogin(action: CompleteLogin) {
    // Define some getters for retrieving redux state.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);

    // Get the current application information.
    let applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    // Get the current session.
    let session: Session = yield select(getSession);

    try {
        console.log("Attempting To Complete Login...", action.payload.mode);

        if (action.payload.mode === "login") {
            if (!applicationInformation.loadingLoginState) {
                // Update the current application information.
                applicationInformation.loadingLoginState = true;
                yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
            }
        } else {
            if (!applicationInformation.loadingBasicState) {
                // Update the current application information.
                applicationInformation.loadingBasicState = true;
                yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
            }
        }

        // Fetch required properties and data from the server..
        const [
            bcSessionLengthResponse,
            bcSessionTimeoutResponse,
            bcSessionKeepAliveResponse,
            billingEnabledResponse,
            portalDisabledResponse,
            portalAccessModeResponse,
            tfaAuthyApiKeyResponse,
            tfaVerifyServiceSIDResponse,
            tfaTwilioAccountSIDResponse,
            tfaTwilioAuthTokenResponse,
            springMultiUploadMaxSizeBytesResponse,
            developerModeResponse,
            bcSessionSynchronizationTimeout,
            portalxSwitchToLegacyView,
        ]: PromiseSettledResult<any>[] = yield Promise.allSettled([
            BrainCloudPropertiesService.getSystemProperty("bcSessionLength"),
            BrainCloudPropertiesService.getSystemProperty("bcSessionTimeout"),
            BrainCloudPropertiesService.getSystemProperty("bcSessionKeepalive"),
            BrainCloudPropertiesService.getSystemProperty("billingEnabled"),
            BrainCloudPropertiesService.getSystemProperty("portalDisabled"),
            BrainCloudPropertiesService.getSystemProperty("portalxAccessMode"),
            BrainCloudPropertiesService.getSystemProperty("tfaAuthyApiKey"),
            BrainCloudPropertiesService.getSystemProperty("tfaVerifyServiceSID"),
            BrainCloudPropertiesService.getSystemProperty("tfaTwilioAccountSID"),
            BrainCloudPropertiesService.getSystemProperty("tfaTwilioAuthToken"),
            BrainCloudPropertiesService.getSystemProperty("springMultiUploadMaxSizeBytes"),
            BrainCloudPropertiesService.getSystemProperty("developerMode"),
            BrainCloudPropertiesService.getSystemProperty("bcSessionSynchronizationTimeout"),
            BrainCloudPropertiesService.getSystemProperty("portalxSwitchToLegacyView"),
        ]);

        // Clear out the session-related fields (we're repopulating them now).
        session.sessionLength = null;
        session.sessionTimeout = null;
        session.sessionKeepAlive = null;

        // Update the current session.
        yield put(SET_SESSION(CloneUtils.clone(session)));
        yield call(saveSession);

        let errors: any[] = [];

        // Process the session and billing properties.
        if (bcSessionLengthResponse.status !== "fulfilled") errors.push(new Error("Unable to fetch session length"));
        else session.sessionLength = StringUtils.toNumber(bcSessionLengthResponse.value);

        if (bcSessionTimeoutResponse.status !== "fulfilled") errors.push(new Error("Unable to fetch session timeout"));
        else session.sessionTimeout = StringUtils.toNumber(bcSessionTimeoutResponse.value);

        if (bcSessionKeepAliveResponse.status !== "fulfilled") errors.push(new Error("Unable to fetch session keep-alive"));
        else session.sessionKeepAlive = StringUtils.toNumber(bcSessionKeepAliveResponse.value);

        if (billingEnabledResponse.status !== "fulfilled") errors.push(new Error("Unable to fetch billing enabled"));
        else applicationInformation.billingConfiguration.enabled = StringUtils.isTruthy(billingEnabledResponse.value);

        // Process the portal disabled property.
        // We do this again when the user completes their authentication in case the portal was disabled after they first came to the portal.
        if (portalDisabledResponse.status === "fulfilled") applicationInformation.disabled = StringUtils.isTruthy(portalDisabledResponse.value);

        // Process the portal access mode property.
        // We do this again when the user completes their authentication in case the portal mode was modified after they first came to the portal.
        if (portalAccessModeResponse.status === "fulfilled") applicationInformation.accessMode = parseAccessMode(portalAccessModeResponse.value);

        // Process the two factor configuration properties.
        if (tfaAuthyApiKeyResponse.status === "fulfilled") {
            if (!StringUtils.isNullOrEmpty(tfaAuthyApiKeyResponse.value)) {
                applicationInformation.twoFactorConfiguration.enabled = true;
                applicationInformation.twoFactorConfiguration.authyAvailable = true;
            }
        }
        if (tfaVerifyServiceSIDResponse.status === "fulfilled" && tfaTwilioAccountSIDResponse.status === "fulfilled" && tfaTwilioAuthTokenResponse.status === "fulfilled") {
            if (!StringUtils.isNullOrEmpty(tfaVerifyServiceSIDResponse.value) && !StringUtils.isNullOrEmpty(tfaTwilioAccountSIDResponse.value) && !StringUtils.isNullOrEmpty(tfaTwilioAuthTokenResponse.value)) {
                applicationInformation.twoFactorConfiguration.enabled = true;
                applicationInformation.twoFactorConfiguration.verifyAvailable = true;
            }
        }

        // Process the max file upload size in bytes property.
        if (springMultiUploadMaxSizeBytesResponse.status === "fulfilled") applicationInformation.maxFileUploadSizeInBytes = StringUtils.toNumber(springMultiUploadMaxSizeBytesResponse.value);

        // Process the developer mode property.
        if (developerModeResponse.status === "fulfilled") {
            session.developerMode = Boolean(developerModeResponse.value);

            if (session.developerMode) {
                console.log("Developer Mode Active");
            }
        } else {
            session.developerMode = false;
        }

        // Process the session synchronization timeout property.
        if (bcSessionSynchronizationTimeout.status === "fulfilled") {
            session.sessionSynchronizationTimeout = StringUtils.isNumber(bcSessionSynchronizationTimeout.value) ? StringUtils.toNumber(bcSessionSynchronizationTimeout.value) : 0;
        } else {
            session.sessionSynchronizationTimeout = 0;
        }

        // Process the switch to legacy view property.
        if (portalxSwitchToLegacyView.status === "fulfilled") applicationInformation.hideSwitchToLegacyView = !StringUtils.isTruthy(portalxSwitchToLegacyView.value);

        // If there was an error retrieving the above properties (indicating the user is not authorized/authenticated), then check the 'authType' of the session.
        // If it is "external" then we want to trigger the SSO flow automatically in order to perhaps regain a valid session.
        // This happens because the JSESSIONID has an expiry of 30 minutes (the "session-expiry" in Tomcat's "web.xml" file).
        //
        // We only want to perform this check when we are NOT about to process the implicit callback (meaning the user is coming right from the SSO provider).
        // We only want to perform this check on a straight browser refresh or hitting the portal for the first time (say user closed the tab then re-opened it).
        //
        // NOTE: This is ONLY supported when the cookie consent permits the FUNCTIONALITY consent level (we need to remember the user's session details in order to do this).
        //
        if (!window.location.hash.startsWith("#/implicit/callback") && errors.length > 0 && session.autoAttemptExternalAuth == null && session.authType === AuthType.EXTERNAL) {
            session.isAuthenticated = false;

            const providers = applicationInformation.loginConfiguration.availableExternalAuthenticationProviders;

            if (providers.length > 0) {
                console.log("Triggering auto-authentication via external provider...");

                session.autoAttemptExternalAuth = true;
            }

            // Update the current session.
            yield put(SET_SESSION(CloneUtils.clone(session)));
            yield call(saveSession);
        } else {
            if (errors.length > 0) {
                session.isAuthenticated = false;

                delete session.externalAuthenticationProvider;
                delete session.externalAuthType;
                delete session.autoAttemptExternalAuth;
                delete session.autoCompleteCompanySelection;

                // Update the current session.
                yield put(SET_SESSION(CloneUtils.clone(session)));
                yield call(saveSession);

                throw new Error("Unable to fetch necessary system properties");
            } else {
                session.isAuthenticated = true;

                delete session.autoAttemptExternalAuth;

                // Update the current session.
                yield put(SET_SESSION(CloneUtils.clone(session)));
                yield call(saveSession);
            }
        }

        // Update the current application information.
        yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));

        // If we are intending to re-authenticate externally then the Login component "should" automatically redirect us.
        if (session.autoAttemptExternalAuth) {
            console.log("Will attempt to re-authenticate via external provider...", session.externalAuthType);
        } else {
            // Populate the basic state.
            yield call(populateBasicState, { type: "initialization.populateBasicState", payload: { i18n: action.payload.i18n, mode: action.payload.mode } });
        }

        console.log("Done Completing Login!");

        // Google Analytics - Login Event
        if ((window as any).gtag) {
            (window as any).gtag("event", "login", {
                method: session.authType,
            });
        }

        return true;
    } catch (error: any) {
        console.error("completeLogin - ERROR", error);

        // Log out the user.
        yield call(logout, { type: "authentication.logout", payload: true });
    } finally {
        // Get the current application information (the logic above could have modified it).
        applicationInformation = yield select(getApplicationInformation);

        if (action.payload.mode === "login") {
            if (applicationInformation.loadingLoginState) {
                // Update the current application information.
                applicationInformation.loadingLoginState = false;
                yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
            }
        } else {
            if (applicationInformation.loadingBasicState) {
                // Update the current application information.
                applicationInformation.loadingBasicState = false;
                yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
            }
        }
    }

    return false;
}

// Attempts to logout the current user.
export function* logout(action: Logout) {
    // Define some getters for retrieving redux state.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const getThemeConfiguration = (state: PortalState): ThemeConfiguration => CloneUtils.clone(state.themeConfiguration);
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);

    // Get the current application information.
    let applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    // Get the current session.
    let session: Session = yield select(getSession);

    // Get the current theme configuration.
    let themeConfiguration: ThemeConfiguration = yield select(getThemeConfiguration);

    try {
        console.log("Attempting To Logout...");

        if (!applicationInformation.loadingBasicState) {
            // Update the current application information.
            applicationInformation.loadingBasicState = true;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // Attempt to logout using the server-side logout mechanism (we don't really care if it succeeds but it should presumably invalidate our current JSESSIONID).
        const logoutResponse: HttpResponse = yield Http.GET("j_spring_security_logout");
        if (!Http.isStatusOk(logoutResponse)) {
            console.warn("Logout Failed", logoutResponse);
        }

        // Reset the current session.
        resetSession(session);

        // Reset the theme mode back to the default.
        session.themeMode = themeConfiguration.settings?.defaultMode === "dark" ? "dark" : "light";

        // Update the current session.
        yield put(SET_SESSION(CloneUtils.clone(session)));
        yield call(saveSession);

        // Clear the screen settings and available privileges.
        yield all([put(SET_SCREEN_SETTINGS([])), put(SET_AVAILABLE_PRIVILEGES([]))]);

        // Clear the basic state.
        yield call(clearBasicState, { type: "initialization.clearBasicState", payload: { mode: "full" } });

        // Switch back to the default theme.
        yield call(setTheme, { type: "theme.setTheme", payload: { companyId: null } });

        // Delete any non-default (i.e. company/team specific) themes.
        for (let x = 0; x < themeConfiguration.availableThemes.length; x++) {
            if (themeConfiguration.availableThemes[x].companyId != null) {
                yield put(DELETE_THEME(themeConfiguration.availableThemes[x]));
            }
        }

        console.log("Done Logging Out!");

        if (applicationInformation.loadingBasicState) {
            // Indicate that we are no longer loading basic state.
            applicationInformation.loadingBasicState = false;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        window.history.replaceState({}, document.title, "/");

        if (action.payload === true) {
            console.log("### Reloading Due To Logout");

            window.location.reload();
        }

        return true;
    } catch (error: any) {
        console.error("logout - ERROR", error);
    } finally {
        // Get the current application information (the logic above could have modified it).
        applicationInformation = yield select(getApplicationInformation);

        if (applicationInformation.loadingBasicState) {
            // Update the current application information.
            applicationInformation.loadingBasicState = false;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }
    }

    return false;
}

export default function* root() {
    yield all([takeLatest("authentication.completeLogin", completeLogin), takeLatest("authentication.logout", logout)]);
}
