// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import classnames from "classnames";
import { toast } from "react-toastify";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import AppDailyCounts from "types/models/AppDailyCounts";

// Import components.
import { Link, Tooltip } from "@mui/material";
import TeamBanner from "./TeamBanner";
import AppBanner from "./AppBanner";
import PlayerBanner from "./PlayerBanner";

// Import icons.
import PipIcon from "@mui/icons-material/FiberManualRecord";

// Import services.
import Services from "./services";

// Import utilities.
import LocalStorageUtils from "utils/LocalStorage";

interface STATE_PROPS {
    session: Session;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    open: boolean;
    mode: "team" | "app" | "player";
    onToggle: (mode: "team" | "app" | "player", open?: boolean, saveToLocalStorage?: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

interface STATE {
    teamInfo: TeamInfo | null;
    appInfo: AppInfo | null;
    appDailyCounts: AppDailyCounts | null;
    appCurrentUserCount: number | null;
    isReady: boolean;
}

// Map redux state to properties.
const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
    };
};

// Map redux actions/sagas to properties.
const mapDispatchToProps = () => {
    return {};
};

class TeamAndAppInfoBanner extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        teamInfo: null,
        appInfo: null,
        appDailyCounts: null,
        appCurrentUserCount: null,
        isReady: false,
    };
    private _isMounted: boolean = false;

    componentDidMount() {
        const { location, open, mode } = this.props;

        this._isMounted = true;

        this.loadInfo();

        let bannerOpen = open;
        let bannerMode = mode;

        if (!location.pathname.startsWith("/super")) {
            if (LocalStorageUtils.getItem("isTeamAndAppBannerOpen") != null) {
                bannerOpen = LocalStorageUtils.getItem("isTeamAndAppBannerOpen") === "true";
            }
        }

        if (["/team/dashboard", "/app/dashboard"].includes(location.pathname)) {
            bannerOpen = true;
        }

        if (["/team/setup/team-info", "/app/design/cloud-code/scripts"].includes(location.pathname)) {
            bannerOpen = false;
        }

        if (location.pathname.startsWith("/team")) {
            bannerMode = "team";
        }

        if (location.pathname.startsWith("/app")) {
            bannerMode = "app";
        }

        if (location.pathname.startsWith("/app/user")) {
            bannerMode = "player";
        }

        if (bannerMode !== mode || bannerOpen !== open) {
            this.props.onToggle(bannerMode, bannerOpen);
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        const { session, location, availableCompanies, availableApps, open, mode } = this.props;
        const { playerSummary } = session;

        if (
            prevProps.session.companyId !== session.companyId ||
            prevProps.session.companyIdAlias !== session.companyIdAlias ||
            prevProps.session.appId !== session.appId ||
            prevProps.session.playerId !== session.playerId ||
            prevProps.availableCompanies !== availableCompanies ||
            prevProps.availableApps !== availableApps
        ) {
            this.loadInfo();
        }

        let bannerOpen = open;
        let bannerMode = mode;

        if (prevProps.location.pathname !== location.pathname) {
            if (!location.pathname.startsWith("/super")) {
                if (LocalStorageUtils.getItem("isTeamAndAppBannerOpen") != null) {
                    bannerOpen = LocalStorageUtils.getItem("isTeamAndAppBannerOpen") === "true";
                }
            }

            if (["/team/dashboard", "/app/dashboard"].includes(location.pathname)) {
                bannerOpen = true;
            }

            if (["/team/setup/team-info", "/app/design/cloud-code/scripts"].includes(location.pathname)) {
                bannerOpen = false;
            }

            if (!prevProps.location.pathname.startsWith("/team") && location.pathname.startsWith("/team")) {
                bannerMode = "team";
            }

            if (!prevProps.location.pathname.startsWith("/app") && location.pathname.startsWith("/app")) {
                bannerMode = "app";
            }

            if (!prevProps.location.pathname.startsWith("/app/user") && location.pathname.startsWith("/app/user")) {
                bannerMode = "player";
            }

            if (prevProps.location.pathname.startsWith("/app/user") && !location.pathname.startsWith("/app/user") && location.pathname.startsWith("/app")) {
                bannerMode = "app";
            }
        }
        if (location.pathname.startsWith("/app/user") && !playerSummary) {
            bannerMode = "app";
        }

        if (prevProps.session.playerSummary == null && playerSummary != null && location.pathname.startsWith("/app/user")) {
            bannerMode = "player";
        }

        if (bannerOpen !== open || bannerMode !== mode) {
            this.props.onToggle(bannerMode, bannerOpen);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadInfo = async () => {
        const { session } = this.props;

        if (!this._isMounted) return;

        this.setState({ teamInfo: null, appInfo: null, appDailyCounts: null, appCurrentUserCount: null, isReady: false });

        try {
            const companyId = session.isSuper ? session.companyIdAlias : session.companyId;
            const appId = session.appId;

            const promiseBatch = await Promise.allSettled([
                companyId ? Services.getTeam(companyId) : null,
                companyId && appId ? Services.getApps() : null,
                companyId && appId ? Services.getAppAggregateCounts() : null,
                companyId && appId ? Services.getAppDailyCounts() : null,
            ]);

            promiseBatch.forEach((response) => {
                if (response.status === "rejected") {
                    const error = response.reason;
                    toast.error("[" + error.errorCode + "] - " + error.errorMessage);
                }
            });

            const teamInfo = promiseBatch[0].status === "fulfilled" ? promiseBatch[0].value : null;
            const appInfo = promiseBatch[1].status === "fulfilled" ? (promiseBatch[1].value || []).find((item) => item.appId === appId) : null;
            const appAggregateCounts = promiseBatch[2].status === "fulfilled" ? promiseBatch[2].value : null;
            const appDailyCounts = promiseBatch[3].status === "fulfilled" ? promiseBatch[3].value : null;

            if (!this._isMounted) return;

            this.setState(
                {
                    teamInfo: teamInfo ? teamInfo : null,
                    appInfo: appInfo ? appInfo : null,
                    appDailyCounts: appDailyCounts ? appDailyCounts : null,
                    appCurrentUserCount: appAggregateCounts ? appAggregateCounts.currentAccountCount : null,
                    isReady: true,
                },
                this.loadUpToDateUserCount
            );
        } catch (error) {
            if (!this._isMounted) return;

            this.setState({ appDailyCounts: null, appCurrentUserCount: null, isReady: true });
        }
    };

    loadUpToDateUserCount = async () => {
        const { session } = this.props;

        const companyId = session.isSuper ? session.companyIdAlias : session.companyId;
        const appId = session.appId;

        try {
            if (companyId && appId) {
                const appCurrentUserCount = await Services.getAppCurrentUserCount();

                if (appCurrentUserCount != null) {
                    if (!this._isMounted) return;

                    this.setState({ appCurrentUserCount: appCurrentUserCount });
                }
            }
        } catch (error) {
            console.warn("Unable to fetch up-to-date user count");
        }
    };

    render() {
        const { location, classes, open, mode, session } = this.props;
        const { teamInfo, appInfo, appDailyCounts, appCurrentUserCount } = this.state;
        const { playerSummary } = session;

        return (
            <div className={classnames({ [classes.root]: true, [classes.closed]: !open || location.pathname.startsWith("/super") || location.pathname === "/team/setup/team-info" })}>
                <div className={classes.banner}>
                    {mode === "team" && <TeamBanner teamInfo={teamInfo} />}
                    {mode === "app" && <AppBanner appInfo={appInfo} appDailyCounts={appDailyCounts} appCurrentUserCount={appCurrentUserCount} />}
                    {mode === "player" && playerSummary && <PlayerBanner playerSummary={playerSummary} />}
                </div>

                <div className={classes.pips}>
                    <Tooltip arrow title={<Trans>Team Info</Trans>}>
                        <Link data-id={"team"} style={{ fontWeight: mode === "team" ? "bold" : "" }} onClick={() => this.props.onToggle("team", true)}>
                            <Trans>Team</Trans>
                        </Link>
                    </Tooltip>

                    <PipIcon />

                    <Tooltip arrow title={<Trans>App Info</Trans>}>
                        <Link
                            data-id={"app"}
                            style={{ fontWeight: mode === "app" ? "bold" : "", cursor: !location.pathname.startsWith("/app") ? "not-allowed" : "pointer" }}
                            onClick={() => {
                                if (location.pathname.startsWith("/app")) this.props.onToggle("app", true);
                            }}
                        >
                            <Trans>App</Trans>
                        </Link>
                    </Tooltip>

                    <PipIcon />

                    <Tooltip arrow title={<Trans>Player Info</Trans>}>
                        <Link
                            data-id={"player"}
                            style={{ fontWeight: mode === "player" ? "bold" : "", cursor: !location.pathname.startsWith("/app/user") || !playerSummary ? "not-allowed" : "pointer" }}
                            onClick={() => {
                                if (location.pathname.startsWith("/app/user") && playerSummary) this.props.onToggle("player", true);
                            }}
                        >
                            <Trans>User</Trans>
                        </Link>
                    </Tooltip>
                </div>
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            position: "relative",
            overflow: "hidden",

            "& > *": {
                overflowY: "hidden",
                overflowX: "auto",
            },

            maxHeight: "calc(8.75rem + var(--scrollbar-size, 0))",

            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625rem",

            transition: "max-height 0.2s linear, border-bottom-width 0.2s linear",
        },
        banner: {
            margin: "0 .625em",

            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--primary-breadcrumbs-color, inherit)",
            borderColor: "var(--primary-breadcrumbs-border-color, inherit)",

            "& > *": {
                backgroundColor: "var(--secondary-background-color, inherit)",
                color: "var(--secondary-color, inherit)",
                borderColor: "var(--secondary-border-color, inherit)",

                borderRadius: "0.25em",
            },
        },
        pips: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--primary-breadcrumbs-color, inherit)",
            borderColor: "var(--primary-breadcrumbs-border-color, inherit)",

            fontSize: "0.75em",
            padding: "0.3125em",
            gap: "0.3125em",

            "& .MuiTypography-root": {
                color: "inherit",
                textAlign: "center",
                margin: "0.2em",
            },
            "& > .MuiSvgIcon-root": {
                width: "0.25em",
                height: "0.25em",
            },
        },
        closed: {
            maxHeight: 0,
            borderBottomWidth: 0,
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(TeamAndAppInfoBanner)));
