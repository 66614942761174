// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import { WithWidthProps, withWidth } from "framework/width";

// Import components.
import { Divider, Tooltip } from "@mui/material";
import IconButton from "components/common/button/IconButton";
import ResizableDrawer from "components/common/ResizableDrawer";

// Import icons.
import CloseIcon from "@mui/icons-material/CloseOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Import utilities.
import ClipboardUtils from "utils/Clipboard";

interface OWN_PROPS {
    open: boolean;

    direction?: "left" | "right";

    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    copyData?: any;

    resizable?: boolean;
    fullScreen?: boolean;

    persist?: string | null;

    onClose?: () => void;
    onClick?: (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;
}

interface PROPS extends React.PropsWithChildren<OWN_PROPS>, WithStyles<typeof styles>, WithWidthProps {}

class FlyOutPanel extends React.PureComponent<PROPS> {
    onCopyToClipboard = () => {
        const { copyData } = this.props;

        ClipboardUtils.writeText(copyData);
    };

    onClickAway = (event: MouseEvent | TouchEvent) => {
        console.log("Flyout Panel - Click Away");
    };

    render() {
        const { classes, open, direction = "left", title, subTitle, copyData, resizable = true, fullScreen = false, persist, children } = this.props;

        return (
            <ResizableDrawer
                anchor={direction === "left" ? "right" : "left"}
                open={open}
                minSize={
                    fullScreen
                        ? "100%"
                        : {
                              xs: "100%",
                              sm: 300,
                              md: 420,
                              lg: 540,
                              xl: 540,
                          }
                }
                disableResize={fullScreen || !resizable}
                onClick={this.props.onClick}
                onClickAway={this.onClickAway}
                persist={persist !== undefined ? (persist === null ? undefined : persist) : "flyout"}
            >
                <div className={classes.header}>
                    <div className={classes.titleWrapper}>
                        <span className={classes.title}>{title}</span>

                        {subTitle && <span className={classes.subTitle}>{subTitle}</span>}
                    </div>

                    <span className={classes.buttonWrapper}>
                        {copyData && (
                            <Tooltip arrow title={<Trans>Copy All Data</Trans>}>
                                <IconButton id={"copy-to-clipboard"} onClick={this.onCopyToClipboard}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        )}

                        {this.props.onClose && (
                            <IconButton id={"dismiss"} type={"neutral"} onClick={this.props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        )}
                    </span>
                </div>

                <Divider />

                <div className={classes.content}>{open && children}</div>
            </ResizableDrawer>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            overflow: "hidden",

            position: "relative",
        },

        header: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",

            paddingTop: "0.3125em",
            paddingBottom: "0.3125em",
            paddingLeft: "0.625em",
            paddingRight: "0.3125em",
        },

        titleWrapper: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            overflow: "hidden",
        },

        title: {
            "& .MuiTypography-root": {
                fontSize: "1.25em",
                fontWeight: "bold",

                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            },
        },

        subTitle: {
            fontSize: "0.875em",
            fontStyle: "italic",
        },

        buttonWrapper: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",

            overflow: "hidden",
        },

        content: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",

            "& > *": {
                margin: "0.3125em",
            },
        },
    });

export default withWidth()(withStyles(styles)(FlyOutPanel));
