// Import libraries.
import React from "react";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { withPermission, WithPermissionProps } from "framework/withPermission";
import { Trans } from "@lingui/macro";

// Import types.
import AppDailyCounts from "types/models/AppDailyCounts";

// Import components.
import { Typography } from "@mui/material";

// Import formatters.
import NumberFormatter from "utils/formatters/Number";

interface PROPS extends WithStyles<typeof styles>, WithPermissionProps {
    appDailyCounts: AppDailyCounts | null;
    appCurrentUserCount: number | null;
}

class MetricsInfoSection extends React.PureComponent<PROPS> {
    render() {
        const { classes, bcHasReadAccess, appDailyCounts, appCurrentUserCount } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    {bcHasReadAccess && (
                        <span className={classes.nameValuePair}>
                            <Typography noWrap>{<Trans>Revenue Today:</Trans>}</Typography>

                            <Typography style={{ marginLeft: "0.3125em", fontWeight: "bold" }} noWrap>
                                {appDailyCounts ? NumberFormatter.formatCurrency(appDailyCounts.revenue.count / 100) : ""}
                            </Typography>
                        </span>
                    )}

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Total Users:</Trans>}</Typography>

                        <Typography style={{ marginLeft: "0.3125em", fontWeight: "bold" }} noWrap>
                            {appCurrentUserCount ? NumberFormatter.formatInteger(appCurrentUserCount) : "---"}
                        </Typography>
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Users Today:</Trans>}</Typography>

                        <Typography style={{ marginLeft: "0.3125em", fontWeight: "bold" }} noWrap>
                            {appDailyCounts ? NumberFormatter.formatInteger(appDailyCounts.users.count) : "---"}
                        </Typography>
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>API Calls Today:</Trans>}</Typography>

                        <Typography style={{ marginLeft: "0.3125em", fontWeight: "bold" }} noWrap>
                            {appDailyCounts ? NumberFormatter.formatInteger(appDailyCounts.apiCalls.count) : "---"}
                        </Typography>
                    </span>
                </div>
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",

            padding: "0.3125em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
    });

export default withPermission("REPORT_DASH_ANALYTICS")(withStyles(styles)(MetricsInfoSection));
