// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

// Import utils.
import Typography from "@mui/material/Typography";

interface OWN_PROPS {
    playerSummary: any;
    style?: CSSProperties;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class PlaceHolderImage extends React.PureComponent<PROPS> {
    calculateChecksum = (id?: string) => {
        let checksum = 0;
        if (id)
            // Iterate through each character in the profileID
            for (let i = 0; i < id.length; i++) {
                // Add the ASCII value of the character to the checksum
                checksum += id.charCodeAt(i);
            }

        return checksum;
    };

    getInitials = () => {
        const { playerSummary } = this.props;

        let initials = "";

        if (playerSummary?.name) {
            const names = playerSummary.name.split(" ");

            if (names.length > 0) initials += names[0].charAt(0).toUpperCase();

            if (names.length > 1) {
                initials += names[names.length - 1].charAt(0).toUpperCase();
            }
        } else if (playerSummary?.fullName) {
            const names = playerSummary.fullName.split(" ");

            if (names.length > 0) initials += names[0].charAt(0).toUpperCase();

            if (names.length > 1) {
                initials += names[names.length - 1].charAt(0).toUpperCase();
            }
        } else if (playerSummary?.email) {
            const [username, domain] = playerSummary.email.split("@");
            if (username) {
                initials += username.charAt(0).toUpperCase();
            }
            if (domain) {
                initials += domain.charAt(0).toUpperCase();
            }
        } else if (playerSummary?.playerId) {
            initials = playerSummary.playerId.slice(0, 2).toUpperCase();
        }

        return initials;
    };

    render() {
        const { playerSummary, classes, style } = this.props;

        // Calculate the checksum
        const checksum = this.calculateChecksum(playerSummary?.playerId || playerSummary?.profileId);

        // Calculate MODULUS(21) of the checksum
        const modulus21 = checksum % 21;

        const initials = this.getInitials();

        return (
            <span className={classes.background} style={{ backgroundColor: `var(--chart-dataset-color-${modulus21}, inherit)`, height: style?.height, width: style?.width }}>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: style?.fontSize ? style.fontSize : initials.length === 1 ? "4em" : "3em",
                        color: `var(--chart-dataset-font-color-${modulus21}, inherit)`,
                    }}
                >
                    {initials}
                </Typography>
            </span>
        );
    }
}

const styles = () =>
    createStyles({
        background: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            borderRadius: "50%",
            overflow: "hidden",
        },
    });

export default withStyles(styles)(PlaceHolderImage);
