// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import classnames from "classnames";
import PortalComponent from "framework/PortalComponent";

// Import types.
import { ActionDefinition, BulkActionDefinition, ColumnDefinition, FilterData, FilterDefinition, PublicTableRefInterface, RowData, TableData } from "./types";

// Import components.
import { Typography, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import CustomMenu from "components/common/menu";
import LoadingProgress from "components/common/widgets/LoadingProgress";
import Toolbar from "./components/Toolbar";
import Header from "./components/Header";
import Row from "./components/Row";
import RowDetails from "./components/RowDetails";
import Paginator from "./components/Paginator";

// Import utilities.
import {
    defaultCellComparator,
    defaultRowFilter,
    DEFAULT_BOTTOM_BORDER_SIZE,
    DEFAULT_OVERFLOW_COUNT,
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE_SIZE_OPTIONS,
    determineBottomBufferHeight,
    determineFirstVisibleRow,
    determineLastVisibleRow,
    determinePageHeight,
    determineTopBufferHeight,
} from "./utils";
import CloneUtils from "utils/Clone";

// Import the MeasurerCache.
import MeasurerCache from "./MeasurerCache";
import User from "types/common/User";
import PortalState from "types/store";
import { connect } from "react-redux";

interface STATE_PROPS {
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    id: string; // The value of the "id" attribute for the root element (can be used by automated test frameworks to lookup the element).

    ref?: React.RefObject<PublicTableRefInterface>; // Since we accept a ref, we declare it here (makes the TypeScript linter happy).

    primaryKey: string; // The field of the row that represents a unique identifier,

    columns: ColumnDefinition[]; // The collection of column definitions.

    filters?: FilterDefinition[]; // The collection of optional filter definitions.

    bulkActions?: BulkActionDefinition[]; // The collection of optional "bulk" action definitions (applied against selected rows).

    actions?: ActionDefinition[] | ((row: RowData) => React.ReactNode); // The collection of optional "single" action definitions (applies to single row, represented by the actions column).

    defaultOrderBy?: string | null; // The default/initial orderBy.
    defaultOrderDirection?: "asc" | "desc" | null; // The default/initial orderDirection.

    customBanner?: React.ReactNode; // An optional custom banner that is rendered just below the toolbar. Can be used to show additional information.

    onRowClicked?: (row: RowData) => void; // Callback function called when a single row is clicked.

    selectMode?: "single" | "multi"; // The selection mode behaviour ("single" or "multi" mode, applies to the current page. Changing the page clears the selections).
    selectedRows?: RowData[]; // The collection of currently selected rows (when the parent component wants to maintain it).
    onSelectedRowsChanged?: (rows: RowData[]) => void; // Callback function called when the row selections have changed.

    swapSelectAndActionColumns?: boolean; // Determines whether the "select" and "actions" columns are swapped (such that actions is on the left and the select is on the right).

    hideColumnHeaders?: boolean; // Indicates whether we should hide the column headers (not especially useful unless explicitly desired for specific use cases).
    hidePaginator?: boolean; // Indicates whether we should hide the paginator (prevents the user from changing page, again only really usefull when you explicitly want to display ALL rows).

    striped?: boolean; // Indicates whether we should apply striping to the rows (alternating background/font color for odd/even rows).

    minHeight?: string | number; // Minimum height of the table wrapper. The root container element is "flex: 1 1 auto" which can be constrained by the parent container.
    maxHeight?: string | number; // Maximum height of the table wrapper. Defaults to 100% of the root container element.

    fontSize?: string | number; // Custom font size for the table wrapper (applies to rows and cells and defaults is 0.875em).

    // The source of the records data (can either be a TableData instance or an asynchronous function that returns a TableData instance).
    dataProvider: TableData | ((page: number, pageSize: number, orderBy: string | null, orderDirection: "asc" | "desc", filters?: FilterData, force?: boolean) => Promise<TableData>);

    // Optional total count that can be supplied that will override ANY total returned from the data provider.
    // Typically used where the total count is detemined independently of the actual call to fetch the records.
    deferredTotalRecordCount?: number;

    defaultPageSize?: number; // The default page size for the table when initially mounted.

    virtualized?: boolean; // Indicates whether we should be "virtualizing" the rendered rows.
    overflowCount?: number; // When "virtualied", indicates the number of overlfow/off-screen rows to render (ABOVE and BELOW the visible/on-screen rows).

    // Additional optional props (primarly used for applying classnames/styling to different things).
    // These would override/replace the standard classnames/styles that are applied for their specific situations.
    rowClassName?: string; // Custom classname applied to each row in the table body.
    selectedRowClassName?: string; // Custom classname applied to each row in the table body that is currently "selected".
    actionColumnWidth?: string | number; // Custom fixed width for the actions column (default is to be dynamically sized based on the content).
    getColumnClassName?: (definition: ColumnDefinition, row: RowData) => string | undefined; // Callback function that is called to get a custom classname to apply to a particular column.

    // EXPERIMENTAL FEATURES BELOW:

    // If supplied then each row (when clicked) will show an expandable "details" row right below it (for showing additional details related to the record).
    rowDetailsExpandable?: ((row: RowData) => boolean) | null;
    rowDetailsExpanded?: ((row: RowData) => boolean) | null;
    renderRowDetails?: ((row: RowData) => React.ReactNode) | null;
    rowDetailsMinHeight?: string | number;
    rowDetailsMaxHeight?: string | number;

    customJsonFilter?: {
        key?: string;

        query?: {
            recommendedKeys: string[];
            sample?: string;
            desc?: React.ReactNode;
        };

        sort?: {
            recommendedKeys: string[];
            sample?: string;
            desc?: React.ReactNode;
        };

        hint?: {
            recommendedKeys: string[];
            sample?: string;
            desc?: React.ReactNode;
        };
    };
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles> {}

const mapStateToProps = (state: PortalState) => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

interface STATE {
    page: number; // The currently viewed page number (zero-based index).
    pageSize: number; // The currently selected 'rowsPerPage'.
    defaultPageSize: number; // The default page size for the table when initially mounted.
    orderBy: string | null; // The currently sorted column id.
    orderDirection: "asc" | "desc"; // The currently sorted direction.
    selectedRows: RowData[]; // The currently selected rows.
    tableData: TableData | null; // the currently loaded data.
    filterData: FilterData; // The currently supplied filter data.
    actionsAnchorElement: (EventTarget & Element) | null; // The currently selected anchor element for the actions menu.
    actionsAnchorRow: RowData | null; // The currently selected anchor row data for the actions menu.
    currentFilterTimeout: number; // The currently defined timeout resulting from a filter change.
    headerHeight: number; // The currently cached header height (for dynamic height support).
    stickyWidth: number; // The currently cached sticky column width, includes BOTH the LEFT and RIGHT sticky column widths (for "details" row support).
    rowHeights: number[]; // The currently cached row heights (for dynamic height support).
    expandedRow: RowData | null; // The optionally expanded row (shows an extra "details" row when set).
    headerCellWidths: string[]; // The currently cached header cell Widths (to support column resize feature).
}

class CustomTable extends PortalComponent<PROPS, STATE> implements PublicTableRefInterface {
    state: Readonly<STATE> = {
        page: 0, // Initial page (0-based).
        pageSize: DEFAULT_PAGE_SIZE, // Initial pageSize
        defaultPageSize: DEFAULT_PAGE_SIZE, // Initial defaultPageSize
        orderBy: this.props.defaultOrderBy || null, // Initial orderBy.
        orderDirection: this.props.defaultOrderDirection || "asc", // Initial orderDirection.
        selectedRows: [], // Initial selected rows.
        tableData: null, // Initial data (represents the currently loaded row data).
        filterData: {}, // Initial filter data.
        actionsAnchorElement: null, // Initial actions menu anchor element.
        actionsAnchorRow: null, // Initial actions menu anchor row data.
        currentFilterTimeout: 0, // Initial filter timeout.
        headerHeight: 0, // Initial header height (for dynamic height support).
        stickyWidth: 0, // Initial sticky width, includes BOTH the LEFT and RIGHT sticky column widths (for "details" row support).
        rowHeights: [], // Initial row heights (for dynamic height support).
        expandedRow: null, // Initial expanded row (shows an extra "details" row when set).
        headerCellWidths: [], // Initial header cell Widths (to support column resize feature).
    };
    private tableContainerRef = React.createRef<HTMLDivElement>();
    private measurerCache: MeasurerCache | null = null;

    constructor(props: PROPS) {
        super(props);

        const { defaultPageSize, filters } = props;

        const initialState = CloneUtils.clone(this.state) as STATE;

        // Determine the initial page size.
        if (defaultPageSize === -1) {
            // A default page size of -1 basically means show all rows. (we cap out at 10000 just in case).
            initialState.pageSize = 10000;
            initialState.defaultPageSize = 10000;
        } else {
            // If the current user has a prefered page size then use that.
            if (props.currentUser?.customData?.pageSize != null && DEFAULT_PAGE_SIZE_OPTIONS.includes(props.currentUser?.customData?.pageSize)) {
                initialState.pageSize = props.currentUser?.customData?.pageSize;
                initialState.defaultPageSize = props.currentUser?.customData?.pageSize;
            } else {
                // Otherwise if a default page size has been supplied to the table then use that.
                if (defaultPageSize != null && DEFAULT_PAGE_SIZE_OPTIONS.includes(defaultPageSize)) {
                    initialState.pageSize = defaultPageSize;
                    initialState.defaultPageSize = defaultPageSize;
                }
            }
        }

        // If filters are defined, initialize the filter data.
        if (filters && filters.length > 0) {
            for (let x = 0, n = filters.length; x < n; ++x) {
                const filter = filters[x];

                if (filter.initialValue != null) {
                    initialState.filterData[filter.id] = filter.initialValue;
                }
            }
        }

        // Instantiate a MeasurerCache (we make use of it in all table rendering scenarios... it's quite performant, so no concerns there).
        this.measurerCache = new MeasurerCache((headerHeight: number, stickyWidth: number, rowHeights: number[], headerCellWidths: string[]) => {
            this.setState({ headerHeight: headerHeight, stickyWidth: stickyWidth, rowHeights: rowHeights, headerCellWidths: headerCellWidths });
        });

        // Set the initial state.
        this.state = initialState;
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        this.loadCurrentPage();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        window.removeEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps: PROPS): void {
        if (this.state.tableData != null && this.props.deferredTotalRecordCount != null && this.state.tableData.total !== this.props.deferredTotalRecordCount && !Number.isNaN(this.props.deferredTotalRecordCount)) {
            this.setState({ tableData: { rows: this.state.tableData.rows, total: this.props.deferredTotalRecordCount } });
        }

        if (prevProps.currentUser?.customData?.pageSize !== this.props.currentUser?.customData?.pageSize) {
            if (this.props.defaultPageSize !== -1 && this.props.currentUser?.customData?.pageSize != null && DEFAULT_PAGE_SIZE_OPTIONS.includes(this.props.currentUser?.customData?.pageSize)) {
                this.setState({ pageSize: this.props.currentUser?.customData?.pageSize, defaultPageSize: this.props.currentUser?.customData?.pageSize, page: 0 }, () => {
                    this.clearRowSelections();
                    this.loadCurrentPage();
                });
            }
        }
    }

    handleResize = () => {
        // Added force render to calucate tableContainerRef.current.clientWidth curretly, when table have RowDetails.
        if (this.props.renderRowDetails != null) {
            this.forceUpdate();
        }
    };

    /**
     * Load the current page of table rows.
     *
     * If the force parameter is supplied, then we first revert back to page 0 (the first page).
     *
     * @param force
     */
    loadCurrentPage = async (force?: boolean) => {
        const { dataProvider } = this.props;
        const { page, pageSize, orderBy, orderDirection, filterData, tableData } = this.state;

        console.debug("Loading Current Page", filterData);

        // Whenever we load the current page, reset the measurer cache (if present).
        // This is required when we change the page, page size or the filtering/sorting as it will most likely result in all of the cached values being invalidated.
        if (this.measurerCache) {
            this.measurerCache.resetAll();
        }

        // If the force parameter was supplied, we revert to page 0 under all circumstances.
        // Otherwise we attempt to stay on the current page.
        let newCurrentPage = force ? 0 : page;

        // Fetch the new table table data.
        let newTableData: TableData | null = null;
        if (dataProvider) {
            if (typeof dataProvider === "function") {
                this.setState({ tableData: null });

                try {
                    newTableData = CloneUtils.cloneObject(await dataProvider(newCurrentPage, pageSize, orderBy, orderDirection, filterData, force)) as TableData;
                } catch (error: any) {
                    if (error.name === "AbortError") {
                        console.debug("Loading Current Page Cancelled");

                        this.setState({ tableData: null });

                        return;
                    } else {
                        console.error("Failed to load current page for table", error);

                        toast(
                            <span style={{ display: "flex", flexDirection: "column" }}>
                                <Typography>
                                    <Trans>Failed to load current page for table:</Trans>
                                </Typography>

                                <Typography style={{ marginLeft: "0.3125em" }}>{"[" + error.errorCode + "] - " + error.errorMessage}</Typography>
                            </span>
                        );
                    }

                    newTableData = {
                        rows: [],
                        total: tableData ? tableData.total : null,
                    };
                }
            } else {
                newTableData = CloneUtils.cloneObject(dataProvider) as TableData;

                // Apply sorting (if applicable).
                if (orderBy) newTableData.rows.sort(defaultCellComparator(orderBy, orderDirection));

                // Apply filtering (if applicable).
                newTableData.rows = newTableData.rows.filter(defaultRowFilter(filterData));

                // Determine the new total based on the filtered results.
                newTableData.total = newTableData.rows.length;
            }
        }

        if (newTableData) {
            if (newTableData.total == null) {
                newTableData.total = Number.POSITIVE_INFINITY;
            }

            if (this.pageSafetyCheck(newCurrentPage, pageSize, newTableData.total)) {
                // If the new current page is valid we just update the state and scroll to the top of the current page.
                this.setState({ page: newCurrentPage, tableData: newTableData }, () => {
                    if (this.tableContainerRef.current) this.tableContainerRef.current.scrollTop = 0;
                });
            }
        } else {
            this.setState({ page: 0, tableData: { rows: [], total: 0 } });
        }
    };

    pageSafetyCheck = (page: number, pageSize: number, totalRecordCount: number) => {
        if (page > 0 && page * pageSize > totalRecordCount) {
            // If the page is out of range, reset the page back to 0 (the first page) and reload.
            this.setState({ page: 0 }, () => {
                this.loadCurrentPage();
            });

            return false;
        } else {
            return true;
        }
    };

    /**
     * Sets the filter value for the indicated named filter.
     * This allows the parent component to manually modify a filter value (similar to how the parent can trigger a refresh).
     */
    setFilterValue = (name: string, value: any) => {
        this.changeFilter(name, value);
    };

    /**
     * Opens the actions menu.
     */
    openActionsMenu = (event: React.SyntheticEvent<HTMLElement>, row: RowData) => {
        this.setState({ actionsAnchorElement: event.currentTarget, actionsAnchorRow: row });
    };

    /**
     * Closes the actions menu.
     */
    closeActionsMenu = () => {
        this.setState({ actionsAnchorElement: null, actionsAnchorRow: null });
    };

    /**
     * Determines if the supplied row (or collection of rows) is currently selected.
     */
    isSelected = (data: RowData | RowData[]) => {
        const { primaryKey } = this.props;

        const selectedRows = this.props.selectedRows ? this.props.selectedRows : this.state.selectedRows;

        if (Array.isArray(data)) {
            let rowsMissing = false;

            for (let x = 0, n = data.length; x < n; ++x) {
                if (!selectedRows.find((item) => item[primaryKey] === data[x][primaryKey])) {
                    rowsMissing = true;

                    break;
                }
            }

            return !rowsMissing;
        } else {
            if (selectedRows && selectedRows.find((item) => item[primaryKey] === data[primaryKey])) {
                return true;
            }

            return false;
        }
    };

    /**
     * Toggles the selected state of all rows.
     */
    toggleAllRowsSelected = () => {
        const { dataProvider, selectMode } = this.props;
        const { tableData, page, pageSize } = this.state;

        if (selectMode === "multi" && tableData) {
            let newSelectedRows: RowData[] = [];

            if (typeof dataProvider !== "function") {
                const currnetPageRows = tableData.rows.slice(page * pageSize, page * pageSize + pageSize);

                if (!this.isSelected(currnetPageRows)) {
                    newSelectedRows = currnetPageRows;
                }
            } else {
                if (!this.isSelected(tableData.rows)) {
                    newSelectedRows = [...tableData.rows];
                }
            }

            if (this.props.selectedRows) {
                if (this.props.onSelectedRowsChanged) {
                    this.props.onSelectedRowsChanged(newSelectedRows);
                }
            } else {
                this.setState({ selectedRows: newSelectedRows }, () => {
                    if (this.props.onSelectedRowsChanged) {
                        this.props.onSelectedRowsChanged(this.state.selectedRows);
                    }
                });
            }
        }
    };

    /**
     * Toggles the selected state of a specific row.
     */
    toggleRowSelection = (row: RowData) => {
        const { primaryKey, selectMode } = this.props;

        const selectedRows = this.props.selectedRows ? this.props.selectedRows : this.state.selectedRows;

        if (selectMode) {
            let newSelectedRows: RowData[] = [];

            if (["multi"].includes(selectMode)) {
                if (selectedRows.find((item) => item[primaryKey] === row[primaryKey])) {
                    newSelectedRows = selectedRows.filter((item) => item[primaryKey] !== row[primaryKey]);
                } else {
                    newSelectedRows = [...selectedRows, row];
                }
            } else {
                if (selectedRows.find((item) => item[primaryKey] === row[primaryKey])) {
                    newSelectedRows = [];
                } else {
                    newSelectedRows = [row];
                }
            }

            if (this.props.selectedRows) {
                if (this.props.onSelectedRowsChanged) {
                    this.props.onSelectedRowsChanged(newSelectedRows);
                }
            } else {
                this.setState({ selectedRows: newSelectedRows }, () => {
                    if (this.props.onSelectedRowsChanged) {
                        this.props.onSelectedRowsChanged(this.state.selectedRows);
                    }
                });
            }
        }
    };

    /**
     * Clears the selected state of all rows.
     */
    clearRowSelections = () => {
        if (this.props.selectedRows) {
            if (this.props.onSelectedRowsChanged) {
                this.props.onSelectedRowsChanged([]);
            }
        } else {
            this.setState({ selectedRows: [] }, () => {
                if (this.props.onSelectedRowsChanged) {
                    this.props.onSelectedRowsChanged(this.state.selectedRows);
                }
            });
        }
    };

    /**
     * Handles the clicking of a specific row.
     */
    clickRow = (row: RowData, rowIdx: number, colIdx: number) => {
        if (this.props.onRowClicked) {
            this.props.onRowClicked(row);
        }

        // If "rowDetailsExpandable" is true and "renderRowDetails" property is present, then proceed with toggling the "details" row.
        if (this.props.rowDetailsExpandable && this.props.rowDetailsExpandable(row) && this.props.renderRowDetails) {
            this.toggleRowDetails(row, rowIdx, colIdx);
        }
    };

    /**
     * Handles the toggling of the "details" row.
     */
    toggleRowDetails = (row: RowData, rowIdx: number, _colIdx: number) => {
        const { rowHeights, expandedRow } = this.state;

        const scrollTop = this.tableContainerRef.current?.scrollTop || 0;

        if (this.measurerCache) {
            this.measurerCache.setCellValue(rowIdx + 1, 0, DEFAULT_BOTTOM_BORDER_SIZE);

            // Normally, we wouldn't set a value on a state object directly (we typically would clone the state field), but this is a special case to address scroll jumping when toggling the "details".
            rowHeights[rowIdx + 1] = DEFAULT_BOTTOM_BORDER_SIZE;
        }

        this.setState({ expandedRow: row !== expandedRow ? row : null });

        // Set a timeout to reset the scroll position of the table after toggling the "details" row.
        window.setTimeout(() => {
            if (this.tableContainerRef.current) {
                this.tableContainerRef.current.scrollTop = scrollTop;
            }
        }, 0);
    };

    /**
     * Handles the changing of the page.
     */
    changePage = (newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.clearRowSelections();
            this.loadCurrentPage();
        });
    };

    /**
     * Handles the changing of the rowsPerPage.
     */
    changeRowsPerPage = (pageSize: number) => {
        this.setState({ pageSize: pageSize, page: 0 }, () => {
            this.clearRowSelections();
            this.loadCurrentPage();
        });
    };

    /**
     * Handles the changing of the orderBy/orderDirection.
     */
    changeSort = (newOrderBy: string, direction: "asc" | "desc") => {
        this.setState({ orderBy: newOrderBy, orderDirection: direction }, () => {
            this.loadCurrentPage();
        });
    };

    /**
     * Handles the changing of a filter.
     */
    changeFilter = (name: string, value: any) => {
        const { filters, customJsonFilter } = this.props;
        const { filterData, currentFilterTimeout } = this.state;

        const isCustomJsonFilterChange = customJsonFilter && name === (customJsonFilter.key || "customJson");

        const filter = isCustomJsonFilterChange ? ({ id: customJsonFilter.key || "customJson" } as FilterDefinition) : filters != null ? filters.find((filter) => filter.id === name) : null;

        if (filter) {
            const updatedFilterData = CloneUtils.clone(filterData) as FilterData;

            // Update the targeted filter data (removing it if the new value is undefined).
            if (value === undefined) {
                delete updatedFilterData[filter.id];
            } else {
                updatedFilterData[filter.id] = value;
            }

            // If any filter other than the "custom query" filter, then clear the "custom "query" filter.
            if (customJsonFilter && !isCustomJsonFilterChange) {
                delete updatedFilterData[customJsonFilter.key || "customJson"];
            }

            if (filter.bypass) {
                // If the filter's bypass parameter is TRUE, then we do NOT reload the current page.
                // Filter's with the bypass defined as TRUE are intended to be handled by the parent component
                // without triggering a reload of the current page.
                //
                // For example, the parent component could change the set of column definitions being displayed
                // or the set of filters/actions present which does not technically require a reload of the current
                // page (or some other custom behaviour).
                this.setState({ filterData: updatedFilterData }, () => {
                    if (filter.onChange != null) {
                        filter.onChange(this.state.filterData[filter.id]);
                    }
                });
            } else {
                // Otherwise the filter is meant to trigger a reload of the current page.

                // First we clear the current filter timout (if applicable).
                window.clearTimeout(currentFilterTimeout);

                // Define a new filter timout (used to trigger the reload of the current page).
                let newFilterTimeout = 0;

                if (filter.filterTimeout != null && filter.filterTimeout > 0) {
                    // If the filter being updated has an assoicated filterTimeout, set up an appropriate timeout.
                    newFilterTimeout = window.setTimeout(() => {
                        this.loadCurrentPage(isCustomJsonFilterChange || filter.force);
                    }, filter.filterTimeout);
                } else {
                    // Otherwise, set up an immediate timeout.
                    newFilterTimeout = window.setTimeout(() => {
                        this.loadCurrentPage(isCustomJsonFilterChange || filter.force);
                    }, 0);
                }

                this.setState({ filterData: updatedFilterData, currentFilterTimeout: newFilterTimeout }, () => {
                    if (filter.onChange != null) {
                        filter.onChange(this.state.filterData[filter.id]);
                    }
                });
            }
        }
    };

    /**
     * Handles the changing of a cell's backing value (if implemented/supported by the parent component).
     */
    changeCellValue = (id: string, newValue: any, row: RowData) => {
        const { primaryKey, columns } = this.props;
        const { tableData } = this.state;

        const targetColumn = columns.find((column) => column.id === id);

        if (targetColumn && tableData) {
            const updatedTableData = CloneUtils.clone(tableData) as TableData;

            const targetRow = updatedTableData.rows.find((item) => item[primaryKey] === row[primaryKey]);

            if (targetRow) {
                targetRow[id] = newValue;

                this.setState({ tableData: updatedTableData });
            }
        }
    };

    /**
     * Renders a single TableRow.
     */
    renderTableRow(row: RowData, index: number) {
        const { classes, columns, primaryKey, selectMode, striped, actions, swapSelectAndActionColumns, rowClassName, selectedRowClassName } = this.props;
        const { rowHeights, actionsAnchorRow } = this.state;

        if (row[primaryKey] == null) {
            console.warn("Row is missing a primary key value", row);
        }

        const detailsEnabled = this.props.rowDetailsExpandable && this.props.rowDetailsExpandable(row) && this.props.renderRowDetails != null;

        const cachedRowHeight = rowHeights[index] || 0;

        const isSelected = this.isSelected(row);

        return (
            <Row
                key={row[primaryKey]}
                id={row[primaryKey]}
                rowIdx={index}
                className={classnames(
                    classes.row,
                    detailsEnabled ? classes.rowWithoutBorder : null,
                    striped ? classes.striped : null,
                    isSelected ? classnames(classes.selected, "selected", selectedRowClassName) : null,
                    rowClassName,
                    actionsAnchorRow && actionsAnchorRow[primaryKey] === row[primaryKey] ? classes.rowHover : null
                )}
                height={cachedRowHeight > 0 ? cachedRowHeight : "auto"}
                definitions={columns}
                row={row}
                primaryKey={primaryKey}
                selectMode={selectMode}
                selected={this.isSelected(row)}
                actions={actions}
                swapSelectAndActionColumns={swapSelectAndActionColumns}
                onClick={this.props.onRowClicked || detailsEnabled ? this.clickRow : undefined}
                onChange={this.changeCellValue}
                onToggleSelection={this.toggleRowSelection}
                onOpenActionMenu={this.openActionsMenu}
                getColumnClassName={this.props.getColumnClassName}
                onCellHeightCalculated={this.measurerCache ? this.measurerCache.setCellValue : null}
            />
        );
    }

    /**
     * Renders the optional "details" TableRow.
     */
    renderTableRowDetails(row: RowData, index: number) {
        const { classes, columns, primaryKey, selectMode, actions, swapSelectAndActionColumns, rowDetailsMinHeight, rowDetailsMaxHeight } = this.props;
        const { stickyWidth, rowHeights, expandedRow } = this.state;

        const detailsEnabled = this.props.renderRowDetails != null;

        if (!detailsEnabled) return null;

        const cachedRowHeight = rowHeights[index] || 0;

        const detailsWidth = this.tableContainerRef.current ? this.tableContainerRef.current.clientWidth - stickyWidth : 0;

        return (
            <RowDetails
                key={row[primaryKey] + "-details"}
                id={row[primaryKey] + "-details"}
                rowIdx={index}
                className={classnames(classes.row)}
                minHeight={rowDetailsMinHeight}
                height={cachedRowHeight > 0 ? cachedRowHeight : "auto"}
                maxHeight={rowDetailsMaxHeight}
                contentWidth={detailsWidth}
                scrollLeft={this.tableContainerRef.current?.scrollLeft || 0}
                definitions={columns}
                row={row}
                primaryKey={primaryKey}
                selectMode={selectMode}
                actions={actions}
                swapSelectAndActionColumns={swapSelectAndActionColumns}
                onCellHeightCalculated={this.measurerCache ? this.measurerCache.setCellValue : null}
                renderContent={this.props.renderRowDetails}
                open={(this.props.rowDetailsExpanded && this.props.rowDetailsExpanded(row)) || (expandedRow != null && row[primaryKey] === expandedRow[primaryKey])}
            />
        );
    }

    /**
     * Renders a TableBody for the supplied page of rows.
     *
     * Optionally accepts a 'virtualized' parameter that enables virtualization of the rows.
     */
    renderTableBody(currentPage: TableData | null, virtualized?: boolean | null) {
        const { id, overflowCount, hideColumnHeaders } = this.props;
        const { headerHeight, rowHeights, expandedRow } = this.state;

        const rows: React.ReactNode[] = [];

        // If there is no current page data (or it contains no rows), then just return an empty TableBody.
        if (!currentPage || currentPage.rows.length === 0) {
            return <TableBody id={id + "-table-body"} style={{ flex: "1 1 auto", overflow: "auto" }}></TableBody>;
        }

        console.debug("Constructing Table Body...");

        // Determine if the expandable "details" support is enabled.
        const detailsEnabled = this.props.renderRowDetails != null;

        // Determine if a "details" row is actually visible/expanded.
        const detailsExpanded = detailsEnabled && expandedRow;
        if (detailsExpanded) {
            console.debug("Details row is visible...");
        }

        // If the "details" support is enabled, then create a "shallow" copy of the "rows" for the currentPage.
        // Otherwise just use the supplied array as-is since we don't need to modify it if the "details" support is disabled.
        // We need this just in case we need to add the "details" row (if applicable) to the array (without modifying the original).
        const currentRows: RowData[] = !detailsEnabled ? currentPage.rows : [];
        if (detailsEnabled) {
            for (let x = 0, n = currentPage.rows.length; x < n; ++x) {
                const row = currentPage.rows[x];

                currentRows.push(row);

                // If the "details" support is enabled, then duplicate the row data and add it again.
                if (detailsEnabled) {
                    const detailsRow = CloneUtils.clone(row) as RowData;

                    // Set a "special" variable on the "details" row data (helps indicate that the row data is specifically for the "details" row).
                    // This could be useful if we need to differentiate between them.
                    detailsRow.__details_row__ = true;

                    currentRows.push(detailsRow);
                }
            }
        }

        // Determine the total number of rows on the current page
        const numberOfRows = currentRows.length;

        // Conditionally render a virtualized OR non-virutalized TableBody (based on the value of the 'virtualized' property).
        if (virtualized) {
            // Basic virtualization support without using "react-window".
            // We do it this way since we are using sticky columns (for horizontal scrolling) and that doesn't seem to play nice with "react-window".
            // Hence this virtualization technique only deals with vertical scrolling (i.e. virtualizing the rows and NOT the columns).
            // That being said, this method of virutalization seems to work quite well (and could potentially be applied elsewhere in place of "react-window").
            // Think of it as a poor-mans virtualization.

            // First, determine the default row height (as a number).
            let rowHeightAsNumber = 0;

            // Determine the height of the table container (by using the associated ref).
            // We use the clientHeight (instead of the offsetHeight) so the horizontal scrollbar (if present) is not included.
            // Otherwise the horizontal scrollbar would overlap the last row in the table.
            const tableContainerHeight = this.tableContainerRef.current?.clientHeight || 0;

            // Re-compute the page height.
            let effectivePageHeight = determinePageHeight(numberOfRows, rowHeights, detailsEnabled, expandedRow != null, rowHeightAsNumber);

            // Determine the scrollTop and scrollBottom (i.e. hidden/off-screen heights for TOP and BOTTOM).
            // The scrollTop represents the height of the page that is hidden/off-screen (at the TOP of the container).
            // The scrollBottom represents the height of the page that is hidden/off-screen (at the BOTTOM of the container).
            const tableContainerScrollTop = this.tableContainerRef.current ? this.tableContainerRef.current.scrollTop : 0;
            const tableContainerScrollBottom = tableContainerScrollTop + tableContainerHeight - (!hideColumnHeaders ? headerHeight : 0);

            // The numer of hidden/off-screen rows to render (mitigates the appearance of the "blank" area when scrolling... you can tweak this value as necessary).
            const overflowRowCount = overflowCount != null ? overflowCount : DEFAULT_OVERFLOW_COUNT;

            // Determine the FIRST and LAST visible/on-screen row indices.
            // These would be the indices of the FIRST and LAST rows that are actually visible/on-screen (even partially) inside the table container.
            const firstVisibleRowIdx = determineFirstVisibleRow(tableContainerScrollTop, numberOfRows, rowHeights, detailsEnabled, rowHeightAsNumber);
            const lastVisibleRowIdx = determineLastVisibleRow(tableContainerScrollBottom, numberOfRows, rowHeights, detailsEnabled, rowHeightAsNumber);

            // Determine the actual number of hidden/off-screen rows to render (BEFORE and AFTER the visible/on-screen page rows).
            // These would be the overflow rows (those rows that are rendered as part of the DOM but are NOT actually visible within the table container).
            const topOverflowRowCount = Math.min(overflowRowCount, firstVisibleRowIdx);
            const bottomOverflowRowCount = Math.min(overflowRowCount, numberOfRows - (lastVisibleRowIdx + 1));

            // Determine the TOP and BOTTOM buffer row heights (used to fill out the rest of the page height).
            //     - TOP Buffer: Space allocated to the buffer row rendered BEFORE the TOP overflow rows (this is a totally empty/hidden row).
            //     - BOTTOM Buffer: Space allocated to the buffer row rendered AFTER the BOTTOM overflow rows (this is a totally empty/hidden row).
            const topBufferHeight = determineTopBufferHeight(firstVisibleRowIdx, topOverflowRowCount, rowHeights, detailsEnabled, rowHeightAsNumber);
            const bottomBufferHeight = determineBottomBufferHeight(firstVisibleRowIdx, lastVisibleRowIdx, topOverflowRowCount, bottomOverflowRowCount, effectivePageHeight, rowHeights, detailsEnabled, rowHeightAsNumber);

            // Generate the TOP and BOTTOM buffer rows. These will be placed BEFORE and AFTER the rows that are actually rendered (to fill out the rest of the page height).
            const topBuffer = topBufferHeight > 0 ? <TableRow key={"__virtualized_top_buffer__"} style={{ height: topBufferHeight }}></TableRow> : null;
            const bottomBuffer = bottomBufferHeight > 0 ? <TableRow key={"__virtualized_bottom_buffer__"} style={{ height: bottomBufferHeight }}></TableRow> : null;

            /*** Next we actually build the collection of rendered rows. ***/

            // Add the TOP buffer row.
            if (topBuffer) {
                rows.push(topBuffer);
            }

            // Add the visible/on-screen and overflow rows.
            for (let x = 0, n = numberOfRows; x < n; ++x) {
                const row = currentRows[x];

                // If the row being processed is part of the visible/on-screen rows OR part of the overflow rows, then render it.
                if (x >= firstVisibleRowIdx - topOverflowRowCount && x <= lastVisibleRowIdx + bottomOverflowRowCount) {
                    if (row.__details_row__) {
                        const targetRowIdx = x - 1;

                        const targetRow = targetRowIdx >= 0 ? currentRows[targetRowIdx] : null;

                        if (targetRow) {
                            rows.push(this.renderTableRowDetails(targetRow, x));
                        }
                    } else {
                        rows.push(this.renderTableRow(row, x));
                    }
                }
            }

            // Add the BOTTOM buffer row.
            if (bottomBuffer) {
                rows.push(bottomBuffer);
            }

            console.debug("Rendered Table Body", effectivePageHeight, rows.length, firstVisibleRowIdx, lastVisibleRowIdx, topOverflowRowCount, bottomOverflowRowCount, topBufferHeight, bottomBufferHeight);

            return (
                <TableBody id={id + "-table-body"} style={{ flex: "1 1 auto", overflow: "auto", minHeight: effectivePageHeight, height: effectivePageHeight, maxHeight: effectivePageHeight }}>
                    {rows}
                </TableBody>
            );
        } else {
            // Basic non-virtualization support.
            // In this scenario we simply render EVERY row of the current page.
            for (let x = 0, n = numberOfRows; x < n; ++x) {
                const row = currentRows[x];

                if (row.__details_row__) {
                    rows.push(this.renderTableRowDetails(row, x));
                } else {
                    rows.push(this.renderTableRow(row, x));
                }
            }

            return (
                <TableBody id={id + "-table-body"} style={{ flex: "1 1 auto", overflow: "auto" }}>
                    {rows}
                </TableBody>
            );
        }
    }

    render() {
        const {
            classes,
            id,
            columns,
            selectMode,
            hideColumnHeaders,
            hidePaginator,
            dataProvider,
            minHeight,
            maxHeight,
            fontSize,
            filters,
            bulkActions,
            actions,
            actionColumnWidth,
            swapSelectAndActionColumns,
            virtualized,
            customBanner,
            customJsonFilter,
        } = this.props;
        const { tableData, page, pageSize, headerCellWidths, defaultPageSize, orderBy, orderDirection, filterData, actionsAnchorElement, actionsAnchorRow } = this.state;

        // Define a TableData variable to represent the "current page".
        const currentPage: TableData = { rows: [], total: 0 };

        // Populate the "current page" (based on whether the dataProvider is a function or a TableData instance).
        // If it is a TableData instance then ALL rows are present, otherwise only the current page is present.
        if (tableData) {
            if (typeof dataProvider !== "function") {
                currentPage.rows = tableData.rows.slice(page * pageSize, page * pageSize + pageSize);
                currentPage.total = tableData.total;
            } else {
                currentPage.rows = [...tableData.rows];
                currentPage.total = tableData.total;
            }
        }

        // Attach an 'onscroll' handler (if necessary) to the table container so we can trigger some code as a result of scrolling.
        // This includes things like:
        //     - Force rendering the table to account for buffer/overflow rows (when the table is "virtualized" and the user is scrolling veritcally).
        //     - Pushing the optional "details" content around (when "details" support is enabled and the user is scrolling horizontally) so that it gets re-positioned correctly.
        if (this.tableContainerRef.current && this.tableContainerRef.current.onscroll == null) {
            this.tableContainerRef.current.onscroll = () => {
                if (virtualized || this.props.renderRowDetails) {
                    this.forceUpdate();
                }
            };
        }

        return (
            <div id={id + "-table"} className={classes.root}>
                {/* Render the toolbar (contains filters and bulk actions) */}
                <Toolbar
                    id={id + "-toolbar"}
                    className={classes.toolbar}
                    selectedRows={this.props.selectedRows ? this.props.selectedRows : this.state.selectedRows}
                    filters={filters}
                    filterData={filterData}
                    customJsonFilter={customJsonFilter ? { ...customJsonFilter, active: filterData[customJsonFilter.key || "customJson"] != null } : undefined}
                    bulkActions={bulkActions}
                    onFilterChanged={this.changeFilter}
                />

                {/* Render the optional "custom" banner */}
                {customBanner && (
                    <div id={id + "-custom-banner"} className={classes.customBanner}>
                        {customBanner}
                    </div>
                )}

                <div id={id + "-table-wrapper"} className={classes.wrapper} style={{ width: "100%", position: "relative", minHeight: minHeight, maxHeight: maxHeight, fontSize: fontSize }}>
                    {/* Render the actual table. */}
                    <TableContainer id={id + "-table-container"} style={{ flex: "1 1 auto", position: "relative", height: "auto" }} ref={this.tableContainerRef}>
                        <Table stickyHeader aria-label="sticky table" style={{ height: "1px" }}>
                            {/* Render the column headers (if applicable, i.e. if they're NOT being explicitly hidden). */}
                            {!hideColumnHeaders && (
                                <Header
                                    id={id + "-table-header"}
                                    className={classes.header}
                                    selectMode={selectMode}
                                    allSelected={currentPage.rows.length > 0 && this.isSelected(currentPage.rows)}
                                    definitions={columns}
                                    headerCellWidths={headerCellWidths}
                                    orderBy={orderBy}
                                    orderDirection={orderDirection}
                                    actions={actions}
                                    actionColumnWidth={actionColumnWidth}
                                    swapSelectAndActionColumns={swapSelectAndActionColumns}
                                    onSort={this.changeSort}
                                    onHeightCalculated={this.measurerCache ? this.measurerCache.setHeaderValue : null}
                                    onStickyWidthCalculated={this.measurerCache ? this.measurerCache.setStickyValue : null}
                                    onToggleAllSelected={this.toggleAllRowsSelected}
                                    onColumnResize={this.measurerCache ? this.measurerCache.setHeaderCellValue : null}
                                />
                            )}

                            {/* Render the table body. */}
                            {this.renderTableBody(currentPage, virtualized)}
                        </Table>
                    </TableContainer>

                    {/* Render the "Loading Data..." overlay if we have no table data (i.e. the tableData is null/undefined). */}
                    {!tableData && (
                        <div id={id + "-loading-overlay"} className={"centered"} style={{ fontSize: "1rem" }}>
                            <LoadingProgress label={<Trans>Loading Data...</Trans>} />
                        </div>
                    )}

                    {/* Render the "No records found..." overlay if we have table data but no rows (i.e. the tableData is NOT null/undefined and it's "rows" collection has a length greater than 0). */}
                    {tableData && (tableData.total === 0 || tableData.rows.length === 0) && tableData.rows.length === 0 && (
                        <div id={id + "-no-records-overlay"} className={"centered"} style={{ fontSize: "1rem", background: "transparent" }}>
                            <Typography>
                                <Trans>No Records Found</Trans>
                            </Typography>
                        </div>
                    )}
                </div>

                {/* Render the paginator (if applicable, i.e. it's NOT being explicitly hidden). */}
                {tableData && !hidePaginator && (
                    <Paginator
                        id={id + "-paginator"}
                        count={tableData ? (currentPage.total != null ? currentPage.total : Number.POSITIVE_INFINITY) : 0}
                        currentPageCount={tableData ? tableData.rows.length : 0}
                        page={tableData ? page : 0}
                        pageSize={pageSize}
                        defaultPageSize={defaultPageSize}
                        onPageChange={this.changePage}
                        onPageSizeChange={this.changeRowsPerPage}
                    />
                )}

                {/* Render the open actions menu for individual row actions (if applicable). */}
                {actions && typeof actions !== "function" && actions.length > 0 && actionsAnchorRow && (
                    <CustomMenu
                        id={id + "-row-actions-popover"}
                        anchorElement={actionsAnchorElement}
                        anchorOrigin={{ vertical: "top", horizontal: !swapSelectAndActionColumns ? "left" : "right" }}
                        transformOrigin={{ vertical: "top", horizontal: !swapSelectAndActionColumns ? "right" : "left" }}
                        open={Boolean(actionsAnchorElement)}
                        onClose={this.closeActionsMenu}
                        menuItems={actions.map((action) => {
                            const hidden = typeof action.hidden === "function" ? action.hidden(actionsAnchorRow) : action.hidden === true;
                            const disabled = typeof action.disabled === "function" ? action.disabled(actionsAnchorRow) : action.disabled === true;

                            return {
                                id: action.id,
                                label: action.render(actionsAnchorRow),
                                hidden: hidden,
                                disabled: disabled,
                                onClick: () => {
                                    this.closeActionsMenu();

                                    action.action(actionsAnchorRow);
                                },
                            };
                        })}
                    />
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            zIndex: 0,

            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
            position: "relative",

            minHeight: "10em",

            // "& > *": {
            //     fontSize: "0.875em !important",
            //     "& *": {
            //         fontSize: "inherit",
            //     },
            // },
        },
        wrapper: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            fontSize: "0.875em",
            "& *": {
                fontSize: "inherit",
            },
        },
        customBanner: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            borderBottomWidth: "0.0625em",
            borderBottomStyle: "solid",
        },
        toolbar: {},
        header: {
            // fontSize: "0.875em",

            "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                color: "inherit",

                "& .MuiTableSortLabel-icon": {
                    color: "inherit",
                },
            },
        },
        row: {
            fontSize: "inherit",

            // "& > .MuiTableCell-root": {
            //     fontSize: "0.875em", // EXPERIMENTAL: Default font sizing for table cell contents.
            // },
            "& .MuiCollapse-wrapper, .MuiCollapse-wrapperInner": {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
            },

            "&.MuiTableRow-root > .MuiTableCell-root > div > div.hoverOverlay": {
                display: "none",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,

                backgroundColor: "var(--table-row-hover-background-color, inherit)",
            },

            "&.MuiTableRow-root.MuiTableRow-hover:hover": {
                backgroundColor: "inherit",
                color: "var(--table-row-hover-color, inherit)",
                borderColor: "inherit",

                "& > .MuiTableCell-root > div > div.hoverOverlay": {
                    display: "block",
                },
            },
        },
        rowWithoutBorder: {
            "& > .MuiTableCell-root": {
                border: "unset",
            },
        },
        striped: {
            "&.MuiTableRow-root:not(.selected) > .MuiTableCell-root > div > div.stripeOverlay": {
                display: "block",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,

                backgroundColor: "inherit",
                color: "inherit",
            },

            "&:nth-child(odd):not(.selected).MuiTableRow-root > .MuiTableCell-root > div > div.stripeOverlay": {
                backgroundColor: "var(--table-row-odd-background-color, inherit)",
                color: "var(--table-row-odd-color, inherit)",
            },

            "&:nth-child(even):not(.selected).MuiTableRow-root > .MuiTableCell-root > div > div.stripeOverlay": {
                backgroundColor: "var(--table-row-even-background-color, inherit)",
                color: "var(--table-row-even-color, inherit)",
            },
        },
        selected: {
            backgroundColor: "var(--table-row-selected-background-color, inherit)",
            color: "var(--table-row-selected-color, inherit)",

            "& > .MuiTableCell-root.MuiTableCell-body": {
                backgroundColor: "var(--table-row-selected-background-color, inherit)",
                color: "var(--table-row-selected-color, inherit)",
            },
        },
        rowHover: {
            backgroundColor: "var(--table-row-hover-background-color, inherit)",
            color: "var(--table-row-hover-color, inherit)",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(CustomTable));
