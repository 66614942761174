export default interface PlayerInfo {
    playerId: string;

    appId: string | null;
    appVersion: string | null;

    name: string | null;
    email: string | null;

    platform: string | null;

    loginCount: number | null;

    createdAt: Date | null;

    lastLogin: Date | null;

    updatedAt: Date | null;

    isActive: boolean | null;

    notes: string | null;

    pictureUrl: string | null;

    amountSpent: number | null;
}

export const processPlayerInfo = (data: any): PlayerInfo => {
    return {
        playerId: data.playerId || "",

        appId: data.gameId || null,
        appVersion: data.appVersion || null,

        name: data.name ? data.name : data.playerName || null,
        email: data.email ? data.email : data.emailAddress || null,

        platform: data.platform || null,

        loginCount: data.loginCount != null ? Number.parseInt(data.loginCount) : null,

        createdAt: data.createdAt != null ? new Date(data.createdAt) : null,

        lastLogin: data.lastLogin != null ? new Date(data.lastLogin) : null,

        updatedAt: data.updatedAt != null ? new Date(data.updatedAt) : null,

        isActive: data.isActive != null ? data.isActive : null,

        notes: data.notes || null,

        pictureUrl: data.pictureUrl || null,

        amountSpent: data.start?.amountSpent != null ? Number.parseInt(data.start?.amountSpent) : null,
    } as PlayerInfo;
};
