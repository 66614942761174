// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import QueryString from "query-string";
import { connect } from "react-redux";
import classnames from "classnames";

// Import types.
import User from "types/common/User";
import PortalState from "types/store";
import SupportedLanguage from "types/enums/SupportedLanguage";
import { MenuItemDefinition } from "components/common/menu/types";

// Import components.
import { MenuItem, Tooltip, Typography } from "@mui/material";
import CustomDialog from "components/common/dialog/CustomDialog";
import IconButton from "components/common/button/IconButton";
import PortalComponent from "framework/PortalComponent";
import CustomMenu from "components/common/menu";

// Import icons.
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import HelpCenterIcon from "components/icons/HelpCenter";

// Import services.
import BrainCloudProperties from "services/BrainCloudProperties";

export interface AdditionalLink {
    label: {
        en: string;
        fr: string;
    };
    url: string;
    isNew?: boolean;
}

interface STATE_PROPS {
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    style: React.CSSProperties;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

const mapStateToProps = (state: PortalState) => {
    return { currentUser: state.currentUser };
};
const mapDispatchToProps = () => {
    return {};
};

interface STATE {
    additionalLinks: AdditionalLink[] | null;
    anchorEl: any;
}

class Help extends PortalComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        additionalLinks: null,
        anchorEl: null,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        let additionalLinks: any = null;

        try {
            additionalLinks = await BrainCloudProperties.getSystemProperty("portalxHeaderHelpLinks");

            this.setState({ additionalLinks: additionalLinks });
        } catch (error: any) {
            toast.error("[" + error.errorCode + "] - " + error.errorMessage);

            additionalLinks = [];
        }

        this.setState({ additionalLinks: additionalLinks });
    };

    onToggleOpen = (event?: any) => {
        this.setState({ anchorEl: this.state.anchorEl == null && event ? event.currentTarget : null });
    };

    onAboutClicked = () => {
        this.onToggleOpen();

        window.setTimeout(() => {
            window.open(window.origin + "/about");
        }, 500);
    };

    onLinkClicked = (url: string) => {
        const { history } = this.props;

        this.onToggleOpen();

        if (url.startsWith("#")) {
            const parts = url.substring(1).split("?");

            let targetPath: string | null = null;
            let targetState: any = null;

            if (parts.length > 0) {
                targetPath = parts[0];

                if (parts.length > 1) {
                    targetState = QueryString.parse(parts[1].substring(1));
                }
            }

            if (targetPath) {
                history.push({ pathname: targetPath, state: targetState });
            }
        } else {
            window.setTimeout(() => {
                window.open(url);
            }, 500);
        }
    };

    renderContent = () => {
        const { classes, currentUser } = this.props;
        const { additionalLinks } = this.state;

        return (
            <div className={classes.content}>
                {additionalLinks &&
                    additionalLinks.length > 0 &&
                    additionalLinks.map((item, idx) => {
                        return (
                            <MenuItem
                                key={"link" + idx}
                                component={"div"}
                                className={classnames({ [classes.helpItem]: true, "bc-intercom-launcher": item.url.toLowerCase() === "%intercom%" })}
                                onClick={() => (item.url.toLowerCase() !== "%intercom%" ? this.onLinkClicked(item.url) : this.onToggleOpen())}
                            >
                                <Typography style={{ flex: "1 1 auto" }} dangerouslySetInnerHTML={{ __html: currentUser?.preferredLanguage === SupportedLanguage.FRENCH ? item.label.fr : item.label.en }}></Typography>

                                {item.isNew && <NewReleasesIcon style={{ flex: "0 0 auto", fontSize: "1em", width: "1.5em", height: "1.5em" }} />}
                            </MenuItem>
                        );
                    })}

                {/* {additionalLinks && additionalLinks.length > 0 && <Divider style={{ backgroundColor: "var(--popup-menu-border-color, inherit)", marginTop: "auto" }} />} */}

                {/* <MenuItem component={"div"} className={classes.helpItem} onClick={this.onAboutClicked}>
                    <Typography>
                        <Trans>About</Trans>
                    </Typography>
                </MenuItem> */}
            </div>
        );
    };

    render() {
        const { classes, style, currentUser } = this.props;
        const { anchorEl, additionalLinks } = this.state;

        const menuItems: MenuItemDefinition[] = [];

        if (additionalLinks && additionalLinks.length > 0)
            additionalLinks.forEach((item, idx) => {
                menuItems.push({
                    id: "link" + idx,
                    label: <Typography style={{ flex: "1 1 auto" }} dangerouslySetInnerHTML={{ __html: currentUser?.preferredLanguage === SupportedLanguage.FRENCH ? item.label.fr : item.label.en }}></Typography>,
                    icon: item.isNew ? <NewReleasesIcon /> : null,
                    iconPlacement: "right",
                    onClick: () => (item.url.toLowerCase() !== "%intercom%" ? this.onLinkClicked(item.url) : this.onToggleOpen()),
                } as MenuItemDefinition);
            });

        return (
            <div id={"help"} className={classes.root} style={style}>
                <Tooltip arrow title={<Trans>Additional Help</Trans>}>
                    <IconButton id={"toggle-open"} className={classes.iconButton} style={{}} onClick={this.onToggleOpen}>
                        <HelpCenterIcon />
                    </IconButton>
                </Tooltip>

                {!isMobile && (
                    <CustomMenu
                        id={"popover-help"}
                        open={Boolean(anchorEl)}
                        onClose={this.onToggleOpen}
                        anchorElement={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        header={<Trans>Help</Trans>}
                        menuItems={menuItems}
                    />
                )}

                {isMobile && <CustomDialog id={"help"} className={classes.dialog} open={Boolean(anchorEl)} ready={true} onClose={this.onToggleOpen} header={<Trans>Help</Trans>} content={this.renderContent()} />}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
        },
        dialog: {
            "& .MuiDialogContent-root": {
                padding: 0,
            },
        },
        content: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            overflow: "auto",

            "& > .MuiMenuItem-root": {
                borderColor: "inherit",

                "&:hover": {
                    backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                    color: "var(--popup-menu-hover-color, inherit)",
                },
            },
        },
        helpItem: {
            minHeight: "2.875em",
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
                flex: "0 0 auto",
                width: "1.5em",
                height: "1.5em",
            },
            "& .MuiTypography-root": {
                flex: "1 1 auto",
                marginRight: "0.5em",
                marginLeft: "0.5em",
            },
        },
        iconButton: {
            color: "var(--header-help-icon-color, inherit)",
            width: "100%",
            height: "100%",
            margin: 0,
            padding: 0,
            "&:hover .MuiTouchRipple-root": {
                backgroundColor: "transparent !important",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Help)));
