// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";

// Import Types.
import DialogMode from "types/enums/DialogMode";

// Import Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import BrokenImage from "components/icons/BrokenImage";

// Import Components.
import { Tooltip, Typography } from "@mui/material";
import IconButton from "../button/IconButton";
import FileUpload from "components/common/form/FileUpload";
import LoadingProgress from "../widgets/LoadingProgress";
import ConfirmActionDialog from "../dialog/ConfirmActionDialog";

// Import Utils.
import { encodeQueryParameters } from "utils/networking/Http";

interface PROPS extends WithStyles<typeof styles> {
    style?: CSSProperties;
    src?: React.ReactNode;
    fallback?: React.ReactNode;
    alt?: string | null;
    isLoading?: boolean;
    disableCache?: string | number | boolean | Date | null;
    readonly?: boolean;
    onChange?: (files: File | null) => void;
}

interface STATE {
    isSrcError: boolean;
    isFallbackError: boolean;

    timestamp: Date;
    dialogMode: DialogMode.DELETE | null;
}

class ImageWrapper extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        isSrcError: false,
        isFallbackError: false,

        timestamp: new Date(),
        dialogMode: null,
    };

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.src !== this.props.src) {
            this.setState({ isSrcError: false, isFallbackError: false, timestamp: new Date() });
        }
    }

    onSrcError = () => {
        this.setState({ isSrcError: true });
    };

    onFallbackError = () => {
        this.setState({ isFallbackError: true });
    };

    openRemoveImageDialog = () => {
        this.setState({ dialogMode: DialogMode.DELETE });
    };

    closeConfirmRemoveImageDialog = (confirmed?: boolean) => {
        if (confirmed && this.props.onChange) this.props.onChange(null);

        this.setState({ dialogMode: null });
    };

    render() {
        const { classes, style, src, fallback, alt, isLoading, disableCache, readonly } = this.props;
        const { isSrcError, isFallbackError, timestamp, dialogMode } = this.state;

        const hideButtons = isLoading || readonly || !this.props.onChange;

        let image = null;

        if ((!src || (typeof src === "string" && src.trim().length === 0)) && (!fallback || (typeof fallback === "string" && fallback.trim().length === 0))) {
            image = null;
        } else {
            if (isSrcError || isFallbackError) {
                image = <BrokenImage />;
            } else {
                if (!src && fallback) {
                    if (typeof fallback === "string") {
                        const queryParameters =
                            disableCache !== undefined
                                ? (fallback.includes("?") ? "&" : "?") +
                                  encodeQueryParameters({ nocdn: disableCache != null && typeof disableCache === "object" ? (disableCache as Date).getTime() : disableCache != null ? disableCache : timestamp.getTime() })
                                : "";

                        image = <img key={fallback} width={"100%"} height={"100%"} src={fallback + queryParameters} alt={alt || "image-fallback"} onError={() => this.onFallbackError()} />;
                    } else {
                        image = fallback;
                    }
                } else {
                    if (typeof src === "string") {
                        const queryParameters =
                            disableCache !== undefined
                                ? (src.includes("?") ? "&" : "?") +
                                  encodeQueryParameters({ nocdn: disableCache != null && typeof disableCache === "object" ? (disableCache as Date).getTime() : disableCache != null ? disableCache : timestamp.getTime() })
                                : "";

                        image = <img key={src} width={"100%"} height={"100%"} src={src + queryParameters} alt={alt || "image-source"} onError={() => this.onSrcError()} />;
                    } else {
                        image = src;
                    }
                }
            }
        }

        let customStyle = Object.assign(
            {
                width: "4em",
                height: "4em",
                borderStyle: "none",
                borderColor: "inherit",
                borderRadius: "0.25em",
                overflow: "hidden",
            },
            style
        );

        if (isSrcError || isFallbackError) {
            customStyle.width = "auto";
            customStyle.height = "inherit";
            customStyle.maxWidth = style?.width;
            customStyle.maxHeight = style?.height;
        }

        return (
            <span className={classes.root} style={customStyle} data-src={typeof src === "string" ? src : undefined} data-fallback={typeof fallback === "string" ? fallback : undefined}>
                {isLoading ? <LoadingProgress hideLabel={true} type={"linear"} /> : image}

                {!hideButtons &&
                    (!src ? (
                        <FileUpload
                            name={"app-image"}
                            style={{ position: "absolute", bottom: "0.375em", width: "7.375em", height: "fit-content" }}
                            acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                            label={<Trans>Upload...</Trans>}
                            onChange={(_name: string, files: File[]) => {
                                if (this.props.onChange) this.props.onChange(files[0]);
                            }}
                        />
                    ) : (
                        <Tooltip title={<Trans>Remove Image</Trans>}>
                            <IconButton id={"remove-image"} type={"semantic-negative"} className={classes.removeButton} onClick={this.openRemoveImageDialog}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    ))}

                {dialogMode === DialogMode.DELETE && (
                    <ConfirmActionDialog title={<Trans>Remove This Image?</Trans>} className={classes.cofirmRemoveImageDialog} actionInProgress={undefined} actionMode={"delete"} onClose={this.closeConfirmRemoveImageDialog}>
                        <Typography>
                            <Trans>Are you sure? This action cannot be undone.</Trans>
                        </Typography>
                    </ConfirmActionDialog>
                )}
            </span>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            maxWidth: "100%",
            maxHeight: "100%",

            flex: "0 0 auto",

            display: "flex",
            justifyContent: "center",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",

            position: "relative",

            "& > *": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
            },
            "&:hover": {
                "& .MuiIconButton-root": {
                    display: "flex",
                },
            },
        },
        cofirmRemoveImageDialog: {
            "& > .MuiDialog-container > .MuiDialog-paper": {
                flex: "0 1 auto",
                minWidth: "unset",
                padding: "1em",
                gap: ".5em",
            },
        },
        removeButton: {
            display: "none",
            height: "2em",
            width: "2em",
            position: "absolute",
            top: 0,
            right: 0,
            color: "var(--button-semantic-negative-secondary-color)",
        },
    });

export default withStyles(styles)(ImageWrapper);
