// Import libraries.
import React from "react";
import { Theme, Typography } from "@mui/material";

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

// Import utilities.
import { DEFAULT_PAGE_SIZE_OPTIONS } from "../../utils";
import IconButton from "components/common/button/IconButton";
import { NavigateBefore, NavigateNext, SkipNext, SkipPrevious } from "@mui/icons-material";
import { Trans } from "@lingui/macro";
import FieldWrapper from "components/common/form/FieldWrapper";
import { SelectFieldOptions } from "components/common/form/fields/SelectField";
import { NumberFieldOptions } from "components/common/form/fields/NumberField";

interface OWN_PROPS {
    id: string;

    count: number;
    currentPageCount: number;

    page: number;
    pageSize: number;
    defaultPageSize: number;
    pageSizeOptions?: number[];

    onPageChange: (page: number) => void;
    onPageSizeChange: (pagesize: number) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

interface STATE {
    selectedPage: number;
}

class Paginator extends React.Component<PROPS, STATE> {
    state: Readonly<STATE> = {
        selectedPage: 0,
    };

    componentDidMount() {
        const { page } = this.props;

        this.setState({ selectedPage: page + 1 });
    }

    componentDidUpdate = (prevProps: PROPS) => {
        if (this.props.currentPageCount === 0 && this.props.page > 0) {
            this.props.onPageChange(0);
        }

        if (prevProps.page !== this.props.page) {
            this.setState({ selectedPage: this.props.page + 1 });
        }
    };

    handleChange = (_name: string, value: number) => {
        this.setState({ selectedPage: value });
    };

    handlePageChange = (selectedPage: number, maxPage: number) => {
        if (selectedPage <= maxPage && selectedPage > 0 && selectedPage !== this.props.page + 1) {
            this.props.onPageChange(selectedPage - 1);

            return;
        }

        this.setState({ selectedPage: this.props.page + 1 });
    };

    render() {
        const { classes, id, count, page, pageSize, defaultPageSize, pageSizeOptions, onPageChange, onPageSizeChange } = this.props;
        const { selectedPage } = this.state;

        const maxPage = pageSize > 0 && count > 0 ? Math.ceil(count / pageSize) : 0;

        const pageSizes = pageSizeOptions != null ? pageSizeOptions : DEFAULT_PAGE_SIZE_OPTIONS;

        return (
            <div id={id} className={classes.rowBox} style={{ justifyContent: !(count <= defaultPageSize) ? "space-between" : "flex-end" }}>
                <div className={classes.content}>
                    {!(count <= defaultPageSize) && (
                        <div className={classes.rowBox}>
                            <IconButton id={"first-page-button"} onClick={() => onPageChange(0)} disabled={page <= 0}>
                                <SkipPrevious />
                            </IconButton>

                            <IconButton id={"pre-button"} onClick={() => onPageChange(page - 1)} disabled={page <= 0}>
                                <NavigateBefore />
                            </IconButton>

                            <FieldWrapper
                                name={"selectPage"}
                                type={"number"}
                                value={selectedPage}
                                label={
                                    maxPage > 0 &&
                                    isFinite(maxPage) && (
                                        <>
                                            <Trans>of </Trans> {maxPage}
                                        </>
                                    )
                                }
                                labelAlignment={"center"}
                                labelPosition={"right"}
                                controlStyle={{ width: "5em" }}
                                onChange={this.handleChange}
                                onBlur={() => this.handlePageChange(selectedPage, maxPage)}
                                onKeyPress={(_name: string, key: string) => {
                                    if (key === "Enter" || key === " ") this.handlePageChange(selectedPage, maxPage);
                                }}
                                options={
                                    {
                                        maxValue: isFinite(maxPage) ? (maxPage === 0 ? maxPage + 1 : maxPage) : Number.POSITIVE_INFINITY,
                                    } as NumberFieldOptions
                                }
                            />

                            <IconButton id={"next-button"} onClick={() => onPageChange(page + 1)} disabled={isFinite(maxPage) && page + 1 >= maxPage}>
                                <NavigateNext />
                            </IconButton>

                            {isFinite(maxPage) && (
                                <IconButton id={"last-page-button"} onClick={() => onPageChange(maxPage - 1)} disabled={page + 1 >= maxPage}>
                                    <SkipNext />
                                </IconButton>
                            )}
                        </div>
                    )}
                </div>

                <div className={classes.content} style={{ justifyContent: !(count <= defaultPageSize) ? "space-between" : "flex-end" }}>
                    <Typography className={classes.rowBox} style={{ marginRight: "0.3125em", alignSelf: "center" }}>
                        {isFinite(count) && count !== 0 && <Trans>{count} records</Trans>}
                    </Typography>

                    {!(count <= defaultPageSize) && (
                        <div className={classes.rowBox} style={{ justifyContent: "end" }}>
                            <FieldWrapper
                                name={"pageSize"}
                                type={"select"}
                                label={<Trans>Result per page:</Trans>}
                                labelAlignment={"flex-start"}
                                labelPosition={"left"}
                                value={pageSize}
                                onChange={(name: string, value: any) => {
                                    try {
                                        if (!isNaN(value)) {
                                            onPageSizeChange(value);
                                        }
                                    } catch (error: any) {
                                        console.warn("Invalid pageSize value", pageSize);
                                    }
                                }}
                                options={
                                    {
                                        options: pageSizes.map((item) => {
                                            return { value: item, label: item };
                                        }),
                                    } as SelectFieldOptions
                                }
                                required={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        rowBox: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",

            fontSize: "inherit",
        },
        content: {
            flex: "1 1 auto",
            display: "flex",
            justifyContent: "space-between",
        },
    });

export default withStyles(styles)(Paginator);
