import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import Permission from "types/enums/Permission";
import PortalState from "types/store";
import { hasReadAccess, hasWriteAccess } from "types/common/PortalPrivilege";

export interface WithPermissionProps {
    bcHasNoAccess: boolean;
    bcHasReadAccess: boolean;
    bcHasWriteAccess: boolean;
}

export const withPermission = (id?: string) => {
    return <P extends WithPermissionProps>(Component: React.ComponentType<P>): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithPermissionProps>>> => {
        return (props: Pick<P, Exclude<keyof P, keyof WithPermissionProps>>) => {
            const session = useSelector((state: PortalState) => state.session);
            const availablePrivileges = useSelector((state: PortalState) => state.availablePrivileges);
            const location = useLocation();

            let effectivePermission = Permission.NONE;

            if (location.pathname.startsWith("/super")) {
                if (session.isSuper) {
                    if (session.isTeamAdmin) {
                        effectivePermission = Permission.WRITE;
                    } else {
                        effectivePermission = Permission.READ;
                    }
                }
            } else if (location.pathname.startsWith("/team")) {
                if (session.isSuper) {
                    if (session.isTeamAdmin) {
                        effectivePermission = Permission.WRITE;
                    } else {
                        effectivePermission = Permission.READ;
                    }
                } else {
                    if (session.isTeamAdmin) {
                        effectivePermission = Permission.WRITE;
                    } else {
                        effectivePermission = Permission.READ;
                    }
                }
            } else {
                if (session.isSuper) {
                    if (session.isTeamAdmin) {
                        if (!session.isLiveLocked) {
                            effectivePermission = Permission.WRITE;
                        } else {
                            effectivePermission = Permission.READ;
                        }
                    } else {
                        effectivePermission = Permission.READ;
                    }
                } else {
                    if (id != null) {
                        if (hasWriteAccess(availablePrivileges, id)) {
                            if (!session.isLiveLocked) {
                                effectivePermission = Permission.WRITE;
                            } else {
                                effectivePermission = Permission.READ;
                            }
                        } else {
                            if (hasReadAccess(availablePrivileges, id)) {
                                effectivePermission = Permission.READ;
                            }
                        }
                    } else {
                        effectivePermission = Permission.WRITE;
                    }
                }
            }

            return <Component {...(props as P)} bcHasNoAccess={effectivePermission === Permission.NONE} bcHasReadAccess={effectivePermission !== Permission.NONE} bcHasWriteAccess={effectivePermission === Permission.WRITE} />;
        };
    };
};
