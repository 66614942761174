import moment from "moment-timezone";

import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss";

export const HUMAN_DATE_FORMAT = "MMM Do YYYY";
export const HUMAN_TIME_FORMAT = "h:mmA";

export const DEFAULT_TIMEZONE = "UTC";

export default abstract class DateTimeFormatter {
    private static CURRENT_TIMEZONE: string = DEFAULT_TIMEZONE;

    public static formatDate(timestamp?: Date | moment.Moment | null, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        return requestedDateTime.format(DEFAULT_DATE_FORMAT);
    }

    public static formatDateHuman(timestamp?: Date | moment.Moment | null, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        return requestedDateTime.format(HUMAN_DATE_FORMAT);
    }

    public static formatTime(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        if (includeTimezone) {
            return requestedDateTime.format(DEFAULT_TIME_FORMAT + " z");
        } else {
            return requestedDateTime.format(DEFAULT_TIME_FORMAT);
        }
    }

    public static formatTimeHuman(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        if (includeTimezone) {
            return requestedDateTime.format(HUMAN_TIME_FORMAT + " z");
        } else {
            return requestedDateTime.format(HUMAN_TIME_FORMAT);
        }
    }

    public static formatDateTime(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        return DateTimeFormatter.formatDate(timestamp, timezone) + " " + DateTimeFormatter.formatTime(timestamp, includeTimezone, timezone);
    }

    public static formatDateTimeHuman(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        return DateTimeFormatter.formatDateHuman(timestamp, timezone) + " " + DateTimeFormatter.formatTimeHuman(timestamp, includeTimezone, timezone);
    }

    public static formatDateTimeCustom(timestamp?: Date | moment.Moment | null, format?: string | null, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        if (format) {
            return requestedDateTime.format(format);
        } else {
            return requestedDateTime.format();
        }
    }

    public static convertToCurrentTimezone(timestamp?: Date | moment.Moment | null) {
        return moment(timestamp).tz(DateTimeFormatter.CURRENT_TIMEZONE);
    }

    public static setCurrentTimezone(timezone?: string | null) {
        DateTimeFormatter.CURRENT_TIMEZONE = timezone ? timezone : DEFAULT_TIMEZONE;
    }

    public static formatTimeAgoOrTimeUntil(date: Date | null | undefined, i18n: I18n, noOfValues?: number) {
        if (date == null) return "-";

        const currentDate = new Date();

        if (date.getTime() > currentDate.getTime()) return this.formatDuration(Math.round((date.getTime() - currentDate.getTime()) / 1000), i18n, noOfValues);

        // Calculate the differences in years, months, days, hours, and minutes
        let years = currentDate.getFullYear() - date.getFullYear();
        let months = currentDate.getMonth() - date.getMonth();
        let days = currentDate.getDate() - date.getDate();
        let hours = currentDate.getHours() - date.getHours();
        let minutes = currentDate.getMinutes() - date.getMinutes();

        if (minutes < 0) {
            hours--;
            minutes += 60;
        }

        if (hours < 0) {
            days--;
            hours += 24;
        }

        if (days < 0) {
            months--;
            const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            days += lastMonth.getDate();
        }

        if (months < 0) {
            years--;
            months += 12;
        }
        const timeAgoArray = [];

        if (years > 0) {
            timeAgoArray.push(`${years} ${years === 1 ? i18n._(t`yr`) : i18n._(t`yrs`)}`);
        }

        if (months > 0) {
            timeAgoArray.push(`${months} ${months === 1 ? i18n._(t`mo`) : i18n._(t`mos`)}`);
        }

        if (days > 0) {
            timeAgoArray.push(`${days} ${days === 1 ? i18n._(t`day`) : i18n._(t`days`)}`);
        }

        if (hours > 0) {
            timeAgoArray.push(`${hours} ${hours === 1 ? i18n._(t`hr`) : i18n._(t`hrs`)}`);
        }

        if (minutes > 0) {
            timeAgoArray.push(`${minutes} ${minutes === 1 ? i18n._(t`min`) : i18n._(t`mins`)}`);
        }

        return i18n._(t`${timeAgoArray.slice(0, noOfValues ? noOfValues : 3).join(" ")} ago`);
    }

    public static formatDuration(duration: number | null | undefined, i18n: I18n, noOfValues?: number) {
        if (duration == null) return "-";

        const seconds = duration;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;

        const months = Math.floor(days / 30);
        const remainingDays = days % 30;

        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;

        const durationArray = [];

        if (years > 0) {
            durationArray.push(`${years} ${years === 1 ? i18n._(t`yr`) : i18n._(t`yrs`)}`);
        }

        if (remainingMonths > 0) {
            durationArray.push(`${remainingMonths} ${remainingMonths === 1 ? i18n._(t`mo`) : i18n._(t`mos`)}`);
        }

        if (remainingDays > 0) {
            durationArray.push(`${remainingDays} ${remainingDays === 1 ? i18n._(t`day`) : i18n._(t`days`)}`);
        }

        if (remainingHours > 0) {
            durationArray.push(`${remainingHours} ${remainingHours === 1 ? i18n._(t`hr`) : i18n._(t`hrs`)}`);
        }

        if (remainingMinutes > 0) {
            durationArray.push(`${remainingMinutes} ${remainingMinutes === 1 ? i18n._(t`min`) : i18n._(t`mins`)}`);
        }
        if (remainingSeconds > 0) {
            durationArray.push(`${remainingSeconds} ${remainingSeconds === 1 ? i18n._(t`sec`) : i18n._(t`secs`)}`);
        }

        return durationArray.slice(0, noOfValues ? noOfValues : undefined).join(" ");
    }
}
