import { Trans } from "@lingui/macro";

enum AppStatus {
    DELETED = "deleted",
    ENABLED = "enabled",
    DISABLED = "disabled",
}

export const formatAppStatusLabel = (appStatus?: AppStatus | null) => {
    switch (appStatus) {
        case AppStatus.DELETED:
            return <Trans>Deleted</Trans>;
        case AppStatus.ENABLED:
            return <Trans>Enabled</Trans>;
        case AppStatus.DISABLED:
            return <Trans>Disabled</Trans>;
        default:
            return appStatus || <Trans>---</Trans>;
    }
};

export const formatAppStatusColor = (appStatus?: AppStatus | null) => {
    switch (appStatus) {
        case AppStatus.DELETED:
            return "var(--label-negative-color)";
        case AppStatus.ENABLED:
            return "var(--label-positive-color)";
        case AppStatus.DISABLED:
            return "var(--label-default-color)";
        default:
            return "var(--label-default-color)";
    }
};

export default AppStatus;
