// Import libraries.
import React from "react";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

// Import types.
import AppInfo from "types/models/AppInfo";

// Import components.
import AppInfoSection from "./AppInfoSection";
import MetricsInfoSection from "./MetricsInfoSection";
import AppDailyCounts from "types/models/AppDailyCounts";
import AppStatusSection from "./AppStatusSection";

interface PROPS extends WithStyles<typeof styles> {
    appInfo: AppInfo | null;
    appDailyCounts: AppDailyCounts | null;
    appCurrentUserCount: number | null;
}

class AppBanner extends React.PureComponent<PROPS> {
    render() {
        const { classes, appInfo, appDailyCounts, appCurrentUserCount } = this.props;

        return (
            <div className={classes.root}>
                <AppInfoSection appInfo={appInfo} />

                <AppStatusSection appInfo={appInfo} />

                <MetricsInfoSection appDailyCounts={appDailyCounts} appCurrentUserCount={appCurrentUserCount} />
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "auto",
            overflowY: "hidden",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },

            "& > *:nth-child(2)": {
                marginLeft: "auto",
            },

            "& > *:nth-child(3)": {
                marginLeft: "1.25em",
            },
        },
    });

export default withStyles(styles)(AppBanner);
