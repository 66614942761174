// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withFormValidator, withFormValidatorProps } from "framework/formValidator";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from "react-simple-captcha";

// Import types.
import DevelopmentType from "types/enums/DevelopmentType";
import PortalState from "types/store";
import Session from "types/common/Session";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import { TextFieldOptions } from "components/common/form/fields/TextField";
import { RadioButtonFieldOptions } from "components/common/form/fields/RadioButtonField";

// Import components.
import { Chip, Divider, Link, Typography } from "@mui/material";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";
import InfoBox from "components/common/screen/InfoBox";

// Import utilities.
import * as Patterns from "utils/Patterns";
import Http from "utils/networking/Http";

interface STATE_PROPS {
    themeConfiguration: ThemeConfiguration;
    session: Session;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    onComplete: (email: string | null) => void;
    onCancel: () => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps, withFormValidatorProps {}

interface STATE {
    fullName: string;
    email: string;
    teamName: string;
    developmentType: DevelopmentType;
    captcha: string;

    checkUserStatus: "pending" | "complete" | "error" | null;
    captchaStatus: "pending" | "complete" | "error" | null;
    registrationStatus: "pending" | "complete" | "error" | null;
    error: string | null;
}

const mapStateToProps = (state: PortalState) => {
    return {
        themeConfiguration: state.themeConfiguration,
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

const CAPTCHA_LENGTH = 6;

class Register extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        fullName: "",
        email: "",
        teamName: "",
        developmentType: DevelopmentType.GAMES,
        captcha: "",

        checkUserStatus: null,
        captchaStatus: null,
        registrationStatus: null,
        error: null,
    };
    private checkUserButtonRef = React.createRef<HTMLElement>();
    private checkCaptchaButtonRef = React.createRef<HTMLElement>();

    componentDidUpdate(_prevProps: PROPS, prevState: STATE) {
        if (prevState.checkUserStatus !== this.state.checkUserStatus && this.state.checkUserStatus === "complete" && this.state.captchaStatus == null) {
            this.initializeCaptcha();
        }
    }

    initializeCaptcha = () => {
        const { themeConfiguration, session } = this.props;

        let backgroundColor = "black";
        let foregroundColor = "white";

        const activeTheme = themeConfiguration.activeTheme;
        const activeMode = activeTheme ? activeTheme[session.themeMode] : null;

        if (activeMode) {
            const cssVariables = activeMode.cssVariables;

            if (cssVariables["field-background-color"]) backgroundColor = cssVariables["field-background-color"];
            if (cssVariables["field-color"]) foregroundColor = cssVariables["field-color"];
        }

        loadCaptchaEnginge(CAPTCHA_LENGTH, backgroundColor, foregroundColor, "upper");
    };

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "fullName":
                this.setState({ fullName: value });
                break;
            case "email":
                this.setState({ email: value.trim() });
                break;
            case "teamName":
                this.setState({ teamName: value });
                break;
            case "developmentType":
                this.setState({ developmentType: value });
                break;
            case "captcha":
                this.setState({ captcha: value });
                break;
            default:
            // Do nothing.
        }
    };

    handleKeyPress = (name: string, key: string) => {
        if (["fullName", "email", "teamName"].includes(name) && key === "Enter") {
            this.checkUserButtonRef.current?.focus();

            this.onCheckUser();
        }

        if (["captcha"].includes(name) && key === "Enter") {
            this.checkCaptchaButtonRef.current?.focus();

            this.onCheckCaptcha();
        }
    };

    onCheckUser = () => {
        const { bcFormValidator, i18n } = this.props;
        const { email } = this.state;

        if (!bcFormValidator.validate()) return;

        this.setState({ checkUserStatus: "pending", error: null }, async () => {
            const checkUserResponse = await Http.GET("check-user-exists", { email: email }, Http.JSON_HEADERS);

            if (Http.isStatusOk(checkUserResponse)) {
                if (checkUserResponse.data.error) {
                    this.setState({ checkUserStatus: "error", error: checkUserResponse.data.error === "User Already Exists." ? i18n._(t`User Already Exists.`) : checkUserResponse.data.error });
                } else {
                    this.setState({ checkUserStatus: "complete", error: null });
                }
            } else {
                const error = Http.buildError(checkUserResponse);

                this.setState({ checkUserStatus: "error", error: error.errorMessage || i18n._(t`Could not check email.`) });
            }
        });
    };

    onCheckCaptcha = () => {
        const { bcFormValidator, i18n } = this.props;

        if (!bcFormValidator.validate()) return;

        this.setState({ captchaStatus: "pending", error: null }, () => {
            if (validateCaptcha(this.state.captcha) === true) {
                this.setState({ captchaStatus: "complete", error: null }, () => {
                    this.onRegister();
                });
            } else {
                this.setState({ captchaStatus: "error", error: i18n._(t`Captcha does not match.`) });
            }
        });
    };

    onCancelCaptcha = () => {
        this.setState({ checkUserStatus: null, captchaStatus: null, error: null });
    };

    onRegister = () => {
        const { bcFormValidator, i18n } = this.props;
        const { fullName, email, teamName, developmentType } = this.state;

        if (!bcFormValidator.validate()) return;

        this.setState({ registrationStatus: "pending", error: null }, async () => {
            const registerResponse = await Http.POST(
                "register",
                undefined,
                {
                    adminCredentialForm: {
                        fullName: fullName,
                        email: email,
                        company: teamName,
                    },
                    companyForm: {
                        companyName: teamName,
                        developmentType: developmentType,
                        contactName: fullName,
                        contactEmail: email,
                    },
                },
                Http.JSON_HEADERS
            );

            if (Http.isStatusOk(registerResponse)) {
                if (registerResponse.data.error) {
                    this.setState({ registrationStatus: "error", error: registerResponse.data.error });
                } else {
                    this.setState({ registrationStatus: "complete", error: null });

                    // Google Analytics - Signup Event
                    if ((window as any).gtag) {
                        (window as any).gtag("event", "sign_up", {
                            method: "portal",
                        });
                    }
                }
            } else {
                const error = Http.buildError(registerResponse);

                this.setState({ registrationStatus: "error", error: error.errorMessage || i18n._(t`Could not register account.`) });
            }
        });
    };

    render() {
        const { bcFormValidator, i18n, classes } = this.props;
        const { fullName, email, teamName, developmentType, captcha, error, checkUserStatus, captchaStatus, registrationStatus } = this.state;

        return (
            <div id={"register"} className={classes.root}>
                {registrationStatus !== "complete" && (
                    <>
                        <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                            <Trans>Sign Up</Trans>
                        </Typography>

                        {(checkUserStatus !== "complete" || captchaStatus === "complete") && (
                            <>
                                <FieldWrapper
                                    formValidator={bcFormValidator}
                                    className={classes.field}
                                    type={"text"}
                                    name={"fullName"}
                                    value={fullName}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    required={true}
                                    disabled={(checkUserStatus != null && ["pending", "complete"].includes(checkUserStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                                    autoFocus={true}
                                    options={
                                        {
                                            placeholder: i18n._(t`Full Name`),
                                            autoComplete: "name given-name nickname",
                                            showPasswordManagerExtensions: false,
                                        } as TextFieldOptions
                                    }
                                />

                                <FieldWrapper
                                    formValidator={bcFormValidator}
                                    className={classes.field}
                                    type={"text"}
                                    name={"email"}
                                    value={email}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    required={true}
                                    disabled={(checkUserStatus != null && ["pending", "complete"].includes(checkUserStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                                    options={
                                        {
                                            placeholder: i18n._(t`Email Address`),
                                            pattern: Patterns.EMAIL,
                                            patternMessage: <Trans>Must be a valid email address.</Trans>,
                                            autoComplete: "email",
                                            showPasswordManagerExtensions: true,
                                        } as TextFieldOptions
                                    }
                                />

                                <FieldWrapper
                                    formValidator={bcFormValidator}
                                    className={classes.field}
                                    type={"text"}
                                    name={"teamName"}
                                    value={teamName}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    required={true}
                                    disabled={(checkUserStatus != null && ["pending", "complete"].includes(checkUserStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                                    options={
                                        {
                                            placeholder: i18n._(t`Team Name`),
                                            autoComplete: "organization-title organization",
                                            showPasswordManagerExtensions: false,
                                        } as TextFieldOptions
                                    }
                                />

                                <FieldWrapper
                                    formValidator={bcFormValidator}
                                    name={"developmentType"}
                                    type={"radiobutton"}
                                    label={<Trans>My Team Builds Primarily</Trans>}
                                    labelPosition={"top"}
                                    labelAlignment={"center"}
                                    controlStyle={{ marginLeft: "auto", marginRight: "auto", marginTop: "0.5rem", marginBottom: "0.5rem" }}
                                    value={developmentType}
                                    onChange={this.handleChange}
                                    disabled={(checkUserStatus != null && ["pending", "complete"].includes(checkUserStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                                    options={
                                        {
                                            options: [
                                                { value: DevelopmentType.GAMES, label: <Trans>Games</Trans> },
                                                { value: DevelopmentType.APPS, label: <Trans>Apps</Trans> },
                                                { value: DevelopmentType.THINGS, label: <Trans>Things</Trans> },
                                            ],
                                        } as RadioButtonFieldOptions
                                    }
                                />
                            </>
                        )}

                        {checkUserStatus === "complete" && captchaStatus !== "complete" && (
                            <>
                                <Typography style={{ alignSelf: "center", marginTop: "0.5rem", marginBottom: "0.5rem", marginLeft: "0.3125rem", marginRight: "0.3125rem" }}>
                                    <Trans>To continue, please enter the text below.</Trans>
                                </Typography>

                                <div className={classes.captcha}>
                                    <LoadCanvasTemplateNoReload />
                                </div>

                                <FieldWrapper
                                    formValidator={bcFormValidator}
                                    className={classes.field}
                                    type={"text"}
                                    name={"captcha"}
                                    value={captcha}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    required={true}
                                    disabled={(captchaStatus != null && ["pending", "complete"].includes(captchaStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                                    options={
                                        {
                                            placeholder: i18n._(t`Enter the letters above (case sensitive)`),
                                        } as TextFieldOptions
                                    }
                                />
                            </>
                        )}

                        {checkUserStatus !== "complete" && (
                            <Button
                                ref={this.checkUserButtonRef}
                                className={classes.button}
                                id={"register"}
                                type={"primary"}
                                onClick={this.onCheckUser}
                                disabled={(checkUserStatus != null && ["pending", "complete"].includes(checkUserStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                            >
                                {checkUserStatus === "pending" || registrationStatus === "pending" ? <Trans>Please Wait...</Trans> : <Trans>Sign up</Trans>}
                            </Button>
                        )}

                        {checkUserStatus === "complete" && (
                            <>
                                <Button
                                    ref={this.checkCaptchaButtonRef}
                                    className={classes.button}
                                    id={"verify-captcha"}
                                    type={"primary"}
                                    onClick={this.onCheckCaptcha}
                                    disabled={(captchaStatus != null && ["pending", "complete"].includes(captchaStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                                >
                                    {captchaStatus === "pending" || registrationStatus === "pending" ? <Trans>Please Wait...</Trans> : <Trans>Verify</Trans>}
                                </Button>

                                <Button
                                    className={classes.button}
                                    id={"abort-captcha"}
                                    type={"semantic-negative-secondary"}
                                    onClick={this.onCancelCaptcha}
                                    disabled={(captchaStatus != null && ["pemnding", "complete"].includes(captchaStatus)) || (registrationStatus != null && ["pending", "complete"].includes(registrationStatus))}
                                >
                                    <Trans>Cancel</Trans>
                                </Button>
                            </>
                        )}

                        {error && <Chip className={classes.chip} label={error} />}

                        {checkUserStatus !== "complete" && (
                            <>
                                <Divider style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "0.3125rem", marginRight: "0.3125rem" }} />

                                <span className={classes.login}>
                                    <Typography>
                                        <Trans>Already have an account?</Trans>
                                    </Typography>

                                    <Link data-id={"login"} onClick={this.props.onCancel} noWrap>
                                        <Trans>Log in</Trans>
                                    </Link>
                                </span>
                            </>
                        )}
                    </>
                )}

                {registrationStatus === "complete" && (
                    <>
                        <InfoBox
                            style={{ marginBottom: "1rem" }}
                            content={
                                <span style={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
                                    <Typography>
                                        <Trans>You should receive an email containing instructions on how to complete the account registration process at the following address in a few minutes.</Trans>
                                    </Typography>

                                    <Link data-id={"register-account"} data-email={email} style={{ marginTop: "2rem", marginBottom: "2rem", textAlign: "center" }} href={"mailto:" + email}>
                                        {email}
                                    </Link>

                                    <Typography>
                                        <Trans>Please check your email and click the included link to set a password and activate of your account.</Trans>
                                    </Typography>
                                </span>
                            }
                        />

                        <Button className={classes.button} id={"finish"} type={"primary"} onClick={() => this.props.onComplete(email)}>
                            <Trans>Log in</Trans>
                        </Button>
                    </>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        field: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            textTransform: "none",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },

        login: {
            flex: "0 0 auto",
            alignSelf: "center",

            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",

            margin: "0.3125rem",

            fontSize: "12px",

            "& .MuiTypography-root": {
                fontSize: "12px",
            },

            "& > *:last-child": {
                marginLeft: "0.3125rem",
            },
        },

        captcha: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            height: "5rem",

            overflow: "hidden",

            "& > div": {
                width: "100%",
                height: "100%",

                overflow: "hidden",

                "& > canvas": {
                    width: "100%",
                    height: "100%",

                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "var(--field-border-color)",
                    borderRadius: "0.25em",

                    overflow: "hidden",
                },
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withFormValidator()(withI18n()(withStyles(styles)(Register))));
