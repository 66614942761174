// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { WithWidthProps, withWidth } from "framework/width";
import classnames from "classnames";

// Import components.
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingProgress from "components/common/widgets/LoadingProgress";
import IconButton from "../button/IconButton";

// Import icons.
import DismissIcon from "@mui/icons-material/CancelOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

interface OWN_PROPS {
    id: string;
    className?: string;
    open?: boolean;
    ready?: boolean;
    height?: string | number;
    header?: React.ReactNode | null;
    customHeader?: React.ReactNode | null;
    content?: React.ReactNode | null;
    actions?: React.ReactNode | null;
    hideFullscreen?: boolean;
    hideDismiss?: boolean;
    dismissOnBackdropClick?: boolean;
    onClose?: () => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, WithWidthProps {}

interface STATE {
    open: boolean;
    fullscreen: boolean;
}

class CustomDialog extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        open: false,
        fullscreen: false,
    };

    componentDidMount() {
        if (this.props.open != null) {
            this.setState({ open: this.props.open });
        }

        if (this.props.width != null) {
            this.setState({ fullscreen: this.props.isWidthDown("xs", this.props.width, true) });
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.open !== this.props.open) {
            this.setState({ open: this.props.open != null ? this.props.open : false });
        }

        if (prevProps.width !== this.props.width) {
            this.setState({ fullscreen: this.props.width != null && this.props.isWidthDown("xs", this.props.width, true) });
        }
    }

    toggleFullscreen = () => {
        this.setState({ fullscreen: !this.state.fullscreen });
    };

    handleDismiss = () => {
        this.setState({ open: false }, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        });
    };

    render() {
        const { width, isWidthDown, classes, id, className, ready, height, header, customHeader, content, actions, hideFullscreen, hideDismiss } = this.props;
        const { open, fullscreen } = this.state;

        return (
            <Dialog
                id={"dialog-" + id}
                className={classnames(classes.root, className)}
                open={open}
                fullScreen={fullscreen}
                aria-labelledby={"dialog-" + id + "-title"}
                aria-describedby={"dialog-" + id + "-description"}
                onKeyDown={(e) => {
                    if (this.props.onKeyDown) {
                        this.props.onKeyDown(e);
                    }
                }}
                onKeyUp={(e) => e.stopPropagation()}
                onClose={() => {
                    if (this.props.dismissOnBackdropClick) {
                        this.handleDismiss();
                    }
                }}
                maxWidth={"md"}
                PaperProps={{
                    style: {
                        minHeight: "unset",
                        height: !fullscreen ? height || "auto" : "100%",
                        maxHeight: "100%",
                    },
                }}
                disableRestoreFocus={true}
            >
                <DialogTitle id={id + "-title"} className={classes.dialogTitle} component={"span"}>
                    {customHeader ? customHeader : <Typography variant={"h6"}>{header}</Typography>}

                    <span>
                        {width != null && !isWidthDown("xs", width) && !hideFullscreen && (
                            <IconButton id={"toggle-fullscreen"} type={"neutral"} style={{ width: "1.75em", height: "1.75em" }} onClick={this.toggleFullscreen}>
                                {!fullscreen && <FullscreenIcon />}
                                {fullscreen && <FullscreenExitIcon />}
                            </IconButton>
                        )}

                        {!hideDismiss && (
                            <IconButton id={"dismiss"} type={"neutral"} style={{ width: "1.75em", height: "1.75em" }} onClick={this.handleDismiss}>
                                <DismissIcon />
                            </IconButton>
                        )}
                    </span>
                </DialogTitle>

                <DialogContent id={id + "-content"} className={classes.dialogContent}>
                    {!ready && (
                        <div className={"centered"}>
                            <LoadingProgress type="linear" />
                        </div>
                    )}
                    {ready && content}
                </DialogContent>

                {actions && (
                    <DialogActions id={id + "-actions"} className={classes.dialogActions}>
                        {actions}
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            "& > .MuiDialog-container > .MuiDialog-paper": {
                flex: "1 1 auto",
                padding: "1em",
            },
        },
        dialogTitle: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            paddingTop: "0.3125em",
            paddingBottom: "0.3125em",
            paddingLeft: "0.625em",
            paddingRight: "0.625em",

            overflow: "hidden",

            fontSize: "1em",

            "& > *:first-child": {
                flex: "1 1 auto",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },

            "& > *:last-child": {
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
                alignSelf: "flex-start",
            },
        },

        dialogContent: {
            flex: "1 1 auto",

            minHeight: "6em",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: "0.625em",
            paddingRight: "0.625em",

            margin: 0,

            overflowX: "hidden",
            overflowY: "auto",

            position: "relative",
        },

        dialogActions: {
            flex: "0 0 auto",

            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            paddingTop: "0.3125em",
            paddingBottom: "0.3125em",
            paddingLeft: "0.625em",
            paddingRight: "0.625em",

            overflow: "hidden",

            "&.MuiDialogActions-spacing > :not(:first-child)": {
                marginLeft: 0,
                justifySelf: "flex-end",
            },

            "& > span": {
                flex: "1 1 auto",

                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                flexWrap: "wrap",

                overflow: "hidden",
            },
        },
    });

export default withWidth()(withStyles(styles)(CustomDialog));
