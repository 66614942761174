// Import libraries.
import React from "react";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from "@lingui/macro";

// Import types.
import { formatAccountStatusColor, formatAccountStatusLabel } from "types/enums/AccountStatus";
import TeamInfo from "types/models/TeamInfo";

// Import components.
import { Typography } from "@mui/material";
import LabelWithCopy from "components/common/LabelWithCopy";

interface PROPS extends WithStyles<typeof styles> {
    teamInfo: TeamInfo | null;
}

class AccountInfoSection extends React.PureComponent<PROPS> {
    render() {
        const { classes, teamInfo } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <LabelWithCopy id={"teamId"} value={teamInfo ? teamInfo.companyId : null}>
                            <Trans>Team ID</Trans>
                        </LabelWithCopy>

                        <Typography style={{ fontWeight: "bold" }} noWrap>
                            {teamInfo ? teamInfo.companyId : "---"}
                        </Typography>
                    </span>

                    <span className={classes.nameValuePair}>
                        <LabelWithCopy id={"accountNumber"} value={teamInfo ? teamInfo.accountNumber : null}>
                            <Trans>Account Number</Trans>
                        </LabelWithCopy>

                        <Typography style={{ fontWeight: "bold" }} noWrap>
                            {teamInfo ? teamInfo.accountNumber : "---"}
                        </Typography>
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>
                            <Trans>Account Status</Trans>
                        </Typography>

                        <Typography
                            style={{
                                marginLeft: "0.3125em",
                                fontWeight: "bold",
                                color: formatAccountStatusColor(teamInfo?.accountStatus),
                            }}
                            noWrap
                        >
                            {formatAccountStatusLabel(teamInfo?.accountStatus)}
                        </Typography>
                    </span>
                </div>
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",

            padding: "0.3125em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
    });

export default withStyles(styles)(AccountInfoSection);
