const Flyout = (props: any) => {
    return (
        <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 16 16" {...props}>
            <g transform="translate(0.000000, 1.777778)" fill="currentcolor" fillRule="nonzero">
                <path d="M0.96,0 L0.96,12.6222222 L0,12.6222222 L0,0 L0.96,0 Z M13.5911111,0.00888888889 C14.1831111,0.00888888889 14.7247111,0.203288889 15.1575911,0.553208889 L15.2977778,0.675555556 L15.2888889,0.684444444 C15.6968889,1.07644444 15.9392889,1.56924444 15.9836889,2.11100444 L15.9911111,2.29333333 L15.9911111,10.2577778 C15.9911111,10.88 15.7422222,11.4488889 15.2888889,11.8755556 C14.8780247,12.2548148 14.3547874,12.4865844 13.7940924,12.5334065 L13.5822222,12.5422222 L5.15555556,12.5422222 C4.49777778,12.5422222 3.90222222,12.3022222 3.44888889,11.8755556 C3.04088889,11.4755556 2.79128889,10.9819556 2.74544889,10.4401156 L2.73777778,10.2577778 L2.73777778,2.28444444 C2.73777778,1.68 2.99555556,1.10222222 3.44888889,0.675555556 C3.86765432,0.296296296 4.38474623,0.064526749 4.94397805,0.0177046182 L5.15555556,0.00888888889 L13.5911111,0.00888888889 Z M13.5911111,1.00888889 L5.15555556,1.00888889 C4.77321532,1.00888889 4.41457107,1.15013102 4.13425359,1.40375555 C3.91444095,1.61063803 3.78016163,1.87237496 3.74628208,2.14652909 L3.73777778,2.28444444 L3.73777778,10.2577778 C3.73777778,10.5848439 3.87469623,10.8925964 4.13425359,11.1473556 C4.3675645,11.3669423 4.65961255,11.5002007 4.98827535,11.5337932 L5.15555556,11.5422222 L13.5822222,11.5422222 C13.9667611,11.5422222 14.3301033,11.3996803 14.6035242,11.1473556 C14.8215873,10.9421197 14.9505857,10.6878448 14.9829921,10.4027873 L14.9911111,10.2577778 L14.9911111,2.29333333 C14.9911111,2.00748517 14.8938238,1.74479839 14.7006398,1.51667675 L14.5960628,1.40554915 L14.481,1.295 L14.3603425,1.21343889 C14.1803352,1.10640384 13.9774999,1.03971287 13.7583913,1.01731788 L13.5911111,1.00888889 Z"></path>
            </g>
        </svg>
    );
};

export default Flyout;
