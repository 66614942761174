// Import libraries.
import React from "react";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

// Import types.
import TeamInfo from "types/models/TeamInfo";

// Import components.
import TeamInfoSection from "./TeamInfoSection";
import AccountInfoSection from "./AccountInfoSection";

interface PROPS extends WithStyles<typeof styles> {
    teamInfo: TeamInfo | null;
}

class TeamBanner extends React.PureComponent<PROPS> {
    render() {
        const { classes, teamInfo } = this.props;

        return (
            <div className={classes.root}>
                <TeamInfoSection teamInfo={teamInfo} />

                <AccountInfoSection teamInfo={teamInfo} />
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "auto",
            overflowY: "hidden",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },

            "& > *:nth-child(2)": {
                marginLeft: "auto",
            },
        },
    });

export default withStyles(styles)(TeamBanner);
