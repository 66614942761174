// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";

// Import components.
import { Tooltip, Typography } from "@mui/material";
import IconButton from "../button/IconButton";

// Import icons.
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Import utilities.
import ClipboardUtils from "utils/Clipboard";

interface PROPS extends WithStyles<typeof styles> {
    id: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    value: any;
}

class LabelWithCopy extends React.PureComponent<PROPS> {
    render() {
        const { classes, id, style, children, value } = this.props;

        return (
            <span id={"label-with-copy-" + id} className={classes.root} style={style}>
                {children && <Typography noWrap>{children}</Typography>}

                {value && (
                    <Tooltip arrow title={<Trans>Copy To Clipboard</Trans>}>
                        <IconButton id={"copy-to-clipboard"} onClick={() => ClipboardUtils.writeText(value)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </span>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            "& > *:last-child": {
                flex: "0 0 auto",
                padding: "0.25em",
                marginTop: 0,
                marginBottom: 0,
                marginLeft: "0.3125em",
                marginRight: 0,
            },
        },
    });

export default withStyles(styles)(LabelWithCopy);
