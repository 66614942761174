// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import PlayerSummary from "types/models/PlayerSummaryInfo";

// Import components.
import { Tooltip, Typography } from "@mui/material";
import ImageWrapper from "components/common/ImageWrapper";
import Timestamp from "components/common/Timestamp";
import IconButton from "components/common/button/IconButton";

// Import Icons.
import LinkIcon from "@mui/icons-material/InsertLink";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import UserImagePlaceHolder from "components/common/UserImagePlaceHolder";

// Import Utils.
import ClipboardUtils from "utils/Clipboard";
import BooleanFormatter from "utils/formatters/Booleanformatter";

interface STATE_PROPS {
    session: Session;
}
interface PROPS extends STATE_PROPS, WithStyles<typeof styles>, RouteComponentProps {
    playerSummary: PlayerSummary;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

class PlayerBanner extends React.PureComponent<PROPS> {
    onCopyDeepLink = () => {
        const { session } = this.props;

        const deepLink = window.location.origin + "/#/app/user/user-browser/" + (session.isSuper ? session.companyIdAlias : session.companyId) + "/" + session.appId + "/" + session.playerId;

        ClipboardUtils.writeText(deepLink);
    };
    render() {
        const { classes, playerSummary } = this.props;
        return (
            <div className={classes.root}>
                <div style={{ display: "flex", borderColor: "inherit" }}>
                    <ImageWrapper style={{ width: "6em", height: "6em", margin: "0.3125em" }} src={playerSummary.pictureUrl} fallback={<UserImagePlaceHolder playerSummary={playerSummary} />} />

                    <div className={classes.content}>
                        <span className={classes.nameValuePair}>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <Typography noWrap>{<Trans>User ID</Trans>}</Typography>
                                <Tooltip arrow title={<Trans>Copy User URL</Trans>}>
                                    <IconButton style={{ margin: "0 0 0 .3125em" }} id={"copy-user-url"} onClick={this.onCopyDeepLink}>
                                        <LinkIcon style={{ transform: "rotate(-45deg)" }} />
                                    </IconButton>
                                </Tooltip>
                            </span>

                            <span style={{ display: "flex", alignItems: "center" }}>
                                <Typography style={{ fontWeight: "bold" }} noWrap>
                                    {playerSummary.playerId}
                                </Typography>
                                <IconButton style={{ margin: "0 0 0 .3125em" }} id={"copy-to-clipboard"} onClick={() => ClipboardUtils.writeText(playerSummary?.playerId)}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </span>
                        </span>
                        <span className={classes.nameValuePair}>
                            <Typography noWrap>{<Trans>Is Tester</Trans>}</Typography>

                            <Typography style={{ fontWeight: "bold" }} noWrap>
                                {BooleanFormatter.formatBoolean(playerSummary.isTester, true)}
                            </Typography>
                        </span>
                    </div>
                </div>

                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Profile Name</Trans>}</Typography>

                        <Typography style={{ fontWeight: "bold" }} noWrap>
                            {playerSummary.name ? playerSummary.name : "---"}
                        </Typography>
                    </span>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Profile Email</Trans>}</Typography>

                        <Typography style={{ fontWeight: "bold" }} noWrap>
                            {playerSummary.email ? playerSummary.email : "---"}
                        </Typography>
                    </span>
                </div>

                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Created</Trans>}</Typography>
                        <Timestamp style={{ fontWeight: "bold" }} value={playerSummary.createdAt} humanize={true} />
                    </span>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Updated</Trans>}</Typography>
                        <Timestamp style={{ fontWeight: "bold" }} value={playerSummary.updatedAt} humanize={true} />
                    </span>
                </div>
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "auto",
            overflowY: "hidden",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },

            "& > *:nth-child(2)": {
                marginLeft: "auto",
            },

            "& > *:nth-child(3)": {
                marginLeft: "1.25em",
            },
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            padding: "0.3125em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
        },
    });

export default connect(mapStateToProps)(withRouter(withStyles(styles)(PlayerBanner)));
