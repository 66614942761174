// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Trans } from "@lingui/macro";

// Import types.
import TeamInfo from "types/models/TeamInfo";

// Import components.
import { Link, Typography } from "@mui/material";
import LabelWithCopy from "components/common/LabelWithCopy";
import ImageWrapper from "components/common/ImageWrapper";

// Import icons.
import NoTeamImage from "components/icons/NoTeamImage";

// Import utilities.
import StringUtils from "utils/String";

interface PROPS extends WithStyles<typeof styles>, RouteComponentProps {
    teamInfo: TeamInfo | null;
}

class TeamInfoSection extends React.PureComponent<PROPS> {
    render() {
        const { classes, teamInfo } = this.props;

        return (
            <div className={classes.root}>
                <ImageWrapper
                    style={{ width: "6em", height: "6em", margin: "0.3125em" }}
                    src={!StringUtils.isNullOrEmpty(teamInfo?.imageUrl) ? "files/portal/c/" + teamInfo?.companyId + teamInfo?.imageUrl : null}
                    fallback={<NoTeamImage />}
                    disableCache={teamInfo?.updatedAt || null}
                />

                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <LabelWithCopy id={"companyId"} value={teamInfo ? `${teamInfo.companyName} (${teamInfo.accountNumber})` : null}>
                            <Trans>Team Name</Trans>
                        </LabelWithCopy>

                        <Typography style={{ fontWeight: "bold" }} noWrap>
                            {teamInfo ? teamInfo.companyName : "---"}
                        </Typography>
                    </span>

                    <Link data-id={teamInfo?.companyId} data-name={teamInfo?.companyName} data-type={"team"} rel="noreferrer" onClick={() => this.props.history.push("/team/setup/team-info")}>
                        <Trans>Edit Team Info</Trans>
                    </Link>
                </div>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            padding: "0.3125em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
        },
    });

export default withRouter(withStyles(styles)(TeamInfoSection));
